<template>
  <div id="HomeDashboard">
    <v-row>
      <v-col
        v-for="(item, index1) in totalsBySelectedPlace"
        :key="`infoX-${index1}`"
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="3"
        class="mb-0 pb-0"
      >
        <v-card class="card-place-details">
          <v-card-text>
            <v-row>
              <v-col
                cols="8"
                class="container-place-details"
              >
                <v-progress-circular
                  v-if="loading"
                  color="orange"
                  indeterminate
                />
                <div
                  v-else
                  class="amount-place-details"
                >
                  {{ item.type ? $formatPrice(item.val) : $formatNum(item.val) }}
                  <exchange-currency v-if="item.type" />
                </div>
                <div class="title-place-details">
                  {{ item.title }}
                </div>
              </v-col>
              <v-col
                cols="4"
                align="center"
              >
                <v-icon class="icon-place-details">
                  {{ item.icon }}
                </v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        class="mt-2"
        cols="12"
        sm="12"
        md="12"
        lg="6"
      >
        <v-card style="padding: 20px; height: 80px;">
          <v-select
            v-model="placeSelected"
            color="black"
            item-color="orange lighten-2"
            mandatory
            prepend-icon="mdi-bank"
            dense
            outlined
            :items="placesForUser"
            item-text="name"
            item-value="id"
            label="Seleccione un parador"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="6"
      >
        <SalesByTicketTypeChart
          style="height: 100%"
          :placeSelected="placeSelected"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="6"
      >
        <SalesByPlacesChart
          style="height: 100%"
          :loading="loadingSalesByPlaces"
          :chartData="chartData"
          :options="options"
          :donut="true"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <SalesByWeekChart :placeSelected="placeSelected" />
      </v-col>

      <v-col
        cols="12"
      >
        <SalesByPlacesChart
          style="height: 100%"
          :loading="loadingSalesByPlaces"
          :chartData="chartData"
          :options="options"
          :line="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Vue
import { mapGetters, mapState } from 'vuex'

// Components
import SalesByTicketTypeChart from '../../components/dashboard/SalesByTicketTypeChart.vue'
import SalesByPlacesChart from '../../components/dashboard/SalesByPlacesChart.vue'
import SalesByWeekChart from '../../components/dashboard/SalesByWeekChart.vue'

export default {
  name: 'HomeDashboard',
  components: { SalesByTicketTypeChart, SalesByPlacesChart, SalesByWeekChart },
  data () {
    return {
      placeSelected: null,
      totalsBySelectedPlace: [
        {
          icon: 'mdi-ticket-confirmation',
          title: 'Boletos mensual',
          val: 0,
          type: 0
        },
        {
          icon: 'mdi-cash-multiple',
          title: 'Recaudado mensual',
          val: 0,
          type: 1
        },
        {
          icon: 'mdi-ticket-confirmation',
          title: 'Boletos anual',
          val: 0,
          type: 0
        },
        {
          icon: 'mdi-cash-multiple',
          title: 'Recaudado anual',
          val: 0,
          type: 1
        }
      ],
      loading: false,
      loadingSalesByPlaces: false,
      chartData: {
        labels: [''],
        datasets: [
          {
            label: 'Parador',
            data: [],
            backgroundColor: [
              '#FFD750',
              '#FFA500',
              '#FF8C00',
              '#FF4500',
              '#FF4570',
              '#FFD700'
            ]
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          },
          title: {
            display: true,
            text: 'Ventas por parador - Anual',
            font: {
              size: 20
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState('dashboard', ['placesForUser', 'entities']),
    ...mapGetters({
      userData: 'auth/userData'
    })
  },
  watch: {
    placeSelected: function (value) {
      this.getStatistics(value)
    }
  },
  mounted () {
    this.initHomeDashboard()
  },
  methods: {
    initHomeDashboard: function () {
      if (this.userData.rol === 2) {
        this.placeSelected = 12
        return
      }
      if (this.userData.rol === 3) {
        this.placeSelected = this.userData.sitio
        return
      }
      this.placeSelected = 39
    },
    getStatistics: function () {
      this.getTotalsForMonth()
      this.getTotalsForYear()
      this.getSalesByPlaces()
    },
    getTotalsForMonth: function () {
      this.loading = true

      const data = { placeSelected: this.placeSelected }
      this.$root.post('/dashboard/getTotalsBySelectedPlaceMonth', data)
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data?.message)
            return
          }

          this.totalsBySelectedPlace[0].val = data?.[0]?.boletos
          this.totalsBySelectedPlace[1].val = data?.[0]?.total
        }).finally(() => { this.loading = false })
    },
    getTotalsForYear: function () {
      this.loading = true

      const data = { placeSelected: this.placeSelected }
      this.$root.post('/dashboard/getTotalsBySelectedPlaceYear', data)
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data?.message)
            return
          }

          this.totalsBySelectedPlace[2].val = data?.[0]?.boletos
          this.totalsBySelectedPlace[3].val = data?.[0]?.total
        }).finally(() => { this.loading = false })
    },
    getSalesByPlaces: function () {
      this.loadingSalesByPlaces = true
      const data = { entity: this.userData.rol, place: this.userData.sitio }

      this.$root.post('/dashboard/getSalesByPlaces', data)
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data?.message)
            return
          }

          const temp2L = []
          const temp2D = []

          data.forEach(element => {
            temp2L.push(element.name)
            temp2D.push(element.total)
          })

          this.chartData.labels = temp2L
          this.chartData.datasets[0].data = temp2D
        })
        .finally(() => { this.loadingSalesByPlaces = false })
    }
  }
}
</script>

<style scoped>
#HomeDashboard {
  padding: 15px 30px;
}

.container-place-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.amount-place-details {
  font-size: 24px;
  color: orange;
  margin-bottom: 5px;
  margin-top: 5px;
}

.title-place-details {
  font-size: 19px;
  margin-top: 5px;
  color: #9e9e9e;
}

.icon-place-details {
  font-size: 50px !important;
  padding: 10px;
  color: orange;
  opacity: 0.6;
}

.card-place-details:hover {
  background: rgb(255, 175, 26) !important;
  cursor: pointer;
}

.card-place-details:hover .amount-place-details,
.card-place-details:hover .title-place-details,
.card-place-details:hover .icon-place-details {
  color: white;
}

.card-place-details:hover .icon-place-details {
  animation-duration: 2s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  color: white;
}

@keyframes slidein {
  0% {
    transform: scale(1, 1);
  opacity: 0.6;
  }
  50% {
    transform: scale(1.5, 1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0.6;
  }
}

@media (max-width: 959px) {
  #HomeDashboard {
    padding: 20px;
  }
}
</style>
