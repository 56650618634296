export default {
  namespaced: true,
  state: {
    loadingMainApp: false,
    textLoadingMainApp: 'Cargando'
  },
  mutations: {
    setLoadingMainApp: function (state, val) {
      state.loadingMainApp = val
    },
    setTextLoadingMainApp: function (state, val) {
      state.textLoadingMainApp = val || 'Cargando'
    }
  }
}
