import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userData: 'auth/userData',
      token: 'auth/token'
    })
  },
  mounted () {
    this.verifyCompanyState()
  },
  methods: {
    ...mapMutations({
      refreshUser: 'auth/refreshUser'
    }),
    sendToVerifyCorp: function () {
      const delay = this.$route.fullPath === '/home' ? 200 : 100
      if (this.$route.fullPath !== '/account-settings') this.$router.push('/account-settings')

      setTimeout(() => {
        const containerSettings = document.getElementById('AccountSettingWholesalers')
        const scrollTo = containerSettings.scrollHeight - containerSettings.clientHeight

        containerSettings.scroll({
          top: scrollTo,
          behavior: 'smooth'
        })
      }, delay)
    },
    verifyCompanyState: async function () {
      if (this.userData.companyState === 1) return
      if (this.userData.companyState === 0 && this.userData.showlastDayForVerify) {
        const title = 'Cuenta inactiva'
        const message = `Tu cuenta no ha sido verificada, es necesario enviar la documentación requerida para poder activar tu cuenta. 
          Recuerda que tienes hasta el ${this.userData.lastDayForVerify} para enviar la documentación requerida.`

        const response = await this.$ConfirmBox('warning', title, message, 'Recordar más tarde', 'Enviar documentación')
        if (response.isConfirmed) {
          this.sendToVerifyCorp()
        }

        if (response.isDenied) {
          this.userData.showlastDayForVerify = false
          this.userData.token = this.token
          this.refreshUser(this.userData)
        }
      }

      if (this.userData.companyState === 3) {
        const title = 'Cuenta temporalmente suspendida'
        const message = 'Tu cuenta no ha sido verificada, es necesario enviar la documentación requerida para poder activar tu cuenta.'
        await this.$swalAlert('error', title, message)

        this.sendToVerifyCorp()
      }
    }
  }
}
