<template>
  <div id="UsersConfigurations">
    <v-card>
      <v-card-title>
        <v-btn
          class="primary"
          :loading="loading"
          :disabled="loading"
          @click="openModal(itemModel)"
        >
          <v-icon left>
            mdi-pencil
          </v-icon>
          Agregar usuario
        </v-btn>
        <v-spacer />

        <v-text-field
          v-if="!loading && items.length"
          v-model="search"
          placeholder="Buscar"
          v-bind="attrsSearching"
        />
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          v-if="loading"
          type="table"
        />
        <v-alert
          v-if="!loading && !items.length"
          class="mb-0"
          icon="mdi-folder-alert-outline"
          prominent
          text
          type="warning"
          align="left"
        >
          No hay usuarios registrados!
        </v-alert>

        <v-data-table
          v-if="!loading && items.length"
          :headers="headers"
          :items="items"
          sort-by="id"
          class="elevation-1 clickable-item-table"
          mobile-breakpoint="Infinity"
          :search="search"
          @click:row="openModal"
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ `${item.name} ${item.name2} ${item.name3}` }}
          </template>
          <template v-slot:[`item.pertenencia`]="{ item }">
            {{ placeUser(item.pertenencia) }}
          </template>
          <template v-slot:[`item.rol`]="{ item }">
            {{ getRolName(item.rol) }}
          </template>
          <template v-slot:[`item.activo`]="{ item }">
            <v-icon :color="item.activo ? 'green darken-3' : 'orange'">
              mdi-account{{ item.activo ? '' : '-off' }}
            </v-icon>
          </template>
          <template v-slot:[`item.admin`]="{ item }">
            <v-icon
              v-if="item.admin"
              color="green darken-3"
            >
              mdi-check-bold
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogUserConfigurations
      v-model="dialog"
      :places="places"
      :catalogueUsers="catalogueUsers"
      :item="itemSelected"
      @refresh="getUsers"
    />
  </div>
</template>

<script>
import DialogUserConfigurations from '../../components/dashboard/DialogUserConfigurations.vue'
export default {
  name: 'UsersConfigurations',
  components: { DialogUserConfigurations },
  data () {
    return {
      loading: false,
      attrsSearching: {
        label: '',
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field mr-4',
        'hide-details': true
      },
      search: '',
      items: [],
      headers: [
        { text: 'Id usuario', value: 'id' },
        { text: 'Usuario', value: 'user' },
        { text: 'Nombre', value: 'name' },
        { text: 'mail', value: 'mail' },
        { text: 'sitio', value: 'pertenencia' },
        { text: 'cargo', value: 'cargo' },
        { text: 'rol', value: 'rol' },
        { text: 'activo', value: 'activo', align: 'center' },
        { text: 'Super usuario', value: 'admin', align: 'center' }
      ],
      places: [],
      catalogueUsers: [],
      dialog: false,
      itemSelected: {},
      itemModel: {
        id: null,
        user: '',
        pass: '',
        name: '',
        name2: '',
        name3: '',
        admin: 0,
        rol: null,
        subrol: null,
        pertenencia: null,
        mail: '',
        activo: 1,
        cargo: null
      }
    }
  },
  mounted () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.loading = true
      this.$root.post('/CC/listUsers')
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message)
            return
          }

          const [items, places, catalogueUsers] = data
          this.items = items
          this.places = places
          this.catalogueUsers = catalogueUsers
        })
        .finally(() => { this.loading = false })
    },
    placeUser: function (placeId) {
      return this.places.find(place => place.id === placeId)?.name
    },
    getRolName: function (rol) {
      const userRol = ['Promtec', 'Aafy', 'Cultur', 'Coordinador', 'Mayorista']
      return userRol?.[rol] || ''
    },
    openModal (item) {
      this.itemSelected = JSON.parse(JSON.stringify(item))
      this.dialog = true
    }
  }
}
</script>

<style scoped>
#UsersConfigurations {
  max-height: calc(100vh - var(--h-navbar));
  overflow-y: auto;
  padding: 20px;
}

.search-text-field {
  max-width: 400px !important;
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css">
</style>
