<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-card style="box-shadow: none !important">
    <v-data-table :headers="headers[tab]" :items="items" item-key="id" :sort-by="['fecha']" :sort-desc="[true]"
      :search="search" multi-sort mobile-breakpoint="Infinity" :loading="loading">
      <template v-slot:no-data />
      <template v-slot:body="{ items }">
        <tbody v-if="!items.length">
          <td colspan="20">
            <v-alert class="mb-0 text" align="center">
              Sin datos para esta búsqueda
            </v-alert>
          </td>
        </tbody>
        <tbody v-else>
          <template v-for="(cartTransaction, index) in items">
            <tr class="row-transaction-data" :style="{ 'background-color': $backgroundRowTable(index) }"
              @click="clickrow(cartTransaction.id)">
              <td style="max-width: 10px">
                <v-icon class="ma-2">
                  mdi-{{ cartTransaction.id === expanded ? 'minus' : 'plus' }}
                </v-icon>
              </td>
              <td class="font-weight-bold">
                {{ cartTransaction.linearef }}
              </td>
              <td class="font-weight-bold">
                {{ cartTransaction.comprador }}
              </td>
              <td class="font-weight-bold">
                {{ cartTransaction.correo }}
              </td>
              <td class="font-weight-bold">
                {{ cartTransaction.telefono }}
              </td>
              <td :style="tab === 4 ? 'color: red; font-weight: bold' : ''">
                {{ cartTransaction.fvenci }}
              </td>
              <td :style="tab === 1 ? 'color: green; font-weight: bold' : ''">
                {{ cartTransaction.fecha }}
              </td>
              <td align="right">
                <strong>${{ $formatPrice(cartTransaction.total) }}
                  <ExchangeCurrency />
                </strong>
              </td>
              <td v-if="tab === 1 || tab === 2" align="center"
                :style="tab === 1 ? 'color: green; font-weight: bold' : ''">
                {{ cartTransaction.solicito }}
              </td>

              <td v-if="tab === 2 || tab === 3" align="center">
                <strong :style="tab === 2 ? 'color: blue' : ''">
                  {{ cartTransaction.f_autorizacion }}
                </strong>
              </td>

              <td v-if="tab === 1">
                <v-tooltip v-if="canEdit" bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success" v-bind="attrs" @click="validateTransaction(cartTransaction)" v-on="on">
                      <v-icon>mdi-clipboard-check-multiple-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Validar Transaccion</span>
                </v-tooltip>
              </td>

              <td v-if="tab === 2">
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success" v-bind="attrs" @click="invitateEvent(cartTransaction)" v-on="on">
                      <v-icon>mdi-email-fast-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Invitar al evento</span>
                </v-tooltip>
              </td>

              <td v-if="tab === 1 || tab === 2">
                <v-tooltip v-if="canEdit" bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="orange" dark v-bind="attrs" @click="lockTransaction(cartTransaction)" v-on="on">
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </template>
                  <span>Bloquear Transaccion</span>
                </v-tooltip>
              </td>

              <!-- <td v-if="tab === 1" align="center" style="min-width: 170px;">
                <v-tooltip v-if="canEdit" bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="blue" dark v-bind="attrs" @click="print(cartTransaction)" v-on="on">
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <span>Imprimir orden de pago</span>
                </v-tooltip>
              </td>

              <td v-if="tab === 2" align="center">
                <v-tooltip v-if="canEdit" bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="blue" dark v-bind="attrs" @click="print(cartTransaction)" v-on="on">
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <span>Imprimir recibo de pagos</span>
                </v-tooltip>
              </td> -->

              <td v-if="tab === 3" align="center">
                <strong style="color:red;">{{ cartTransaction.f_bloqueo }}</strong>
              </td>

              <td v-if="tab === 3">
                <v-tooltip v-if="canEdit" bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" dark v-bind="attrs" @click="unlockTransaction(cartTransaction)" v-on="on">
                      <v-icon>mdi-lock-open</v-icon>
                    </v-btn>
                  </template>
                  <span>Desbloquear Transaccion</span>
                </v-tooltip>
              </td>

            </tr>

            <tr v-if="cartTransaction.id === expanded" :key="`cart-transaction-details-${cartTransaction.id}`"
              style="background-color: white !important">
              <TicketsDetailsTransactions :placeDetails="cartTransaction.place" :transactionId="cartTransaction.id"
                v-if="tab != 2" />
              <TicketsDetailsTransactions2 :placeDetails="cartTransaction.place" :transactionId="cartTransaction.id"
                v-if="tab === 2" />
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title style="background-color: #1E1E1E;">
          <span style="color: white;" class="text-h5">Enviar boletos</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div style="padding-bottom: 10px;" v-on:click="">
              <label @click="invitatechecked = 1" class="InvitaLabel" style="margin-left: -16px;">
                <input type="radio" name="favAnimal" v-model="invitatechecked" value="1" class="largerRadio"
                  :checked="invitatechecked === 1"> Comprador
              </label>
              <label @click="invitatechecked = 2" class="InvitaLabel">
                <input type="radio" name="favAnimal" v-model="invitatechecked" value="2" class="largerRadio"
                  :checked="invitatechecked === 2"> Invitado
              </label>
            </div>
            <v-row style="background-color: white !important;">
              <template v-if="invitatechecked === 1">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="destinatary.nombre" label="Nombre(s)" outlined required class="code" readonly disabled 
                    hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="destinatary.paterno" label="Apellido Paterno" outlined hide-details readonly disabled 
                    class="text-uppercase"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="destinatary.materno" label="Apellido Materno" outlined hide-details required readonly disabled 
                    class="text-uppercase"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="destinatary.correo" label="Correo*" outlined required readonly disabled 
                    hide-details></v-text-field>
                </v-col>
              </template>
              <template v-if="invitatechecked === 2">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="invitate.nombre" label="Nombre(s)" outlined required @keyup="toUppercase('invitate.nombre')"
                    hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="invitate.paterno" label="Apellido Paterno" outlined hide-details @keyup="toUppercase('invitate.paterno')"
                    class="text-uppercase"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="invitate.materno" label="Apellido Materno" outlined hide-details required @keyup="toUppercase('invitate.materno')"
                    class="text-uppercase"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="invitate.correo" label="Correo*" outlined required
                    hide-details></v-text-field>
                </v-col>
              </template>
              <v-col cols="12">
                <template v-for="itemPlace in place">
                  <div style="display: flex;">
                    <div style="width: 50%; padding-top: 16px; font-size: 16px; font-weight: bold;">
                      {{ itemPlace.shortname }} {{ itemPlace.alias }}<br>{{ itemPlace.fecha }}
                    </div>
                    <div style="width: 50%;">
                      <div style="color: red; padding-right: 10px;" align="right">Disponibilidad {{ 12 - itemPlace.max
                        }}</div>
                      <InputAmountTickets v-model="itemPlace.cantidad" class="ma-3 mt-0 amount-tickets-available"
                        @change="changeAmountOfTicket(itemPlace)" @keyup="changeAmountOfTicket(itemPlace)" />
                    </div>
                  </div>
                </template>

              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-row style="background-color: white !important;">
            <v-col cols="6" align="center">
              <v-btn color="red" class="ma-2" dark @click="dialog = false">
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn color="primary" class="ma-2" dark @click="invitateEventSucess(place)">
                GENERAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import { mapMutations } from 'vuex/dist/vuex.common.js'
import WHOLESALERS from '../../minixs/wholesalers'

// Components
import TicketsDetailsTransactions from '../tools/TicketsDetailsTransactions.vue'
import TicketsDetailsTransactions2 from '../tools/TicketsDetailsTransactions2.vue'
import MYCART from '@/minixs/my-cart'

export default {
  mixins: [MYCART, WHOLESALERS],
  components: {
    TicketsDetailsTransactions,
    TicketsDetailsTransactions2
  },
  props: ['items', 'tab', 'search'],
  data() {
    return {
      invitatechecked: 1,
      loading: false,
      rol: 0,
      inline: null,
      dialog: false,
      destinatary: {
        nombre: '',
        paterno: '',
        materno: '',
        correo: '',
        telefono: ''
      },
      invitate: {
        nombre: '',
        paterno: '',
        materno: '',
        correo: '',
        telefono: ''
      },
      place: [],
      flag: false,
      expanded: '',
      tempInvitate: null,
      headers: [
        [],
        [
          { text: '', value: 'icon' },
          { text: 'Línea de Referencia', value: 'linearef' },
          { text: 'Comprador', value: 'comprador' },
          { text: 'Correo', value: 'correo' },
          { text: 'Telefono', value: 'telefono' },
          { text: 'Fecha de Vencimiento', value: 'fvenci' },
          { text: 'Fecha de Creacion', value: 'fecha' },
          { text: 'Costo Total', value: 'total', align: 'right' },
          { text: 'Solicito', value: 'solicito', align: 'center' },
          { text: 'Validar Orden', value: '', align: 'center', sortable: false },
          { text: 'Bloquear Orden', value: '', align: 'center', sortable: false }
        ],
        [
          { text: '', value: 'icon', sortable: false },
          { text: 'Línea de Referencia', value: 'linearef' },
          { text: 'Comprador', value: 'comprador' },
          { text: 'Correo', value: 'correo' },
          { text: 'Telefono', value: 'telefono' },
          { text: 'Fecha de Vencimiento', value: 'fvenci' },
          { text: 'Fecha de Creacion', value: 'fecha' },
          { text: 'Costo Total', value: 'total', align: 'right' },
          { text: 'Solicito', value: 'solicito', align: 'center' },
          { text: 'Fecha de Autorizacion', value: 'f_autorizacion', align: 'center' },
          { text: 'Enviar Boletos', value: '', align: 'center', sortable: false },
          { text: 'Bloquear Order', value: '', align: 'center', sortable: false }
        ],
        [
          { text: '', value: 'icon', sortable: false },
          { text: 'Línea de Referencia', value: 'linearef' },
          { text: 'Comprador', value: 'comprador' },
          { text: 'Correo', value: 'correo' },
          { text: 'Telefono', value: 'telefono' },
          { text: 'Fecha de Vencimiento', value: 'fvenci' },
          { text: 'Fecha de Creacion', value: 'fecha' },
          { text: 'Costo Total', value: 'total', align: 'right' },
          { text: 'Fecha de Autorizacion', value: 'f_autorizacion', align: 'center' },
          { text: 'Fecha de Bloqueo', value: 'f_bloqueo', align: 'center' },
          { text: 'Desbloquear Order', value: '', align: 'center', sortable: false }
        ],
        [
          { text: '', value: 'icon', sortable: false },
          { text: 'Línea de Referencia', value: 'linearef' },
          { text: 'Comprador', value: 'comprador' },
          { text: 'Correo', value: 'correo' },
          { text: 'Telefono', value: 'telefono' },
          { text: 'Fecha de Vencimiento', value: 'fvenci' },
          { text: 'Fecha de Creacion', value: 'fecha' },
          { text: 'Costo Total', value: 'total', align: 'right' }
        ]
      ]
    }
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      userData: 'auth/userData'
    }),
    canEdit() {
      return this.userData?.subrol < 3
    }
  },
  watch: {
    tab: function () {
      this.expanded = ''
    },
    search: function () {
      this.expanded = ''
    }
  },
  methods: {
    ...mapMutations({
      setLoadingMainApp: 'globalStates/setLoadingMainApp'
    }),
    changeAmountOfTicket: function (item) {
      const cantidad = 12 - item.max
      if (parseInt(item.cantidad) > parseInt(cantidad)) {
        item.cantidad = parseInt(cantidad)
      }
    },
    toUppercase(field) {
      try{
        let fields  =  field.split('.')
        this[fields[0]][fields[1]] = this[fields[0]][fields[1]].replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, '').toUpperCase()
      }catch(e){
        console.log(e);
      }
    },
    clickrow: function (id) {
      if (!this.flag) {
        this.expanded = this.expanded !== id ?
          id : ''
      } else {
        this.flag = false
      }
    },
    print: function (item) {
      this.flag = true

      this.$session.remove('tipo')
      const tipoX = { id: item.id, estatus: item.estatus, token: this.token, idCorp: this.userData.idCorp, format: 1 }
      this.$session.set('tipo', tipoX)

      setTimeout(() => {
        const routeData = this.$router.resolve({ name: 'PdfGenerator' })
        window.open(routeData.href, '_blank')
      }, 10)
    },
    validateTransaction: async function (item) {
      this.flag = true
      const self = this

      const result = await this.$swal({
        title: 'Confirmación de la línea de referencia',
        input: 'text',
        inputLabel: 'Escriba la línea de referencia',
        inputPlaceholder: 'Línea de referencia',
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: 'primary',
        confirmButtonText: 'Aceptar',
        denyButtonText: 'Cancelar',
        denyButtonColor: '#dd8159',
        preConfirm: (value) => {
          if (value !== item.linearef) {
            self.$swal.showValidationMessage('Las líneas de referencia no coinciden!')
          }
        }
      })

      if (!result.isConfirmed) return
      this.updateTransactionStatus(item, 2)
    },
    lockTransaction: async function (item) {
      this.flag = true
      const self = this

      const result = await this.$swal({
        icon: 'warning',
        title: '¿Desea bloquear esta Orden de Pago?',
        text: item.comprador + ': ' + item.linearef,
        input: 'textarea',
        inputLabel: 'Escriba el motivo del bloqueo',
        inputPlaceholder: 'Escriba motivo',
        inputAttributes: {
          maxlength: 250,
          'aria-label': 'Type your message here'
        },
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: 'primary',
        confirmButtonText: 'SI',
        denyButtonColor: '#dd8159',
        preConfirm: (value) => {
          if (!value) {
            self.$swal.showValidationMessage('Se necesita un motivo!')
          }
        }
      })

      if (!result.isConfirmed) return
      this.updateTransactionStatus(item, 3, result.value)
    },
    unlockTransaction: async function (item) {
      this.flag = true
      const result = await this.$ConfirmBox('warning', '¿Desea desbloquear esta Orden de Pago?', item.comprador + ': ' + item.linearef)
      if (!result.isConfirmed) return
      this.updateTransactionStatus(item, 4)
    },
    lockInformation: function (item) {
      this.flag = true

      const info = item.info.replaceAll('\\n', '\n')
      this.$swal({
        title: 'Motivo',
        html: `<textarea rows="5" cols="33" disabled  style="resize: none; border: 1px solid #b7b7b7; margin: 0; padding: 10px; width: 100%;">${info}</textarea>`,
        showDenyButton: false,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: 'primary',
        confirmButtonText: 'OK',
        denyButtonColor: '#dd8159'
      })
    },
    updateTransactionStatus(item, estatusX, info) {
      this.setLoadingMainApp(true)
      this.$root
        .post('/UpdateTransMayo', {
          idX: item.id,
          estatusX,
          info
        })
        .then(() => {
          window.open(`http://localhost:8080/my-cart/${estatusX}`, '_self')
        }).finally(() => { 
          this.setLoadingMainApp(false)
        })

    },
    invitateEvent: async function (invitate) {
      this.flag = true
      this.invitatechecked = 1

      console.log(invitate);
      
      
      this.destinatary = {
        nombre: invitate.nombres ? invitate.nombres.toUpperCase() : invitate.nombres,
        paterno: invitate.paterno ? invitate.paterno.toUpperCase() :  invitate.paterno,
        materno: invitate.materno ? invitate.materno.toUpperCase() : invitate.materno,
        correo: invitate.correo,
        telefono: invitate.telefono
      }

      this.invitate = { nombre: null, paterno: null, materno: null, correo: null, telefono: null}

      invitate.place.map((ele) => {
        ele.cantidad = 0
      })

      this.place = invitate.place

      this.dialog = true
    },
    invitateEventSucess: async function (itemPlace) {
      let infoDestinatary = []

      if (!this.destinatary.nombre || !this.destinatary.correo) {
        this.$swalAlert('warning', 'advertencia', 'completa los datos del invitado')
        return false
      }

      for (let x = 0; x < itemPlace.length; x++) {
        infoDestinatary.push({ "cantidad": itemPlace[x].cantidad, "id_carrito": itemPlace[x].transaccion[0].id_carrito })
      }

      if(this.invitatechecked === 1){
        this.invitate = { nombre: null, paterno: null, materno: null, correo: null, telefono: null}
      }

      const data = { "infoDestinatary": infoDestinatary, "destinatary": this.destinatary, "invitate":this.invitate, "invitatechecked":this.invitatechecked }

      this.setInvitate(data)
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.row-transaction-data {
  color: black;
  cursor: pointer;
  border-top: 2px solid #75695f
}

.row {
  background-color: #e2e2e2;
}

.InvitaLabel {
  font-size: 18px;
}

.InvitaLabel:hover {
  cursor: pointer;
}

input.largerRadio {
  width: 20px;
  height: 20px;
  margin: 3px 0px 16px 16px;
}
.input {
  text-transform: uppercase;
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css"></style>
