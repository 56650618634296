<template>
  <div id="ReportTicketSalesTable">
    <table style="width: 500px;">
      <tr class="text-center">
        <td colspan="6">
          <h2>Reporte de mayoristas</h2>
        </td>
      </tr>
      <tr class="text-center">
        <td colspan="6">
          <h3>boletos de vendidos, generados y usudos</h3>
        </td>
      </tr>
      <br>
      <template v-for="(item, index1) in ticketsDetails">

        <tr>
          <td align="left" style="padding-inline: 5px; font-weight: bold;" colspan="5">
            <hr v-if="index1 > 0">
            {{ item.name_whoseller }}
          </td>
        </tr>
        
        <template v-for="(ele, index2) in item.sitios">
          <tr>
            <td colspan="5" style="font-size: 12px; padding-inline: 10px; background-color: #195b46; font-weight: bold; color: white;">
              {{ ele.name_sitio }}
            </td>
          </tr>
          <tr style="font-size: 11px;">
            <td class="myclass2" style="background-color: #195b46; color: white;">Boleto</td>
            <td class="myclass2 text-center" style="background-color: #195b46; color: white;">Comprado</td>
            <td class="myclass2 text-center" style="background-color: #195b46; color: white;">Generado</td>
            <td class="myclass2 text-center" style="background-color: #195b46; color: white;">Usado</td>
            <td class="myclass2 text-center" style="background-color: #195b46; color: white;">Sin usar</td>
          </tr>
          <tr v-for="(ele2, index3) in ele.tickets" style="font-size: 11px;">
            <td class="myclass" style="text-align: left;">
              {{ ele2.ticket }}
            </td>
            <td class="myclass">
              {{ $formatNum(ele2.total) }}
            </td>
            <td class="myclass">
              {{ $formatNum(ele2.generated) }}
            </td>
            <td class="myclass">
              {{ $formatNum(ele2.used) }}
            </td>
            <td class="myclass">
              {{ $formatNum(ele2.generated - ele2.used) }}
            </td>
          </tr>
          <tr style="font-size: 11px;">
            <td></td>
            <td class="myborder">{{ $formatNum(ele.total) }}</td>
            <td class="myborder">{{ $formatNum(ele.generated) }}</td>
            <td class="myborder">{{ $formatNum(ele.used) }}</td>
            <td class="myborder">{{ $formatNum(ele.generated - ele.used) }}</td>
          </tr>
        </template>
        <tr>
          <td colspan="5" style="height: 10px;"></td>
        </tr>

      </template>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ReportTicketSalesTable',
  props: [
    'titleReport',
    'ticketsDetails',
  ],
  data() {
    return {
      headersReport: [
        { name: 'Fecha', attrs: {} },
        { name: 'Vendidos', attrs: {} },
        { name: 'Canjes', attrs: {} },
        { name: 'total', attrs: {} }
      ],
      detailsReport: [
        {
          key: 'Fecha',
          attrs: { class: 'pt-2' }
        }
      ]
    }
  }

}
</script>

<style scoped>
.myclass {
  padding: 5px !important;
  text-align: center;
  padding-bottom: 0 !important;
    padding-top: 0 !important;
}
.myclass2{
  padding-inline: 10px;
  color: rgb(255, 255, 255);
}
.myborder{
  border-top: 1px solid black;
  text-align: center;
  font-weight: bold;
}
</style>
