<template>
  <div id="PosConfiguration">
    <v-card>
      <v-card-title>
        <v-btn
          class="success"
          :loading="loading"
          :disabled="loading"
          @click="updateCashierCatalogue"
        >
          <v-icon left>
            mdi-refresh
          </v-icon>
          Refrescar
        </v-btn>
        <v-spacer />
        <v-text-field
          v-if="!loading && items.length"
          v-model="search"
          placeholder="Buscar"
          v-bind="attrsSearching"
        />
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          v-if="loading"
          type="table"
        />
        <v-alert
          v-if="!loading && !items.length"
          class="mb-0"
          icon="mdi-printer-pos-alert-outline"
          prominent
          text
          type="warning"
          align="left"
        >
          Catalogo de cajas vacio!
        </v-alert>

        <v-data-table
          v-if="!loading && items.length"
          :headers="headers"
          :items="items"
          :search="search"
          sort-by="active"
          :sort-desc="true"
          class="elevation-1 clickable-item-table"
          mobile-breakpoint="Infinity"
          @click:row="openModal"
        />
      </v-card-text>
    </v-card>

    <DialogPosConfigurations
      v-model="showPosConfiguration"
      :item="itemEditing"
      :places="places"
      :bankAccounts="bankAccounts"
      @refresh="getPOSList"
    />
  </div>
</template>

<script>
import DialogPosConfigurations from '../../components/dashboard/DialogPosConfigurations.vue'

export default {
  name: 'PosConfiguration',
  components: { DialogPosConfigurations },
  data () {
    return {
      headers: [
        { text: 'Id Cajero', value: 'IdCajero' },
        { text: 'Nombre Largo', value: 'name' },
        { text: 'Nombre Corto', value: 'name2' },
        { text: 'Entidad', value: 'entidad' },
        { text: 'Sitio', value: 'sitio' },
        { text: 'Cuota Promtec', value: 'Cuenta', filterable: false }
      ],
      items: [],
      loading: false,
      attrsSearching: {
        label: '',
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field mr-4',
        'hide-details': true
      },
      search: '',
      showPosConfiguration: false,
      places: [],
      bankAccounts: [],
      itemEditing: {}
    }
  },
  mounted () {
    this.getPOSList()
  },
  methods: {
    getPOSList: function () {
      this.loading = true
      this.$root.post('/CC/listPOS')
        .then(response => {
          const data = response.data
          const dataStore = data?.[0]

          if (data?.success === 'FALSE' || dataStore?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message || dataStore?.message)
            return
          }

          const [items, places, bankAccounts] = data

          this.items = items || []
          this.places = places || []
          this.bankAccounts = bankAccounts || []
        })
        .finally(() => { this.loading = false })
    },
    updateCashierCatalogue: function () {
      this.loading = true
      const data = { catalogue: 'tabkassakonf' }

      this.$root.post('/api2/updateCatalogue', data)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message)
            return
          }

          this.getPOSList()
        }).finally(() => { this.loading = false })
    },
    openModal: function (item) {
      this.itemEditing = JSON.parse(JSON.stringify(item))
      this.showPosConfiguration = true
    }
  }
}
</script>

<style scoped>
#PosConfiguration {
  max-height: calc(100vh - var(--h-navbar));
  overflow-y: auto;
  padding: 20px;
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css">
</style>
