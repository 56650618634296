<template>
  <div id="ShipmentHistory">
    <v-toolbar
      height="45px"
      dark
      elevation="0"
      color="#1e1e1e"
    >
      <v-spacer />
      <div class="mr-4">
        <InputDatePickerReport
          v-model="selectedDate"
          :forcedMax="yesterdayDate"
          :disabled="loading"
          @change="getTransactionsDate"
        />
      </div>
      <v-text-field
        v-model="search"
        placeholder="Escribe lo que deseas buscar"
        v-bind="attrsSearching"
        :disabled="loading || !items.length"
      />
    </v-toolbar>

    <div class="content-with-fixed-toolbar">
      <v-card>
        <v-card-text>
          <v-skeleton-loader
            v-if="loading"
            type="table"
          />
          <v-alert
            v-if="!loading && !items.length"
            class="mb-0"
            icon="mdi-folder-alert-outline"
            prominent
            text
            type="warning"
          >
            No hay bitacoras para el día {{ selectedDate }}
          </v-alert>

          <v-data-table
            v-if="!loading && items.length"
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            multi-sort
            mobile-breakpoint="Infinity"
          >
            <template v-slot:body="{ items: itemsSearched }">
              <tbody v-if="!itemsSearched.length">
                <td colspan="20">
                  <v-alert
                    class="mb-0 text"
                    align="center"
                  >
                    Sin datos para esta búsqueda
                  </v-alert>
                </td>
              </tbody>
              <tbody v-if="itemsSearched.length">
                <tr
                  v-for="item in itemsSearched"
                  :key="`shipment-history-${item.iFolioBitacora}-${item.ConsecutivoEnvio}-${item.idSucursal}-${item.idEquipo}-${item.idCajero}-${item.fechaEnvio}`"
                >
                  <td align="center">
                    <strong>{{ item.iFolioBitacora }}</strong>
                  </td>
                  <td align="center">
                    {{ item.sCodigoRecepcion }}
                  </td>
                  <td align="center">
                    {{ item.ConsecutivoEnvio }}
                  </td>
                  <td align="center">
                    {{ item.idSucursal }}
                  </td>
                  <td align="center">
                    {{ item.idEquipo }}
                  </td>
                  <td align="center">
                    {{ item.idCajero }}
                  </td>
                  <td align="center">
                    {{ item.cError }}
                  </td>
                  <td align="center">
                    {{ item.fechaEnvio }}
                  </td>
                  <td
                    v-if="userData.subrol != 4"
                    align="center"
                  >
                    <v-btn
                      small
                      dark
                      color="red"
                      class="ma-2"
                      @click="sentBitaAafy(item)"
                    >
                      <v-icon>mdi-cloud-upload</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
// Components
import InputDatePickerReport from '../tools/reports/InputDatePickerReport.vue'

export default {
  name: 'ShipmentHistory',
  components: { InputDatePickerReport },
  props: ['yesterdayDate', 'isActive'],
  data () {
    return {
      attrsSearching: {
        label: '',
        outlined: true,
        dark: true,
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field',
        'hide-details': true
      },
      selectedDate: null,
      search: '',
      loading: false,
      items: [],
      headers: [
        { text: 'Folio en Bitacora', value: 'iFolioBitacora', width: '', filterable: true },
        { text: 'Código de Recepción', value: 'sCodigoRecepcion', width: '', filterable: true },
        { text: 'Consecutivo de Envio', value: 'ConsecutivoEnvio', width: '', filterable: true },
        { text: 'Sucursal', value: 'idSucursal', width: '', filterable: true },
        { text: 'Caja', value: 'idEquipo', width: '', filterable: true },
        { text: 'Cajero', value: 'idCajero', width: '', filterable: true },
        { text: 'Descripción de Error', value: 'cError', width: '', filterable: true },
        { text: 'Fecha Envio', value: 'fechaEnvio', width: '', filterable: true },
        { text: 'Nuevo envio', value: '', width: '', filterable: false }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    })
  },
  watch: {
    isActive: function (val) {
      if (val) this.getTransactionsDate()
    }
  },
  created () {
    this.selectedDate = this.yesterdayDate
    this.getTransactionsDate()
  },
  methods: {
    ...mapMutations({
      setLoadingMainApp: 'globalStates/setLoadingMainApp',
      setTextLoadingMainApp: 'globalStates/setTextLoadingMainApp'
    }),
    getTransactionsDate: function () {
      this.loading = true
      this.items = []
      this.search = ''

      const data = { selectedDate: this.selectedDate }
      this.$root.post('/aafy/binnacle', data)
        .then((response) => {
          const data = response?.data
          if (data.success === 'FALSE') return this.$swalAlert('error', null, data.message)

          this.items = data || []
        })
        .finally(() => { this.loading = false })
    },
    sentBitaAafy: async function name (item) {
      const response = await this.$ConfirmBox('warning', '¿Desea reenviar la bitacora?')
      if (!response?.isConfirmed) return

      const data = {
        selectedDate: this.selectedDate,
        place: item.idSucursal,
        pos: item.idEquipo,
        cashier: item.idCajero,
        consecutivo: item.ConsecutivoEnvio
      }

      this.setLoadingMainApp(true)
      this.setTextLoadingMainApp('Enviando')

      this.$root.post('/aafy/sendPOS', data)
        .then((response) => {
          const info = response?.data
          if (info.success === 'FALSE') return this.$swalAlert('error', info.message, null)

          this.$swalAlert('success', 'Reenviado correctamente')
        }).finally(() => {
          this.setLoadingMainApp(false)
          this.setTextLoadingMainApp('')
          setTimeout(() => {
            this.getTransactionsDate()
          }, 100)
        })
    }
  }
}
</script>

<style scoped>
.search-text-field {
  max-width: 800px !important;
}

.content-with-fixed-toolbar {
  height: calc(100vh - var(--h-navbar) - 45px - 45px);
  overflow-y: auto;
  padding: 20px;
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css">
</style>
