import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Libraries
import vuetify from './plugins/vuetify'
import swal from './plugins/swal'
import i18n from './i18n/'
import axios from 'axios'
import VueSessionStorage from 'vue-sessionstorage'
import { VueMaskDirective } from 'v-mask'
import { Chart, registerables } from 'chart.js'

// Utils
import './utils/globalFunctions'
import './utils/numberToLetter'
import './utils/validation'
import './utils/styleTools'

// Components
import ExchangeCurrency from './components/tools/ExchangeCurrency.vue'
import InputAmountTickets from './components/tools/InputAmountTickets.vue'

Vue.use(VueSessionStorage)
Chart.register(...registerables)
Vue.component('ExchangeCurrency', ExchangeCurrency)
Vue.component('InputAmountTickets', InputAmountTickets)

Vue.directive('mask', VueMaskDirective)

Vue.config.productionTip = true

new Vue({
  router,
  store,
  swal,
  i18n,
  vuetify,
  data: {
    socket: null,
    myserver: '//199.89.54.175:', // myserver:'//199.89.53.147:',  -> ya no se usa pero lo tengo para recordar la ip del server
    //myserver: '//localhost:',
    mySock: 'ws://localhost:', // url socket  -> se autoconfigura
    myport: '9999', // port activo -> se auto configura
    myport1: '9999', // http
    myport2: '9999' // https
  },
  created () {
    this.conectToServerAndSocket()
  },
  methods: {
    conectToServerAndSocket: function () {
      if (window.location.protocol === 'https:') {
        this.mySock = 'wss:' + this.myserver
        this.myserver = 'https:' + this.myserver
        this.myport = this.myport2
      } else {
        this.mySock = 'ws:' + this.myserver
        this.myserver = 'http:' + this.myserver
        this.myport = this.myport1
      }
    },
    post: function (endPoint, data) {
      const URL = this.$root.myserver + this.$root.myport + endPoint
      const user = JSON.parse(localStorage.getItem('user'))

      return new Promise((resolve, reject) => {
        axios
          .post(
            URL,
            data,
            { headers: { 'X-Access-Token': user?.token } }
          )
          .then((response) => {
            const { data, headers } = response
            if (data.exit) {
              this.logout()
              this.$swalAlert('error', data.message || data.msg || data.message || 'Error inesperado')
              return resolve(response)
            }

            if (headers?.tokenuserx) {
              user.token = headers?.tokenuserx
              this.$store.dispatch('auth/updateToken', headers?.tokenuserx || '')
            }
            resolve(response)
          })
          .catch((error) => {
            const statusResponse = error?.response?.status
            const notFound = statusResponse === 404 ? 'API no encontrada!' : ''
            const message = notFound || error.message || error.Msg ||
              error?.response?.data ||
              error.toString()
            this.$swalAlert('error', 'Error', message)
            reject(error)
          })
      })
    },
    post2: function (endPoint, data) {
      let port = 5624
      if(this.$root.myport == 9999){
        port= 5623
      }
      const URL = this.$root.myserver + port + endPoint
      const user = JSON.parse(localStorage.getItem('user'))

      return new Promise((resolve, reject) => {
        axios
          .post(
            URL,
            data,
            { headers: { 'X-Access-Token': user?.token } }
          )
          .then((response) => {
            const { data, headers } = response
            if (data.exit) {
              this.logout()
              this.$swalAlert('error', data.message || data.msg || data.message || 'Error inesperado')
              return resolve(response)
            }

            if (headers?.tokenuserx) {
              user.token = headers?.tokenuserx
              this.$store.dispatch('auth/updateToken', headers?.tokenuserx || '')
            }
            resolve(response)
          })
          .catch((error) => {
            const statusResponse = error?.response?.status
            const notFound = statusResponse === 404 ? 'API no encontrada!' : ''
            const message = notFound || error.message || error.Msg ||
              error?.response?.data ||
              error.toString()
            this.$swalAlert('error', 'Error', message)
            reject(error)
          })
      })
    },
    logout: function () {
      try {
        if (this.socket) this.socket.disconnect()
        this.$router.push({ name: 'Login' })
        this.$store.commit('auth/logoutUser')
      } catch (e) {}
    }
  },
  render: (h) => h(App)
}).$mount('#app')
