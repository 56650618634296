<template>
  <div id="ManualCancellations">
    <v-card>
      <v-card-title>
        <v-btn
          class="primary"
          :loading="loading"
          :disabled="loading"
          @click="openModal(itemModel)"
        >
          <v-icon left>
            mdi-pencil
          </v-icon>
          Agregar
        </v-btn>
        <v-spacer />

        <v-text-field
          v-if="!loading && items.length"
          v-model="search"
          placeholder="Buscar"
          v-bind="attrsSearching"
        />
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          v-if="loading"
          type="table"
        />
        <v-alert
          v-if="!loading && !items.length"
          class="mb-0"
          icon="mdi-folder-alert-outline"
          prominent
          text
          type="warning"
          align="left"
        >
          No hay cancelaciones manuales registradas!
        </v-alert>

        <v-data-table
          v-if="!loading && items.length"
          :headers="headers"
          :items="items"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          mobile-breakpoint="Infinity"
          :search="search"
        >
          <template v-slot:[`item.activo`]="{ item }">
            <v-icon
              :color="item.activo ? '#5d99c6' : '#26a69a'"
              dark
            >
              mdi-{{ item.activo ? 'timer' : 'check-bold' }}
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="mx-1"
              color="primary"
              :disabled="!item.activo"
              small
              @click="openModal(item)"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-1"
              small
              color="error"
              :disabled="!item.activo"
              @click="deleteItem(item)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogManualCancellations
      v-model="dialog"
      :item="itemEditing"
      @refresh="getManualCancellations"
    />
  </div>
</template>

<script>
import DialogManualCancellations from '../../components/dashboard/DialogManualCancellations.vue'
export default {
  name: 'ManualCancellations',
  components: { DialogManualCancellations },
  data () {
    return {
      loading: false,
      attrsSearching: {
        label: '',
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field mr-4',
        'hide-details': true
      },
      search: '',
      items: [],
      headers: [
        { text: 'ID', value: 'id', align: 'center' },
        { text: 'Transaccion Original', value: 'transI', align: 'center' },
        { text: 'No. Caja', value: 'caja', align: 'center' },
        { text: 'Fecha del evento', value: 'fecha', align: 'center' },
        { text: 'Transaccion de Cancelacion', value: 'transC', align: 'center' },
        { text: 'Estatus', value: 'activo', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],
      itemModel: {
        id: null,
        transI: null,
        caja: null,
        fecha: null,
        transC: null,
        activo: 0
      },
      itemEditing: {},
      dialog: false
    }
  },
  mounted () {
    this.getManualCancellations()
    this.itemModel.fecha = this.$sumarDias(new Date(), 0)
  },
  methods: {
    getManualCancellations () {
      this.loading = true
      this.search = ''
      this.$root.post('/CC/manualCancellations')
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message)
            return
          }

          this.items = data
        })
        .finally(() => { this.loading = false })
    },
    openModal: function (item) {
      if (!item.activo && !!item.id) return this.$Toast('warning', 'La cancelación ya fue procesada')
      this.itemEditing = JSON.parse(JSON.stringify(item))
      this.dialog = true
    },
    deleteItem: async function (item) {
      if (!item.activo && !!item.id) return this.$Toast('warning', 'La cancelación ya fue procesada')

      const response = await this.$ConfirmBox('warning', '¿Estas seguro de eliminar la cancelación manual?')
      if (!response.isConfirmed) return

      this.loading = true
      this.$root.post('/CC/deleteManualCancellation', item)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message)
            return
          }

          this.$swalAlert('success', null, data.message)
          this.getManualCancellations()
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped>
#ManualCancellations {
  max-height: calc(100vh - var(--h-navbar));
  overflow-y: auto;
  padding: 20px;
}

.search-text-field {
  max-width: 400px !important;
}
</style>

<style scoped src="../../assets/css/style-tables-dark.css">
</style>
