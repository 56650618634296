<template>
  <v-menu
    v-model="showPicker"
    :close-on-content-click="false"
    :nudge-right="left ? 0 : 40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :left="left"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateComputed"
        :label="label"
        prepend-icon="mdi-calendar"
        outlined
        readonly
        hide-details
        dense
        class="input-date"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="dateComputed"
      :max="max"
      :type="type ? type : 'date'"
      @input="showPicker = false"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'InputDatePickerReport',
  props: ['value', 'label', 'noMaxToday', 'newMax', 'forcedMax', 'disabled', 'type', 'left'],
  data () {
    return {
      showPicker: false,
      max: ''
    }
  },
  computed: {
    dateComputed: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change')
      }
    }
  },
  watch: {
    newMax: function (val) {
      this.max = val

      const dateCurrent = Date.parse(this.dateComputed)
      const maxDate = Date.parse(this.max)
      if (dateCurrent > maxDate) this.dateComputed = val
    }
  },
  mounted () {
    this.max = this.forcedMax
      ? this.forcedMax
      : this.noMaxToday ? '' : this.$sumarDias(new Date(), 0)
  }
}
</script>

<style scoped>
.input-date >>> .v-input__slot {
  min-height: 45px !important;
}
</style>
