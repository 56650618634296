<template>
  <div>
    <div v-if="items.length" align="right">
      <p class="text-h5 font-weight-bold mb-1">
        ${{ $formatPrice(totalPrecart) }}
        <ExchangeCurrency />
      </p>
      <v-btn v-if="canEdit" color="warning" dark @click="generatePaymentOrder">
        Generar Orden de Pago
      </v-btn>
    </div>
    <v-simple-table v-for="placeData in items" :key="`table-precart-place-${placeData.sitio}_${placeData.fecha}`"
      class="mb-4">
      <thead>
        <tr>
          <td class="place-title">
            {{ placeData.name }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr style="background-color: rgba(255, 165, 0, 0.4);">
          <td>
            <v-simple-table>
              <thead>
                <tr style="background: rgb(253 142 48 / 22%);">
                  <td style="width:40%;">
                    <strong>Nombre del Boleto</strong>
                  </td>
                  <td align="center" style="width:10%">
                    <strong>Fecha Limite de uso</strong>
                  </td>
                  <td align="center" style="width:10%">
                    <strong>Cantidad</strong>
                  </td>
                  <td align="right" style="width:20%;">
                    <strong>Precio Unitario</strong>
                  </td>
                  <td align="right" style="width:20%;">
                    <strong>Precio total</strong>
                  </td>
                  <td v-if="canEdit" align="center" style="width:20%;">
                    <strong>Eliminar</strong>
                  </td>
                </tr>
              </thead>
      <tbody>
        <tr v-for="ticketData in placeData.place"
          :key="`table-precart-place-items-${ticketData.id_ticket}-${ticketData.persontype}-${ticketData.npool}`">
          <td>
            {{ ticketData.nombre }}
            <span v-if="ticketData.alias">({{ ticketData.alias }})</span>
          </td>
          <td align="center" class="red--text font-weight-bold">
            {{ ticketData.Ffin }}
          </td>
          <td align="center" class="CardFormat">
            <!-- <InputAmountTickets
                      v-if="canEdit"
                      v-model="ticketData.cantidad"
                      :checkFirtsPress="false"
                      @change="$emit('changeOnPrecart')"
                    />
                    <strong v-else> {{ ticketData.cantidad }}</strong> -->
            <strong> 1 </strong>
          </td>
          <td align="right" class="text-body-1 font-weight-bold">
            ${{ $formatPrice(ticketData.costo) }}
            <ExchangeCurrency />
          </td>
          <td align="right" class="text-body-1 font-weight-bold">
            ${{ $formatPrice(ticketData.costo) }}
            <ExchangeCurrency />
          </td>
          <td v-if="canEdit" align="right">
            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="red" class="ma-2" v-bind="attrs" @click="deleteItemPrecart(ticketData)" v-on="on">
                  <v-icon size="29px">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar del carrito</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    </td>
    </tr>
    </tbody>
    </v-simple-table>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title style="background-color: #1E1E1E;">
          <span style="color: white;" class="text-h5">Datos del comprador</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="nombre" label="Nombre(s)" outlined required  class="text-uppercase" @keyup="toUppercase('nombre')"
                  hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="paterno" label="Apellido Paterno" outlined @keyup="toUppercase('paterno')"
                  hide-details  class="text-uppercase"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="materno" label="Apellido Materno" outlined hide-details @keyup="toUppercase('materno')"
                  required  class="text-uppercase"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="correo" label="Correo*" outlined required hide-details></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="telefono" label="Telefono*" outlined required
                  hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6" align="center">
              <v-btn color="red" class="ma-2" dark @click="dialog = false">
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn color="primary" class="ma-2" dark @click="generatePaymentOrderSucess()">
                GENERAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TablePreCarrito',
  props: ['totalPrecart', 'items'],
  data() {
    return {
      rol: 0,
      dialog: false,
      nombre: '',
      paterno: '',
      materno: '',
      correo: '',
      telefono: ''
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    }),
    canEdit() {
      return this.userData?.subrol < 3
    }
  },
  methods: {
    toUppercase(field) {
      try{
        this[field] = this[field].replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, '').toUpperCase()
      }catch(e){
        console.log(e);
      }
    },
    generatePaymentOrder: async function () {     
      this.nombre = ''
      this.paterno = ''
      this.materno = ''
      this.correo = ''
      this.telefono = ''

      this.dialog = true
    },
    generatePaymentOrderSucess: async function () {
      if(!this.nombre || !this.correo){
        this.$swalAlert('warning', 'advertencia', 'completa los datos del comprador')
        return false
      }

      let comprador = {nombre: this.nombre, paterno: this.paterno, materno: this.materno, correo: this.correo, tel: this.telefono}
      
      this.$emit('generatePaymentOrder', comprador)
      this.dialog = false
    },
    deleteItemPrecart: async function (ticket) {
      const result = await this.$ConfirmBox('warning', '¿Esta seguro?', '¡Eliminar del carrito!')

      if (!result.isConfirmed) return
      this.$emit('deleteItemPrecart', ticket)
    }
  }
}
</script>

<style scoped>
.place-title {
  background: white;
  padding: 5px !important;
  height: 40px;
  font-size: 20px !important;
  font-weight: bold;
}
</style>
