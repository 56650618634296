<template>
  <div id="WholesalersDashboard">
    <v-tabs
      v-model="wholesalersItemActive"
      height="45px"
      dark
      grow
      show-arrows
      slider-color="orange"
    >
      <v-tab
        v-for="(item, index) in itemsForWholesalersTabs"
        :id="`tab${index+1}`"
        :key="`tab-${index+1}`"
      >
        <v-icon class=" ma-2">
          {{ item.icon }}
        </v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="wholesalersItemActive"
      touchless
      style="background-color: transparent !important;"
    >
      <v-tab-item
        v-for="(item, index) in itemsForWholesalersTabs"
        :key="`tab-${index+1}`"
      >
        <PendingTransactions
          v-if="item.type === 'toValidateTransactions' || item.type === 'expiredTransactions'"
          :activeNow="wholesalersItemActive === index"
          :isExpiredTrasactions="item.type === 'expiredTransactions'"
          :nowDate="nowDate"
        />
        <WholesalersTransactions
          v-if="item.type === 'authorizedTransactions' || item.type === 'lockTransactions'"
          :activeNow="wholesalersItemActive === index"
          :isLockTrasactions="item.type === 'lockTransactions'"
          :nowDate="nowDate"
        />
        <TicketsCorps
          v-if="item.type === 'ticketsCorp'"
          :activeNow="wholesalersItemActive === index"
          :nowDate="nowDate"
        />
        <ReportWholesalers
          v-if="item.type === 'report'"
          :activeNow="wholesalersItemActive === index"
          :nowDate="nowDate"
        />
        <WholesalesSearchEngine
          v-if="item.type === 'searchEngine'"
          :activeNow="wholesalersItemActive === index"
          :nowDate="nowDate"
        />
        <CompaniesWholesalers
          v-if="item.type === 'companies'"
          :activeNow="wholesalersItemActive === index"
          :nowDate="nowDate"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// Vue
import { mapGetters } from 'vuex'

// Componets
import PendingTransactions from '../../components/dashboard/wholesalers/PendingTransactions.vue'
import ReportWholesalers from '../../components/dashboard/wholesalers/ReportWholesalers.vue'
import TicketsCorps from '../../components/dashboard/wholesalers/TicketsCorps.vue'
import WholesalersTransactions from '../../components/dashboard/wholesalers/WholesalersTransactions.vue'
import WholesalesSearchEngine from '../../components/dashboard/wholesalers/WholesalesSearchEngine.vue'
import CompaniesWholesalers from '../../components/dashboard/wholesalers/CompaniesWholesalers.vue'

export default {
  name: 'WholesalersDashboard',
  components: { PendingTransactions, WholesalersTransactions, TicketsCorps, ReportWholesalers, WholesalesSearchEngine, CompaniesWholesalers },
  data () {
    return {
      wholesalersItemActive: null,
      itemsForWholesalersTabs: [
        { title: 'Por validar', icon: 'mdi-clipboard-text-clock-outline', type: 'toValidateTransactions' },
        { title: 'Autorizados', icon: 'mdi-clipboard-check-multiple-outline', type: 'authorizedTransactions' },
        { title: 'Bloqueados', icon: 'mdi-cancel', type: 'lockTransactions' },
        { title: 'Vencidos', icon: 'mdi-calendar-clock', type: 'expiredTransactions' },
        { title: 'Boletos', icon: 'mdi-ticket-confirmation-outline', type: 'ticketsCorp' },
        { title: 'Reporte', icon: 'mdi-file-document-edit-outline', type: 'report' },
        { title: 'Buscador', icon: 'mdi-clipboard-text-search-outline', type: 'searchEngine' },
        { title: 'Empresas', icon: 'mdi-domain', type: 'companies' }
      ],
      loading: false,
      nowDate: '',
      dialog: true
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    })
  },
  mounted () {
    this.nowDate = this.$sumarDias(new Date(), 0)
  },
  created: function () {
    this.$root.socket.removeListener('changeStatusTransaction')
    this.$root.socket.on('changeStatusTransaction', (res) => {
      if (this.userData.id === res.idUserX) this.$swalAlert('success', res.msgNotification)
    })
  }
}
</script>

<style scoped>
#WholesalersDashboard {
  position: relative;
}
</style>
