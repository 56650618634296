<template>
  <v-dialog
    id="DialogUserConfigurations"
    v-model="dialog"
    max-width="900px"
    persistent
  >
    <v-card tile>
      <v-toolbar
        dark
        style="border-radius: 0px !important"
      >
        <v-card-title>
          <span class="text-h5">Configuracion de usuario</span>
        </v-card-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        <v-form ref="userConfigurationsForm">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="formUser.user"
                label="Nombre de usuario"
                :rules="[$rules.required]"
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="formUser.mail"
                label="Correo electronico"
                :rules="[$rules.required, $rules.isEmail]"
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="formUser.pass"
                label="Contraseña"
                :rules="[$rules.required]"
                :type="hidePassword ? 'password' : 'text'"
                :append-icon="`mdi-${hidePassword ? 'eye-off' : 'eye'}`"
                hide-details="auto"
                @click:append="hidePassword = !hidePassword"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="formUser.name"
                label="Nombre"
                :rules="[$rules.required]"
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="formUser.name2"
                label="Apellido Paterno"
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="formUser.name3"
                label="Apellido Materno"
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              style="height: 100%;"
            >
              <v-select
                v-model="formUser.rol"
                label="Rol"
                :items="selectRol"
                :disabled="isEdit && (formUser.rol === 4 || formUser.rol === 0)"
                item-text="name"
                item-value="id"
                :rules="[$rules.requiredOrZero]"
                hide-details="auto"
                @change="formUser.subrol = null"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="formUser.subrol"
                label="Cargo"
                :items="subrolsByRol"
                item-value="subrol"
                item-text="name"
                :rules="[$rules.requiredOrZero]"
                :disabled="formUser.rol === 4 && formUser.subrol === 0"
                hide-details="auto"
                @change="formUser.pertenencia = null"
              />
            </v-col>

            <v-col
              v-if="formUser.rol === 3 || ((formUser.rol !== 0 && formUser.rol !== 4) && ((formUser.rol === 1 || formUser.rol === 2) & formUser.subrol !== 1))"
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="formUser.pertenencia"
                label="Pertenencia"
                :items="placeByRol"
                item-value="id"
                item-text="name"
                :rules="[$rules.requiredOrZero]"
                hide-details="auto"
              />
              {{ formUser.pertenencia }}
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <div class="button-container">
                <v-btn
                  class="d-block"
                  dark
                  :color="formUser.activo ? 'green darken-3' : 'orange'"
                  @click="formUser.activo = !formUser.activo"
                >
                  <v-icon left>
                    mdi-account{{ formUser.activo ? '' : '-off' }}
                  </v-icon>
                  {{ formUser.activo ? 'Activo' : 'Inactivo' }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <ActionsButtonsForms
        :loading="loading"
        class="pb-4"
        @cancel="dialog = false"
        @accept="save"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import ActionsButtonsForms from '../tools/ActionsButtonsForms.vue'
export default {
  name: 'DialogUserConfigurations',
  components: { ActionsButtonsForms },
  props: ['value', 'item', 'places', 'catalogueUsers'],
  data () {
    return {
      loading: false,
      hidePassword: true,
      selectRol: [
        { id: 0, name: 'Promtec', disabled: true },
        { id: 1, name: 'Aafy' },
        { id: 2, name: 'Cultur' },
        { id: 3, name: 'Parador' },
        { id: 4, name: 'Mayorista', disabled: true }
      ]
    }
  },
  computed: {
    dialog: {
      get () {
        this.resetForm()
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.resetForm()
      }
    },
    formUser () {
      return this.item
    },
    isEdit () {
      return !!this.formUser.id
    },
    subrolsByRol () {
      const filtredRoles = this.catalogueUsers.filter(user => user.rol === this.formUser.rol)
        .map(user => {
          if (user.rol === 4 && user.subrol === 0) user.disabled = true
          return user
        })
      return filtredRoles
    },
    placeByRol () {
      if (this.formUser.rol === 3) return this.places
      const filtredPlaces = this.places.filter(place => place.entidad === this.formUser.rol)
      return filtredPlaces
    }
  },
  methods: {
    save: function () {
      if (!this.$refs?.userConfigurationsForm?.validate()) return this.$Toast('warning', 'Faltan campos por llenar')
      this.loading = true
      const data = { data: this.formUser }

      this.$root.post('/CC/saveUser', data)
        .then(response => {
          const data = response.data
          const dataStore = data?.[0]

          if (data?.success === 'FALSE' || dataStore?.success === 'FALSE') return this.$swalAlert('error', null, data.message || dataStore?.message || 'Error inesperado!')

          this.$swalAlert('success', null, data.message || dataStore?.message)
          this.$emit('refresh')
          this.dialog = false
        })
        .finally(() => { this.loading = false })
    },
    resetForm: function () {
      this.hidePassword = true
      this.$refs?.userConfigurationsForm?.resetValidation()
    }
  }
}
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
