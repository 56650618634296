<template>
  <div id="AvailableTickets">
    <v-tabs
      v-model="ticketsActiveItem"
      height="45px"
      dark
      grow
      show-arrows
    >
      <v-tabs-slider color="orange" />
      <v-tab
        v-for="(item, index) in itemsForTicketsTabs"
        :id="`tab${index+1}`"
        :key="`tab-${index+1}`"
        mandatory
      >
        <v-icon class="ma-2">
          {{ item.icon }}
        </v-icon>
        {{ item.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="ticketsActiveItem"
      touchless
    >
      <v-tab-item
        v-for="(item, index) in itemsForTicketsTabs"
        :key="`tab-${index+1}`"
      >
        <v-card
          tile
          flat
        >
          <v-toolbar
            height="45px"
            dark
            elevation="0"
            color="#1e1e1e"
          >
            <h5 v-if="!item.type && canPrint && availableTickets.length">
              Boletos a utilizar: {{ totalForGenerate }}
            </h5>
            <h5 v-if="!item.type && !canPrint && availableTickets.length">
              Boletos que se pueden utilizar
            </h5>
            <h5 v-if="!item.type && !availableTickets.length">
              Aquí aparecerán los boletos autorizados
            </h5>
            <h5 v-if="item.type && !generatedTickets.length">
              Aquí aparecerán las transacciones de impresión
            </h5>

            <v-spacer />
            <v-btn
              v-if="!item.type && canPrint && totalForGenerate"
              color="success"
              @click="confirmPrintTickets"
            >
              <v-icon left>
                mdi-ticket-confirmation-outline
              </v-icon>
              Generar boletos
            </v-btn>
            <v-text-field
              v-if="item.type && generatedTickets.length"
              v-model="search"
              label=""
              outlined
              dark
              clearable
              dense
              placeholder="Escribe lo que desea buscar"
              prepend-icon="mdi-magnify"
              class="search-text-field"
              hide-details
            />
          </v-toolbar>
          <v-card-text :class="`container-items-cart ${item.type ? 'have-toolbar' : ''}`">
            <GenerateTickets
              v-if="!item.type"
              ref="generateTicketsRef"
              v-model="totalForGenerate"
              :availableTickets="availableTickets"
            />
            <TicketsGenerated
              v-else
              ref="ticketsGeneratedRef"
              :detTicketsGenerated="generatedTickets"
              :search="search"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

// Mixins
import WHOLESALERS from '../../minixs/wholesalers'

// Components
import GenerateTickets from '../../components/wholesalers/GenerateTickets.vue'
import TicketsGenerated from '../../components/wholesalers/TicketsGenerated.vue'

export default {
  name: 'AvailableTickets',
  components: {
    GenerateTickets,
    TicketsGenerated
  },
  mixins: [WHOLESALERS],
  data () {
    return {
      ticketsActiveItem: null,
      itemsForTicketsTabs: [
        { title: 'Generar boletos', icon: 'mdi-ticket-confirmation-outline', type: 0 },
        { title: 'boletos generados', icon: 'mdi-cloud-print-outline', type: 1 }
      ],
      availableTickets: [],
      generatedTickets: [],
      totalForGenerate: 0,
      search: ''
    }
  },
  computed: {
    ...mapGetters({
      token: 'auth/token'
    }),
    canPrint () {
      return this.userData?.subrol < 2
    }
  },
  watch: {
    ticketsActiveItem: function () {
      this.search = ''
    }
  },
  created () {
    this.launchSocketForThose()
  },
  methods: {
    ...mapMutations({
      setLoadingMainApp: 'globalStates/setLoadingMainApp',
      setTextLoadingMainApp: 'globalStates/setTextLoadingMainApp'
    }),
    launchSocketForThose: function () {
      const self = this
      try {
        self.$root.socket.removeListener('tickets')
        self.$root.socket.on('tickets', (res) => {
          if (self.userData?.idCorp === res.coorId) {
            self.totalForGenerate = 0
            self.availableTickets = res.data
          }
        })
        this.$root.socket.emit('reloadData', self.token)

        self.$root.socket.removeListener('responseTicketsGenerated')
        self.$root.socket.on('responseTicketsGenerated', (res) => {
          self.totalForGenerate = 0
          self.ticketsActiveItem = 1
          setTimeout(() => {
            self.$refs.ticketsGeneratedRef?.[0].clickrow(res)
          }, 100)
        })

        self.$root.socket.removeListener('TicketsGenerated')
        self.$root.socket.on('TicketsGenerated', (res) => {
          if (self.userData?.idCorp === res.coorId) {
            self.generatedTickets = res.data
          }
        })
        this.$root.socket.emit('GetTicketsGenerated', self.token)

        self.$root.socket.removeListener('StatusPrintTicket')
        self.$root.socket.on('StatusPrintTicket', (res) => {
          this.setLoadingMainApp(res.showDialog)
          this.setTextLoadingMainApp(res.text)
        })
      } catch (e) {}
    },
    confirmPrintTickets: function () {
      this.$refs.generateTicketsRef?.[0].confirmPrintTickets()
    }
  }
}
</script>

<style scoped>
.container-items-cart {
  max-height: calc(100vh - var(--h-navbar) - 45px - 45px);
  overflow: auto;
  color: #222 !important;
}

.search-text-field {
  max-width: 600px !important;
}
</style>
