<template>
  <v-card class="WholesalesSearchEngineTable">
    <v-card-title
      v-if="!loading && tickets.length"
      class="pt-2 pb-1"
    >
      <v-spacer />
      <v-btn
        height="40"
        color="success"
        class="ma-2 ml-7"
        @click="exportTable"
      >
        Exportar
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader
        v-if="loading"
        type="table"
      />
      <v-alert
        v-if="!loading && !tickets.length"
        class="mb-0"
        icon="mdi-folder-alert-outline"
        prominent
        text
        type="warning"
        align="left"
      >
        {{ textNoData }}
      </v-alert>

      <v-data-table
        v-if="!loading && tickets.length"
        :headers="headers"
        :items="tickets"
        item-key="id"
        class="styled-table m-4 mt-2"
        :sort-desc="[true, true]"
        multi-sort
        mobile-breakpoint="Infinity"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="(ticketTransac, index2) in items"
              :key="'Wholesales-search-engine-item-' + ticketTransac.folioimp + '-br-' + ticketTransac.brcode"
              style="color: black"
              :style="{'background-color': $backgroundRowTable(index2)}"
            >
              <td align="center">
                <strong>{{
                  ticketTransac.folioimp ? ticketTransac.folio : ""
                }}</strong>
              </td>
              <td align="center">
                <strong>{{ ticketTransac.NJOURNALNO }}</strong>
              </td>
              <td align="center">
                <strong>{{ ticketTransac.brcode }}</strong>
              </td>
              <td align="center">
                <strong>{{ ticketTransac.corp }}</strong>
              </td>
              <td align="center">
                <strong>{{ ticketTransac.l_reference }}</strong>
              </td>
              <td align="center">
                <strong>{{
                  ticketTransac.fecha_autorizacion
                    ? $formatFecha(ticketTransac.fecha_autorizacion, "/")
                    : ""
                }}</strong>
              </td>
              <td align="center">
                <strong>{{ ticketTransac.folioimp }}</strong>
              </td>
              <td align="center">
                <strong>{{
                  ticketTransac.fecha_impresion
                    ? $formatFecha(ticketTransac.fecha_impresion, "/")
                    : ""
                }}</strong>
              </td>
              <td align="center">
                <strong>{{ ticketTransac.descripcion }}</strong>
              </td>
              <td align="center">
                <strong>{{ ticketTransac.alias }}</strong>
              </td>
              <td align="center">
                <strong>{{ ticketTransac.shortname }}</strong>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>
    <div
      v-show="false"
      v-if="!loading && tickets.length"
      :id="idPrint"
    >
      <table style="width:100%;">
        <tr align="right">
          <td
            :colspan="headers.length"
            style="font-size: 16px;"
          >
            <strong>{{ title }}: </strong> {{ isDateSearch ? $formatFecha(valueSearched) : valueSearched }}
          </td>
        </tr>
        <tr align="right">
          <td
            :colspan="headers.length"
            style="font-size: 16px;"
          >
            <strong>Al día: </strong> {{ $formatFecha(nowDate, '/') }}
          </td>
        </tr>
        <tr />
      </table>

      <table
        border="1"
        bordercolor="#272727"
        class="styled-table"
        style="border-collapse: collapse; width:100%;"
      >
        <thead>
          <tr>
            <td
              v-for="header in headers"
              :key="'item-header-print'+header.value"
              align="center"
            >
              <strong>{{ header.text }}</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ticketTransac in tickets"
            :key="'Wholesales-search-engine-print-item-' + ticketTransac.folioimp + '-br-' + ticketTransac.brcode"
          >
            <td align="center">
              <strong>{{
                ticketTransac.folioimp ? ticketTransac.folio : ""
              }}</strong>
            </td>
            <td align="center">
              <strong>{{ ticketTransac.NJOURNALNO }}</strong>
            </td>
            <td align="center">
              <strong>{{ ticketTransac.brcode }}&nbsp;</strong>
            </td>
            <td align="center">
              <strong>{{ ticketTransac.corp }}</strong>
            </td>
            <td align="center">
              <strong>{{ ticketTransac.l_reference }}&nbsp;</strong>
            </td>
            <td align="center">
              <strong>{{
                ticketTransac.fecha_autorizacion
                  ? $formatFecha(ticketTransac.fecha_autorizacion, "/")
                  : ""
              }}</strong>
            </td>
            <td align="center">
              <strong>{{ ticketTransac.folioimp }}</strong>
            </td>
            <td align="center">
              <strong>{{
                ticketTransac.fecha_impresion
                  ? $formatFecha(ticketTransac.fecha_impresion, "/")
                  : ""
              }}</strong>
            </td>
            <td align="center">
              <strong>{{ ticketTransac.descripcion }}</strong>
            </td>
            <td align="center">
              <strong>{{ ticketTransac.alias }}</strong>
            </td>
            <td align="center">
              <strong>{{ ticketTransac.shortname }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'WholesalesSearchEngineTable',
  props: ['loading', 'generated', 'tickets', 'titleReport', 'valueSearched', 'isDateSearch', 'nowDate'],
  data () {
    return {
      headers: [
        { text: 'Folio', value: 'folio', align: 'center' },
        { text: 'Journal', value: 'NJOURNALNO', align: 'center' },
        { text: 'Barcode', value: 'brcode', align: 'center' },
        { text: 'Empresa', value: 'corp', align: 'center' },
        { text: 'Línea de Referencia', value: 'l_reference', align: 'center' },
        { text: 'Fecha de Autorización', value: 'fecha_autorizacion', align: 'center' },
        { text: 'Folio de Impresión', value: 'folioimp', align: 'center' },
        {
          text: 'Fecha de Impresión',
          value: 'fecha_creacion',
          align: 'center'
        },
        { text: 'Nombre del Boleto', value: 'descripcion', align: 'center' },
        { text: 'Alias del Boleto', value: 'alias', align: 'center' },
        { text: 'Parador Turistico', value: 'shortname', align: 'center' }
      ]
    }
  },
  computed: {
    textNoData: function () {
      let textData = ''
      if (!this.generated) textData = 'Primero realize su busqueda!'
      else textData = 'No hay datos para su busqueda!'
      return textData
    },
    idPrint: function () {
      return 'Wholesales-search-engine-by-' + this.titleReport
    },
    title: function () {
      return 'Búsqueda por ' + this.titleReport ?? ''
    },
    fileName: function () {
      let title = this.title.replaceAll(' ', '_')
      title += '_' + this.valueSearched + '_a_' + this.$formatFecha(this.nowDate)

      return title
    }
  },
  methods: {
    exportTable: function (event) {
      this.$exportExcelFromJs(event, this.fileName, this.idPrint)
    }
  }
}
</script>

<style scoped>
.search-text-field {
  max-width: 300px !important;
}

</style>

<style scoped src="../../../assets/css/style-tables-dark.css">
</style>
