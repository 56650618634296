<template>
  <td :colspan="colspan ? colspan : 20" class="TicketsDetailsTransactions pb-2">
    <v-simple-table v-for="(placeData, indexDetails) in placeDetails"
      :key="'transaction-details-place-' + transactionId + '-' + placeData.sitio + indexDetails">
      <thead>
        <tr>
          <td class="place-title">
            <table style="width: 100%;">
              <tr>
                <td style="width: 120px;">
                  Evento:
                </td>
                <td style="padding-left: 10px;">
                  <strong>{{ placeData.shortname }} - {{ placeData.alias }}</strong>
                </td>
                <td colspan="2" style="width: 70%;" align="right">

                </td>
              </tr>
              <tr>
                <td>
                  Fecha del evento:
                </td>
                <td style="padding-left: 10px;">
                  <strong>{{ placeData.fecha }}</strong> {{ placeData.show }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr style="background-color: rgba(255, 165, 0, 0.4);">
          <td>
            <v-simple-table>
              <thead @click="hideDetails(placeData)">
                <tr style="background: rgb(253 142 48 / 22%); color: black">
                  <td align="center">
                    #
                  </td>
                  <td align="center" style="width: 90px;">
                    <strong>ID boleto</strong>
                  </td>
                  <td style="width: 250px;">
                    <strong>Nombre del Evento</strong>
                  </td>
                  <td style="width: 100px;">
                    <strong>Suit</strong>
                  </td>
                  <td style="width: 160px;">
                    <strong>Fecha del evento</strong>
                  </td>
                  <!-- <td align="left">
                    <strong>Correo del Invitado</strong>
                  </td> -->
                  <td align="left">
                    <strong>Nombre</strong>
                  </td>
                  <td align="left">
                    <strong>Destinatario</strong>
                  </td>
                  <td align="center">
                    <strong>Fecha del Envio</strong>
                  </td>
                  <td align="center">
                    <strong>Reenviar</strong>
                  </td>
                </tr>
              </thead>
      <tbody :id="'myDetails_' + indexDetails" v-if="placeData.show">
        <tr v-for="(ticketData, count ) in placeData.transaccion"
          :key="`transaction-details-place-${transactionId}-${placeData.sitio}-${ticketData.id_ticket}-${ticketData.persontype}-${ticketData.npool}-${indexDetails}-${count}`">
          <!-- <td align="center">
            <input type="checkbox" :id="ticketData.id_carrito" :value="ticketData.id_carrito" class="largerCheckbox"
              @change="toggleSelection(ticketData.id_carrito)" :disabled="ticketData.correo != null" :checked="ticketData.correo != null || isSelected(ticketData.id_carrito)">
          </td> -->
          <td align="center">
            {{ count + 1 }}
          </td>
          <td align="center">
            {{ ticketData.id_carrito }}
          </td>
          <td align="left">
            {{ ticketData.nombre }}
          </td>
          <td align="left">
            {{ ticketData.alias }}
          </td>
          <td align="left">
            <span>{{ placeData.fecha }}</span>
          </td>
          <td align="left">
            {{ ticketData.invitado }}
          </td>
          <td align="left">
            <b>{{ ticketData.correo }}</b>
          </td>
          <!-- <td align="center">
            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="ticketData.correo != null" color="success" v-bind="attrs"
                  @click="invitateEvent(ticketData)" v-on="on">
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </template>
              <span>Enviar Boletos</span>
            </v-tooltip>
          </td> -->
          <td align="center">
            {{ ticketData.fecha_envio }}
          </td>
          <td align="center">
            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!ticketData.correo" color="orange" :dark="ticketData.correo != null" v-bind="attrs"
                  @click="invitateEvent()" v-on="on">
                  <v-icon>mdi-email-fast-outline</v-icon>
                </v-btn>
              </template>
              <span>Volver a enviar</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="placeData.total">
        <tr style="background: rgb(253 142 48 / 22%); color: black">
          <td :colspan="detailed ? 6 : 4" align="right">
            <strong>Total: </strong>
          </td>
          <td align="right" class="place-total pt-0">
            <strong>${{ $formatPrice(placeData.total) }}
              <ExchangeCurrency />
            </strong>
          </td>
        </tr>
      </tfoot>
    </v-simple-table>
  </td>
  </tr>
  </tbody>
  </v-simple-table>
  </td>
</template>

<script>
import MYCART from '@/minixs/my-cart'

export default {
  mixins: [MYCART],
  name: 'TicketsDetailsTransactions',
  props: ['colspan', 'placeDetails', 'transactionId', 'detailed'],
  data() {
    return {
      dialog: false,
      nombre: '',
      paterno: '',
      materno: '',
      correo: '',
      telefono: '',
      invitate: null,
      selected: [],
    }
  },
  methods: {
    isSelected(item) {
      return this.selected.includes(item);
    },
    toggleSelection(item) {
      if (this.isSelected(item)) {
        // Elimina el elemento si ya está seleccionado
        this.selected = this.selected.filter((i) => i !== item);
      } else {
        // Añade el elemento si no está seleccionado
        this.selected.push(item);
      }
    },
    hideDetails: function (placeData) {
      placeData.show = !placeData.show
    },
    invitateEvent: async function (invitate) {
      this.nombre = ''
      this.paterno = ''
      this.materno = ''
      this.correo = ''
      this.telefono = ''
      this.invitate = invitate

      this.dialog = true
    },
    invitateEventSucess: async function () {
      if (!this.nombre || !this.correo) {
        this.$swalAlert('warning', 'advertencia', 'completa los datos del invitado')
        return false
      }
      const _invitate = { nombre: this.nombre, paterno: this.paterno, materno: this.materno, correo: this.correo }
      const data = { "invitate": this.invitate, "_invitate": _invitate }

      //this.setInvitate(data)
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.place-title {
  background: white;
  padding: 15px 0px 0px 5px !important;
  font-weight: bold;
}

input.largerCheckbox {
  width: 20px;
  height: 20px;
}

input.largerCheckbox:hover {
  cursor: pointer;
}
</style>
