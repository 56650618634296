<template>
  <v-card
    id="SalesByPlacesChart"
    class="pa-2 pb-4"
  >
    <v-skeleton-loader
      v-if="loading"
      type="image"
      style="padding:20px;"
    />
    <DoughnutChart
      v-if="!loading && donut"
      :chartData="chartData"
      :options="options"
    />
    <LineChart
      v-if="!loading && line"
      :chartData="chartData"
      :options="options"
    />
  </v-card>
</template>

<script>
// Components
import { DoughnutChart, LineChart } from 'vue-chart-3'

export default {
  name: 'SalesByPlacesChart',
  components: { DoughnutChart, LineChart },
  props: ['loading', 'chartData', 'options', 'donut', 'line']
}
</script>
