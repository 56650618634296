<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card class="WholesalersTransactionsTable">
    <v-card-title
      v-if="!loading && transactions.length"
      class="pt-2 pb-1"
    >
      <h5>{{ title }}</h5>
      <v-spacer />
      <v-btn
        height="40"
        color="success"
        class="ma-2 ml-7"
        @click="exportTable"
      >
        Exportar
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader
        v-if="loading"
        type="table"
      />
      <v-alert
        v-if="!loading && !transactions.length"
        class="mb-0"
        icon="mdi-folder-alert-outline"
        prominent
        text
        type="warning"
        align="left"
      >
        {{ textNoData }}
      </v-alert>

      <div v-if="!loading && transactions.length">
        <v-data-table
          :headers="headers"
          :items="transactions"
          :sort-by="['fecha']"
          :sort-desc="[true]"
          :search="search"
          multi-sort
          mobile-breakpoint="Infinity"
        >
          <template v-slot:no-data />
          <template v-slot:body="{ items }">
            <tbody v-if="!items.length">
              <td colspan="20">
                <v-alert
                  class="mb-0 text"
                  align="center"
                >
                  Sin datos para esta búsqueda
                </v-alert>
              </td>
            </tbody>
            <tbody v-else>
              <template v-for="(item, index) in items">
                <tr
                  :key="`item-pending-transaction-${item.id}`"
                  class="pointman"
                  style="color: black"
                  :style="{ 'background-color': $backgroundRowTable(index) }"
                  @click="clickrow(item)"
                >
                  <td style="padding: 5px; max-width: 14px !important;">
                    <v-icon class="ml-2">
                      mdi-{{ item.id === expanded ? 'minus' : 'plus' }}
                    </v-icon>
                  </td>
                  <td>
                    <strong>{{ item.RefOri }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ item.Date }}</strong>
                  </td>
                  <td v-if="!selectedCorp">
                    {{ item.CorpName }}
                  </td>
                  <td align="center">
                    {{ item.fecha }}
                  </td>
                  <td>
                    <strong>${{ $formatPrice(item.total) }}<ExchangeCurrency /></strong>
                  </td>
                  <td align="center">
                    <strong>{{ $formatNum(item.cBoletos) }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ $formatNum(item.iBoletos) }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ $formatNum(item.dBoletos) }}</strong>
                  </td>
                  <td>
                    {{ isLockTrasactions ? item.f_bloqueo : item.f_autorizacion }}
                  </td>
                  <td>
                    {{ isLockTrasactions ? item.bloqueo : item.autorizo }}
                  </td>
                  <td align="center">
                    <v-btn
                      small
                      color="blue lighten-1"
                      class="ma-2"
                      :disabled="item.iBoletos < 1"
                      @click="usedHistory(item)"
                    >
                      <v-icon color="white">
                        mdi-printer-eye
                      </v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      small
                      dark
                      :color="isLockTrasactions ? 'red': 'orange'"
                      class="ma-2"
                      @click="isLockTrasactions ? unlockTransaction(item) : lockTransaction(item)"
                    >
                      <v-icon>mdi-{{ isLockTrasactions ? 'lock-open' : 'cancel' }}</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="isLockTrasactions">
                    <v-btn
                      small
                      dark
                      color="orange"
                      class="ma-2"
                      @click="lockInformation(item)"
                    >
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr
                  v-if="item.id === expanded"
                  :key="`pending-transaction-details-${item.id}`"
                  style="background-color: white !important"
                >
                  <TicketsDetailsTransactions
                    :colspan="headers.length"
                    :placeDetails="item.placeData"
                    :transactionId="item.id"
                    :detailed="true"
                  />
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </div>

      <div
        v-if="!loading && transactions.length"
        v-show="false"
        :id="`WholesalersTransactionsTable-${typeReport}-tickets-wrap`"
      >
        <table style="width:100%;">
          <tr align="right">
            <td
              :colspan="!selectedCorp ? 10 : 9"
              style="font-size: 16px;"
              v-html="getTitleReport"
            />
          </tr>
          <tr align="right">
            <td
              :colspan="!selectedCorp ? 10 : 9"
              style="font-size: 16px;"
            >
              <strong>Al día: </strong> {{ $formatFecha(nowDate, '/') }}
            </td>
          </tr>
          <tr />
        </table>

        <div
          v-for="item in transactions"
          :key="`Wholesalers-transactions-${typeReport}-${item.id}`"
        >
          <table
            border="1"
            bordercolor="#272727"
            class="styled-table"
            style="border-collapse: collapse; width:100%;"
          >
            <thead>
              <tr>
                <td align="center">
                  <strong>Línea de referencia</strong>
                </td>
                <td align="center">
                  <strong>Fecha de vencimiento</strong>
                </td>
                <td
                  v-if="!selectedCorp"
                  align="center"
                >
                  <strong>Nombre de la Empresa</strong>
                </td>
                <td align="center">
                  <strong>Fecha orden de pago</strong>
                </td>
                <td align="center">
                  <strong>Costo total</strong>
                </td>
                <td align="center">
                  <strong>Total de boletos</strong>
                </td>
                <td align="center">
                  <strong>Boletos impreso(s)</strong>
                </td>
                <td align="center">
                  <strong>Boletos disponibles(s)</strong>
                </td>
                <td align="center">
                  <strong>{{ isLockTrasactions ? 'Fecha de bloqueo': 'Fecha de autorizacion' }}</strong>
                </td>
                <td align="center">
                  <strong>{{ isLockTrasactions ? 'Bloqueó': 'Autorizó' }}</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{{ item.RefOri }}  &nbsp;</strong>
                </td>
                <td align="center">
                  <strong>{{ item.Date }}</strong>
                </td>
                <td v-if="!selectedCorp">
                  {{ item.CorpName }}
                </td>
                <td align="center">
                  {{ item.fecha }}
                </td>
                <td>
                  <strong>${{ $formatPrice(item.total) }}</strong>
                </td>
                <td align="center">
                  <strong>{{ $formatNum(item.cBoletos) }}</strong>
                </td>
                <td align="center">
                  <strong>{{ $formatNum(item.iBoletos) }}</strong>
                </td>
                <td align="center">
                  <strong>{{ $formatNum(item.dBoletos) }}</strong>
                </td>
                <td align="center">
                  <strong>{{ isLockTrasactions ? item.f_bloqueo : item.f_autorizacion }}</strong>
                </td>
                <td align="center">
                  <strong>{{ isLockTrasactions ? item.bloqueo : item.autorizo }}</strong>
                </td>
              </tr>
              <tr>
                <TicketsDetailsTransactionsToExport
                  :colspan="headers.length - 3"
                  :placeDetails="getPlaceDetails(item)"
                  :transactionId="item.id"
                  :detailed="true"
                />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// Mixins
import DASHBOARDWHOLESALERS from '../../../minixs/dashboard-wholesalers'

// Components
import TicketsDetailsTransactions from '../../tools/TicketsDetailsTransactions.vue'
import TicketsDetailsTransactionsToExport from '../../tools/TicketsDetailsTransactionsToExport.vue'

export default {
  name: 'WholesalersTransactionsTable',
  components: { TicketsDetailsTransactions, TicketsDetailsTransactionsToExport },
  mixins: [DASHBOARDWHOLESALERS],
  props: [
    'searchType',
    'selectedCorp',
    'loading',
    'isLockTrasactions',
    'transactions',
    'search',
    'searchedReferenceLine',
    'selectedDate',
    'nowDate'
  ],
  data () {
    return {
      headersLock: [
        { text: '', value: '', sortable: false },
        { text: 'Línea de referencia', value: 'RefOri', width: '280px' },
        { text: 'Fecha de vencimiento', value: 'Date', align: 'center', width: '150px' },
        { text: 'Fecha orden de pago', value: 'fecha', align: 'center', width: '150px' },
        { text: 'Costo total', value: 'total' },
        { text: 'Total de boletos', value: 'cBoletos' },
        { text: 'Boletos impreso(s)', value: 'iBoletos', align: 'center' },
        { text: 'Boletos disponibles(s)', value: 'dBoletos', align: 'center' },
        { text: 'Fecha de bloqueo', value: 'f_bloqueo', align: 'center', width: '150px' },
        { text: 'Bloqueó', value: 'namebloqueo', align: 'center' },
        { text: 'Historial de impresiones', value: '', align: 'center', sortable: false },
        { text: 'Desbloquear', value: '', align: 'center', sortable: false },
        { text: 'Información', value: '', align: 'center', sortable: false }
      ],
      headersAutorized: [
        { text: '', value: '', sortable: false },
        { text: 'Línea de referencia', value: 'RefOri', width: '280px' },
        { text: 'Fecha de vencimiento', value: 'Date', align: 'center', width: '150px' },
        { text: 'Fecha orden de pago', value: 'fecha', align: 'center', width: '150px' },
        { text: 'Costo total', value: 'total' },
        { text: 'Total de boletos', value: 'cBoletos' },
        { text: 'Boletos impreso(s)', value: 'iBoletos', align: 'center' },
        { text: 'Boletos disponibles(s)', value: 'dBoletos', align: 'center' },
        { text: 'Fecha de autorizacion', value: 'f_autorizacion', align: 'center', width: '150px' },
        { text: 'Autorizó', value: 'nameautorizo', align: 'center' },
        { text: 'Historial de impresiones', value: '', align: 'center', sortable: false },
        { text: 'Bloquear', value: '', align: 'center', sortable: false }
      ],
      expanded: null,
      dialog: false
    }
  },
  computed: {
    headers: function () {
      let headersTemp = this.isLockTrasactions ? this.headersLock : this.headersAutorized
      headersTemp = JSON.parse(JSON.stringify(headersTemp))
      if (!this.selectedCorp) headersTemp.splice(3, 0, { text: 'Nombre de la Empresa', value: 'CorpName' })
      return headersTemp
    },
    typeReport: function () {
      return `${this.isLockTrasactions ? 'Bloqueados' : 'Autorizados'}`
    },
    title: function () {
      if (this.searchType === null) return
      const titles = {
        corp: () => this.selectedCorp.name,
        referenceLine: () => {
          let title = '*Limite de busqueda 20 resultados'
          title += this.searchedReferenceLine ? ` para: ${this.searchedReferenceLine}` : ''
          return title
        },
        date: () => `${this.typeReport} el día: ${this.selectedDate}`
      }
      return titles[this.searchType]()
    },
    textNoData: function () {
      if (this.searchType === null) return
      const titles = {
        corp: () => `No hay ${this.typeReport.toLowerCase()} para: ${this.selectedCorp.name}`,
        referenceLine: () => {
          let title = `No hay resultados en ${this.typeReport.toLowerCase()}`
          title += this.searchedReferenceLine ? ` para: '${this.searchedReferenceLine}'` : ''
          return title
        },
        date: () => `No hay ${this.typeReport.toLowerCase()} el día: ${this.selectedDate}`
      }
      return titles[this.searchType]()
    },
    getTitleReport: function () {
      const titles = this.getNameReport.split(':')
      let title = `<b>${titles[0]}`
      title += titles[1] ? `:</b> ${titles[1]}` : '</b>'
      return title
    },
    getNameReport: function () {
      if (this.searchType === null) return
      const titles = {
        corp: () => `${this.typeReport} de: ${this.selectedCorp.name}`,
        referenceLine: () => {
          let title = `${this.typeReport} líneas de referencia`
          title += this.searchedReferenceLine ? ` para: '${this.searchedReferenceLine}'` : ''
          return title
        },
        date: () => `${this.typeReport} el día: ${this.selectedDate}`
      }
      return titles[this.searchType]()
    },
    fileName: function () {
      let name = this.getNameReport.replaceAll(' ', '_')
      name = name.replaceAll(':', '')
      name = name.replaceAll("'", '')
      name += '_al_dia_' + this.$formatFecha(this.nowDate)
      return name
    }
  },
  watch: {
    transactions: function () {
      this.expanded = null
      this.flag = false
      this.dialog = false
    }
  },
  methods: {
    getData: function () {
      this.$emit('getData')
    },
    clickrow: function (item) {
      if (this.flag) {
        this.flag = false
        return
      }

      this.expanded = this.expanded === item.id ? '' : item.id
      if (this.expanded) this.getPlaceDetails(item)
    },
    getPlaceDetails: function (item) {
      if (item.placeData) return item.placeData

      const placeData = typeof item.sitio === 'string' ? JSON.parse(item.sitio) : item.sitio
      const placeDetails = []
      placeData.forEach(element => {
        const {
          SitioName: shortname,
          sitio,
          ticket,
          subtotal2: total
        } = element
        const transaccion = ticket.map((item) => {
          const { NameTicket: nombre, alias, cantidad, usado, n_usado, costo, subtotal: total, id_ticket, fecha } = item

          return { nombre, alias, cantidad, usado, n_usado, costo, total, id_ticket, fecha }
        })
        const ticketData = {
          shortname,
          sitio,
          transaccion,
          total
        }
        placeDetails.push(ticketData)
      })
      item.placeData = placeDetails
      return placeDetails
    },
    exportTable: function (event) {
      this.$exportExcelFromJs(event, this.fileName, `WholesalersTransactionsTable-${this.typeReport}-tickets-wrap`)
    },
    usedHistory: function (item) {
      this.flag = true
      this.$emit('usedHistory', item)
    }
  }
}
</script>

<style>

</style>

<style scoped src="../../../assets/css/style-tables-dark.css">
</style>
