<template>
  <div id="AccountSettingWholesalers">
    <v-card
      class="mx-auto py-3 px-6 ma-xs-0"
      max-width="730px"
      elevation="5"
      min-width="75%"
    >
      <SecctionFormStyled
        v-if="isAnAdmin"
        subtitle="Imagen corporativa"
      >
        <v-img
          :src="userData.avatar"
          class="img-profile mx-auto"
          alt="Imagen de perfil"
          contain
        >
          <v-btn
            class="change-image-button"
            fab
            dark
            color="green"
            @click="changeProfileImage()"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-img>
      </SecctionFormStyled>

      <FormTwoFactorAuth
        :userData="userData"
        @success="refreshDataUser"
      />

      <FormChangePassword v-if="!isAnAdmin" />

      <UserDataSettings
        v-if="isAnAdmin"
        :formUser="formUser"
        @cancel="getUserData"
        @success="handleSuccessUserDataSetting"
      />

      <CorpDataSettings
        v-if="isAnAdmin"
        :formCorp="formCorp"
        @cancel="getCorpData"
        @success="handleSuccessCorpDataSetting"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

// Components
import UserDataSettings from '../../components/wholesalers/account-settings/UserDataSettings.vue'
import SecctionFormStyled from '../../components/tools/SecctionFormStyled.vue'
import FormTwoFactorAuth from '../../components/wholesalers/account-settings/FormTwoFactorAuth.vue'
import FormChangePassword from '../../components/wholesalers/account-settings/FormChangePassword.vue'
import CorpDataSettings from '../../components/wholesalers/account-settings/CorpDataSettings.vue'

export default {
  name: 'AccountSettingWholesalers',
  components: {
    SecctionFormStyled,
    FormTwoFactorAuth,
    FormChangePassword,
    UserDataSettings,
    CorpDataSettings
  },
  data () {
    return {
      formUser: {},
      formCorp: {}
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    }),
    isAnAdmin () {
      return this.userData?.subrol < 1
    }
  },
  mounted () {
    this.getUserData()
    this.getCorpData()
  },
  methods: {
    ...mapMutations({
      refreshUser: 'auth/refreshUser'
    }),
    changeProfileImage: async function () {
      const file = await this.$swalUploadProfileImage()
      if (!file) return

      let imgName = file.name.split('.')
      const wholesalerId = this.userData?.idCorp
      imgName = wholesalerId + '.' + imgName[1]
      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileName', imgName)

      this.$root.post('/wholesalers/uploadImageCorp', formData)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          this.refreshDataUser()
          this.$swalAlert('success', 'Imagen modificada', null)
        })
    },
    getUserData: function () {
      this.$root.post('/wholesalers/userData')
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error')
            return
          }

          this.formUser = data
        })
    },
    handleSuccessUserDataSetting: function () {
      this.getUserData()
      this.refreshDataUser()
    },
    getCorpData: function () {
      if (this.userData.subrol !== 0) return
      this.$root.post('/wholesalers/corpData')
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error')
            return
          }

          this.formCorp = data
        })
    },
    handleSuccessCorpDataSetting: function () {
      this.getCorpData()
      this.refreshDataUser()
    },
    refreshDataUser: function () {
      this.$root.post('/auth/refreshUserData')
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message)
            return
          }

          this.refreshUser(data)
        })
    }
  }
}
</script>

<style scoped>
#AccountSettingWholesalers {
  height: calc(100vh - var(--h-navbar));
  display: grid;
  place-items: center;
  overflow: auto;
  padding: 40px;
  background-color: var(--bg-gray);
}

.change-image-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.img-profile {
  max-width: 350px;
  max-height: 350px;
  min-width: 150px;
  min-height: 150px;
}

@media (max-width: 960px) {
  #AccountSettingWholesalers {
    padding: 20px 10px;
  }
}

@media (max-width: 599px) {
  .img-profile {
    max-width: 200px;
    max-height: 200px;
    min-width: 100px;
    min-height: 100px;
  }
}
</style>
