<template>
  <v-dialog
    id="DialogPosConfigurations"
    v-model="dialog"
    max-width="900px"
    persistent
  >
    <v-card tile>
      <v-toolbar
        dark
        style="border-radius: 0px !important"
      >
        <v-card-title>
          <span class="text-h5">Configuracion de caja</span>
        </v-card-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="posData.IdCajero"
              readonly
              disabled
              label="Id Boleto Axess"
              hide-details="auto"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="posData.name"
              readonly
              disabled
              label="Nombre Largo"
              hide-details="auto"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="posData.name2"
              readonly
              disabled
              label="Nombre Corto"
              hide-details="auto"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="posData.IdEntidad"
              :items="entities"
              item-value="id"
              item-text="name"
              label="Entidad"
              hide-details="auto"
              @change="posData.IdSitio = ''"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="posData.IdSitio"
              :items="placeForEntity"
              name="id"
              item-value="id"
              item-text="NAME"
              label="Sitio"
              hide-details="auto"
              :disabled="!posData.entidad"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="posData.Cuenta"
              :items="bankAccounts"
              name="id"
              item-text="Cuenta"
              label="Cuenta Bancarias"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <ActionsButtonsForms
        :loading="loading"
        class="pb-4"
        @cancel="dialog = false"
        @accept="save"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import ActionsButtonsForms from '../tools/ActionsButtonsForms.vue'
export default {
  name: 'DialogPosConfigurations',
  components: { ActionsButtonsForms },
  props: ['value', 'item', 'places', 'bankAccounts'],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('dashboard', ['entities']),
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    posData () {
      return this.item
    },
    placeForEntity () {
      return this.places.filter(place => place.entidad === this.posData.IdEntidad)
    }
  },
  methods: {
    save: function () {
      this.loading = true
      const { IdCajero: cashierID, IdCuenta: bankAccountID, IdSitio: placeID, IdSucursalr: registedID } = this.posData
      const data = { cashierID, bankAccountID, placeID, registedID }

      this.$root.post('/CC/savePosConfiguration', data)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message)
            return
          }

          this.$swalAlert('success', null, 'Se ha guardado correctamente')
          this.$emit('refresh')
          this.dialog = false
        }).finally(() => { this.loading = false })
    }
  }
}
</script>

<style>

</style>
