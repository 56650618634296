export default {
  namespaced: true,
  state: {
    placesAvailable: [],
    ticketsAvailable: [],
    totalForSale: 0,
    hadAddCart: 0
  },
  mutations: {
    setPlacesAvailable: function (state, val) {
      state.placesAvailable = val
    },
    setTicketsAvailable: function (state, val) {
      state.ticketsAvailable = val
    },
    setTotalForSale: function (state, val) {
      state.totalForSale = val
    },
    setHadAddCart: function (state, val) {
      state.hadAddCart = val
    }
  }
}
