export default {
  namespaced: true,
  state: {
    placesForUser: [],
    entities: [],
    showAxessTickets: false
  },
  mutations: {
    setPlacesForUser: function (state, val) {
      state.placesForUser = val
    },
    setEntities: function (state, val) {
      state.entities = val
    },
    setShowAxessTickets: function (state, val) {
      state.showAxessTickets = val || false
    }
  }
}
