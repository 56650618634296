<template>
  <small class="ExchangeCurrency"><sup>&nbsp;MXN</sup></small>
</template>

<script>
export default { name: 'ExchangeCurrency' }
</script>

<style scoped>
@media print {
  .ExchangeCurrency {
    display: none;
  }
}
</style>
