<template>
  <v-card id="AvailableTicketsTable">
    <v-card-title
      v-if="!loading && items.length"
      class="pb-0"
    >
      <v-spacer />
      <v-btn
        color="success"
        @click="exportTable"
      >
        Exportar
      </v-btn>
    </v-card-title>
    <v-card-text class="card-available-tickets">
      <v-skeleton-loader
        v-if="loading"
        type="table"
      />
      <v-alert
        v-if="!loading && !items.length"
        class="mb-0"
        icon="mdi-folder-alert-outline"
        prominent
        text
        type="warning"
        align="left"
      >
        No hay boletos disponibles!
      </v-alert>
      <div
        v-if="!loading && items.length"
      >
        <v-simple-table
          v-for="(sitio, index) in items"
          :key="'sitios-' + index"
          class="w-100"
        >
          <thead>
            <tr>
              <td class="place-title">
                {{ sitio.shortname }}
              </td>
            </tr>
          </thead>
          <tbody
            border="1"
            bordercolor="#272727"
            style="border-collapse: collapse; width:100%;"
          >
            <tr class="container-details">
              <td>
                <v-simple-table>
                  <thead class="header-details">
                    <tr>
                      <td align="center">
                        <strong>Tipo de boleto</strong>
                      </td>
                      <td class="MyTdPadding">
                        <strong>Nombre del Boleto</strong>
                      </td>
                      <td align="center">
                        <strong>Fecha Limite de uso</strong>
                      </td>
                      <td align="center">
                        <strong>Comprado(s)</strong>
                      </td>
                      <td align="center">
                        <strong>Impreso(s)</strong>
                      </td>
                      <td align="center">
                        <strong>Disponible(s)</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(sitioTicket, index12) in sitio.data"
                      :key="`sitioTickets-${sitioTicket.id_ticket}-${index12}-${sitio.sitio}`"
                    >
                      <td align="center">
                        <strong>{{ sitioTicket.id_ticket }}</strong>
                      </td>
                      <td width="30%">
                        <strong>{{ sitioTicket.descripcion }} </strong>
                      </td>
                      <td align="center">
                        <strong>{{ sitioTicket.fechaFin }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ $formatNum(sitioTicket.cantidad) }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ $formatNum(sitioTicket.usados) }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ $formatNum(sitioTicket.total) }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card-text>
    <div
      v-if="!loading && items.length"
      v-show="false"
      :id="idPrint"
    >
      <table>
        <tbody>
          <tr>
            <td
              align="right"
              colspan="6"
            >
              <strong>Boletos disponibles de: </strong> {{ selectedCorp.name }}
            </td>
          </tr>
          <tr>
            <td
              align="right"
              colspan="6"
            >
              <strong>Al día: </strong> {{ $formatFecha(nowDate) }}
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-for="(sitio, index1) in items"
        :key="`sitio-${sitio.id}-${index1}`"
      >
        <table style="width:100%;">
          <tr />
          <tr align="center">
            <td
              colspan="6"
              style="font-size: 18px;"
            >
              <strong>{{ sitio.shortname }}</strong>
            </td>
          </tr>
        </table>
        <table
          border="1"
          bordercolor="#272727"
          style="border-collapse: collapse; width:100%;"
        >
          <tbody>
            <tr>
              <td>
                <table
                  border="1"
                  bordercolor="#272727"
                  style="border-collapse: collapse; width:100%;"
                >
                  <thead>
                    <tr>
                      <td align="center">
                        <strong>Tipo de boleto</strong>
                      </td>
                      <td class="MyTdPadding">
                        <strong>Nombre del Boleto</strong>
                      </td>
                      <td align="center">
                        <strong>Fecha Limite de uso</strong>
                      </td>
                      <td align="center">
                        <strong>Comprado(s)</strong>
                      </td>
                      <td align="center">
                        <strong>Impreso(s)</strong>
                      </td>
                      <td align="center">
                        <strong>Disponible(s)</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(sitioTicket, index12) in sitio.data"
                      :key="`sitioTickets-${sitioTicket.id_ticket}-${index12}-${sitio.sitio}`"
                    >
                      <td align="center">
                        <strong>{{ sitioTicket.id_ticket }}</strong>
                      </td>
                      <td width="30%">
                        <strong>{{ sitioTicket.descripcion }} </strong>
                      </td>
                      <td align="center">
                        <strong>{{ sitioTicket.fechaFin }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ $formatNum(sitioTicket.cantidad) }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ $formatNum(sitioTicket.usados) }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ $formatNum(sitioTicket.total) }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AvailableTicketsTable',
  props: ['selectedCorp', 'items', 'loading', 'nowDate'],
  computed: {
    idPrint: function () {
      return `available-tickets-for-corp-${this.selectedCorp.idcorp}`
    }
  },
  methods: {
    exportTable: function () {
      let fileName = `${this.selectedCorp.name}-boletos_disponibles-a-${this.$formatFecha(this.nowDate, '-')}`
      fileName = fileName.replaceAll(' ', '_')
      this.$exportExcelFromJs(event, fileName, this.idPrint)
    }
  }
}
</script>

<style scoped>
.card-available-tickets {
  color: black !important;
}

.place-title {
  background: white;
  color: black;
  padding: 0 !important;
  padding-left: 5px !important;
  font-size: 16px !important;
  font-weight: bold;
}

.container-details, .container-details:hover {
  background-color: rgba(255, 165, 0, 0.4) !important;
}

.header-details {
  background: rgb(253 142 48 / 22%); color: black
}
</style>
