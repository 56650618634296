<template>
  <div id="WholesalersTransactions">
    <v-toolbar
      height="45px"
      dark
      elevation="0"
      color="#1e1e1e"
    >
      <v-btn
        v-if="selectedCorp"
        dark
        color="orange"
        @click="selectedCorp = null"
      >
        <v-icon>mdi-arrow-left</v-icon>
        Regresar
      </v-btn>
      <v-radio-group
        v-if="!selectedCorp"
        v-model="searchType"
        class="radio-search-type"
        row
        hide-details
        mandatory
      >
        <v-radio
          v-for="item in searchTypes"
          :key="`item-search-type-${item.title}`"
          :value="item.value"
        >
          <template v-slot:label>
            Buscar por <strong class="warning--text pl-1">{{ item.title }}</strong>

            <InputDatePickerReport
              v-if="searchType === 'date' && item.value === 'date'"
              v-model="selectedDate"
              class="ml-3"
              @change="getTransactionsDate"
            />
          </template>
        </v-radio>
      </v-radio-group>
      <div
        v-if="!selectedCorp"
        class="select-search-type"
      >
        <v-select
          v-model="searchType"
          :items="searchTypes"
          dense
          dark
          outlined
          hide-details
          item-value="value"
        >
          <template v-slot:item="{item}">
            Buscar por <strong class="warning--text pl-1">{{ item.title }}</strong>
          </template>
          <template v-slot:selection="{item}">
            Buscar por <strong class="warning--text pl-1">{{ item.title }}</strong>
          </template>
        </v-select>
        <InputDatePickerReport
          v-if="searchType === 'date'"
          v-model="selectedDate"
          class="ml-3"
          :left="true"
          @change="getTransactionsDate"
        />
      </div>

      <v-spacer />

      <v-text-field
        v-if="searchType === 'corp'"
        v-model="searchCorp"
        :placeholder="searchPlaceholder"
        v-bind="attrsSearching"
      />

      <v-text-field
        v-if="searchType === 'referenceLine'"
        v-model="searchReferenceLine"
        placeholder="Escribe la línea de referencia y presione enter"
        v-bind="attrsSearching"
        @keyup.enter="getTransactionsReferenceLine(false)"
        @click:clear="getTransactionsReferenceLine(true)"
      />
    </v-toolbar>
    <div class="content-with-fixed-toolbar">
      <CardsCorps
        v-if="searchType === 'corp' && !selectedCorp"
        :corpsList="corpsList"
        :search="searchCorp"
        :loading="loading"
        :isLockTrasactions="isLockTrasactions"
        @selectCorp="corp => selectedCorp = corp"
      />
      <WholesalersTransactionsTable
        v-if="(searchType === 'corp' && selectedCorp) || searchType !== 'corp'"
        :searchType="searchType"
        :selectedCorp="selectedCorp"
        :loading="loading"
        :isLockTrasactions="isLockTrasactions"
        :transactions="transactions"
        :search="searchType === 'corp' ? searchCorp : ''"
        :searchedReferenceLine="searchedReferenceLine"
        :selectedDate="selectedDate"
        :nowDate="nowDate"
        @getData="getData"
        @usedHistory="usedHistory"
      />
    </div>
    <ImpressionHistory
      v-model="dialogPrintHistory"
      :transactionPrint="transactionPrint"
      :transactionPrintData="transactionPrintData"
      :loading="loadingPrintHistory"
      :nowDate="nowDate"
    />
  </div>
</template>

<script>
// Components
import CardsCorps from './CardsCorps.vue'
import InputDatePickerReport from '../../tools/reports/InputDatePickerReport.vue'
import ImpressionHistory from './ImpressionHistory.vue'
import WholesalersTransactionsTable from './WholesalersTransactionsTable.vue'

export default {
  name: 'WholesalersTransactions',
  components: { CardsCorps, WholesalersTransactionsTable, InputDatePickerReport, ImpressionHistory },
  props: ['activeNow', 'nowDate', 'isLockTrasactions'],
  data () {
    return {
      attrsSearching: {
        label: '',
        outlined: true,
        dark: true,
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field',
        'hide-details': true
      },
      searchType: null,
      searchTypes: [
        { title: 'Empresa', value: 'corp' },
        { title: 'Línea de Referencia', value: 'referenceLine' },
        { title: 'Fecha', value: 'date' }
      ],
      searchPlaceholder: 'Escribe el nombre de una empresa',
      corpsList: [],
      selectedCorp: null,
      loading: false,
      searchCorp: '',
      searchReferenceLine: '',
      searchedReferenceLine: '',
      selectedDate: '',
      transactions: [],
      dialogPrintHistory: false,
      loadingPrintHistory: false,
      transactionPrint: {},
      transactionPrintData: []
    }
  },
  computed: {
    status: function () {
      return this.isLockTrasactions ? 3 : 2
    }
  },
  watch: {
    activeNow: function (val) {
      if (val) this.getData()
    },
    searchType: function () {
      this.getData()
    },
    selectedCorp: function (val) {
      if (val) this.getTransactionsFromACorporation()
      else {
        this.items = []
        this.searchPlaceholder = 'Escribe el nombre de una empresa'
      }
    }
  },
  mounted () {
    this.selectedDate = this.nowDate
  },
  methods: {
    getData: function () {
      const actions = {
        corp: this.getCorpsList,
        referenceLine: this.getTransactionsReferenceLine,
        date: this.getTransactionsDate
      }
      actions[this.searchType]()
    },
    getCorpsList: function () {
      this.searchCorp = ''
      this.loading = true
      this.selectedCorp = null
      this.corpsList = []

      const data = { status: this.status, opt: 0, type: 1 }
      this.$root.post('/wholesalers/getTransCartGob', { data })
        .then((response) => {
          const data = response?.data
          if (data.success === 'FALSE') return this.$swalAlert('error', null, data.message)

          this.corpsList = data || []
        }).finally(() => { this.loading = false })
    },
    getTransactionsFromACorporation: function () {
      this.searchPlaceholder = 'Escribe lo que quieras buscar'
      const data = { status: this.status, opt: this.selectedCorp.idcorp, type: 1 }
      this.getTransactions(data, 'corp')
    },
    getTransactionsReferenceLine: function (clearSearch) {
      this.searchReferenceLine = this.searchReferenceLine.trim()
      if (!this.searchReferenceLine) this.searchReferenceLine = ''
      if (clearSearch) this.searchReferenceLine = ''
      this.searchedReferenceLine = this.searchReferenceLine
      const data = { status: this.status, linea: this.searchReferenceLine, type: 2 }
      this.getTransactions(data, 'referenceLine')
    },
    getTransactionsDate: function () {
      const data = { status: this.status, fecha: this.selectedDate, type: 3 }
      this.getTransactions(data, 'date')
    },
    getTransactions: function (data, type) {
      this.searchCorp = ''
      this.loading = true
      this.transactions = []

      this.$root.post('/wholesalers/getTransCartGob', { data })
        .then((response) => {
          const data = response?.data
          if (data.success === 'FALSE') return this.$swalAlert('error', null, data.message)

          if (this.searchType !== type) return
          if (!this.loading) return
          this.transactions = data ?? []
        }).finally(() => { this.loading = false })
    },
    usedHistory: function (item) {
      this.dialogPrintHistory = true
      this.loadingPrintHistory = true
      this.transactionPrint = item
      this.transactionPrintData = []
      const data = { type: 1, id: item.id, corp: item.id_mayo, ticketType: {} }

      this.$root
        .post('/wholesalers/historyPrintedTransac', data)
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          data.forEach(item => {
            item.data = JSON.parse(item.data)
          })
          this.transactionPrintData = data
        }).finally(() => { this.loadingPrintHistory = false })
    }
  }
}
</script>

<style scoped>
.search-text-field {
  max-width: 600px !important;
}

.content-with-fixed-toolbar {
  height: calc(100vh - var(--h-navbar) - 45px - 45px);
  overflow-y: auto;
  padding: 20px;
}

.select-search-type {
  display: none;
}

.radio-search-type {
  display: block;
}

@media (max-width: 1199px) {
  .select-search-type {
    display: flex;
  }

  .radio-search-type {
    display: none;
  }
}
</style>
