<template>
  <div id="NavBarWholesalers">
    <v-app-bar
      fixed
      dense
      :height="getHeightCss"
      align="center"
      elevation="0"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        <div style="display: flex;">
          <div>
            <img src="http://199.89.54.175/files/light-logo-estadio-azteca.png" alt="logo" width="90" style="margin-top: 8px;">
          </div>
          <div style="margin-top: 18px; margin-left: 10px">
            {{ getNameRoute() }}
          </div>
        </div>
      </v-toolbar-title>
      <v-spacer />

      <div v-if="$route.path === '/home'">
        <span
          v-if="totalForSale"
          class="text-subtitle-1 item-for-desktop mr-3"
        >
          ${{ $formatPrice(totalForSale) }} <ExchangeCurrency />
        </span>
        <v-btn
          v-if="totalForSale"
          color="success"
          class="mr-3"
          small
          @click="addTicketsToCarrito"
        >
          <v-icon
            left
            class="item-for-mobile"
          >
            mdi-plus
          </v-icon>
          <span class="item-for-mobile">
            ${{ $formatPrice(totalForSale) }}  <ExchangeCurrency />
          </span>
          <span class="item-for-desktop">
            AGREGAR
          </span>
        </v-btn>
        <v-btn
          fab
          small
          @click="sendToRoute('Mi carrito', { id: 0 })"
        >
          <v-badge
            color="green"
            :value="hadAddCart"
            :content="hadAddCart"
          >
            <v-icon size="20">
              mdi-cart
            </v-icon>
          </v-badge>
        </v-btn>
      </div>

      <!-- <v-btn
        v-if="$route.name === 'Mi carrito'"
        class="mr-3 item-for-desktop"
        color="success"
        small
        @click="sendToRoute('Boletos disponibles')"
      >
        <v-icon left>
          mdi-ticket-confirmation
        </v-icon>
        BOLETOS DISPONIBLES
      </v-btn> -->

      <v-btn
        v-if="$route.path !== '/home'"
        fab
        small
        @click="sendToRoute('Mayoristas')"
      >
        <v-icon> mdi-home </v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      bottom
      temporary
      dark
      color="#363636"
      :mobile-breakpoint="600"
    >
      <v-list-item
        class="container-avatar px-2"
        style="background: white"
        @click="canEdit ? sendToRoute('Configuración de la cuenta') : null"
      >
        <div
          align="center"
          class="container-img-avatar"
        >
          <img
            :src="userData.avatar"
            class="img-avatar"
          >
        </div>
        <div
          v-if="canEdit && alertLogo(userData.avatar)"
          class="add-avatar-message"
        >
          Haz click para agregar tu foto
        </div>
      </v-list-item>

      <v-list-item
        link
        active-class="bg-active"
        :to="'/account-settings'"
      >
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ userData.corp }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ userData.cargo }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mt-0 pt-0 mb-2" />
      <v-list
        nav
        dense
      >
        <template
          v-for="(item) in items"
        >
          <v-list-item
            v-if="!item.sub.length"
            :key="'navBar-'+item.title"
            link
            active-class="bg-active"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-group
            v-else
            :key="'navBar-group-'+item.title"
            class="active-dropdown"
            no-action
            :prepend-icon="item.icon"
            dark
            color="white"
          >
            <template slot="activator">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(subitem, index) in item.sub"
              :key="`navBar-${item.title}-${index}`"
              dark
              link
              active-class="bg-active"
              :to="subitem.link"
              style="padding-left: 33px !important"
            >
              <v-list-item-icon>
                <v-icon>{{ subitem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ subitem.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            class="white--text"
            color="teal"
            @click="LogOut"
          >
            <v-icon class="mr-2">
              mdi-logout
            </v-icon>
            Cerrar sesión
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'NavBarWholesalers',
  data () {
    return {
      drawer: false,
      avatar: '//gpstuuxan.com//img/user/17.png?1687819920075',
      titleCarritoRoutes: ['Mi Carrito', 'Ordenes de Pago', 'Autorizados', 'Bloqueados', 'Vencidos'],
      items: [
        { title: 'Inicio', icon: 'mdi-home', link: '/home', sub: [] },

        {
          title: 'Ventas',
          icon: 'mdi-cart-arrow-down',
          sub: [
            {
              title: 'Mi Carrito',
              icon: 'mdi-cart',
              link: '/my-cart/0',
              sub: []
            },
            {
              title: 'Ordenes de Pago',
              icon: 'mdi-clipboard-text-clock-outline',
              link: '/my-cart/1',
              sub: []
            },
            {
              title: 'Autorizados',
              icon: 'mdi-clipboard-check-multiple-outline',
              link: '/my-cart/2',
              sub: []
            },
            {
              title: 'Bloqueados',
              icon: 'mdi-cancel',
              link: '/my-cart/3',
              sub: []
            },
            {
              title: 'Vencidos',
              icon: 'mdi-calendar-clock',
              link: '/my-cart/4',
              sub: []
            }
          ]
        },
/*         {
          title: 'Boletos disponibles',
          icon: 'mdi-ticket-confirmation',
          link: '/available-tickets',
          sub: []
        }, */
      ]
    }
  },
  computed: {
    ...mapState('wholesaler', ['totalForSale', 'placesAvailable', 'ticketsAvailable', 'hadAddCart']),
    ...mapGetters({
      token: 'auth/token',
      userData: 'auth/userData'
    }),
    canEdit () {
      return this.userData?.subrol < 1
    },
    getHeightCss () {
      const defaultHeight = '58px'
      try {
        const height = getComputedStyle(document.querySelector('html')).getPropertyValue('--h-navbar')
        return height || defaultHeight
      } catch (e) {
        return defaultHeight
      }
    }
  },
  mounted () {
    if (this.canEdit) {
      const itemUsers = { title: 'Usuarios', icon: 'mdi-account-multiple', link: '/users', sub: [] }
      //this.items.splice(1, 0, itemUsers)
    }
  },
  methods: {
    ...mapMutations({
      setPlacesAvailable: 'wholesaler/setPlacesAvailable',
      setTicketsAvailable: 'wholesaler/setTicketsAvailable',
      setTotalForSale: 'wholesaler/setTotalForSale',
      setHadAddCart: 'wholesaler/setHadAddCart',
      logoutUser: 'auth/logoutUser'
    }),
    alertLogo: function (val) {
      try {
        const temp = val.split('/')
        if (!temp.length) return true
        const temp1 = temp?.[5].split('?')
        if (!temp1.length) return true
        return temp1[0] === '0.png'
      } catch (error) {
        console.log(error)
      }
    },
    sendToRoute: function (name, params) {
      if (!name || this.$route.name === name) return
      this.$router.push({ name, params })
    },
    addTicketsToCarrito: function () {
      let ticketsForAdd = []
     
      this.ticketsAvailable.forEach(tic => {
        tic.ticketsForSelectedPlace.forEach(ticket => {

          const { cantidad, costo, id, sitio, npool, persontype, active, NTARIFBLATTNR, NTARIFBLATTGUELTNR, idTicketDate } = ticket
          if (active != 2) return

          ticketsForAdd.push({ cantidad:1, id_ticket: id, sitio:tic["sitio"], npool, persontype, NTARIFBLATTNR, NTARIFBLATTGUELTNR, idTicketDate })
        })
      })
      ticketsForAdd = JSON.parse(JSON.stringify(ticketsForAdd))

      if (!ticketsForAdd.length) return this.$swalAlert('error', null, 'No puede añadir a carrito sin seleccionar boletos')

      const data = { tickets: ticketsForAdd, token: this.token }
      this.$root.socket.emit('addTicketsToPrecarrito', data)
      this.resetTicketAvailable()
      this.setHadAddCart(this.hadAddCart + 1)
    },
    resetTicketAvailable: function () {
      const ticketsForReset = []
      this.ticketsAvailable.forEach((ticket) => {
        let ticketReset = { cantidad: 0, firtsPress: true }
        ticketReset = Object.assign(ticket, ticketReset)
        ticketsForReset.push(ticketReset)
      })
      this.setTicketsAvailable(ticketsForReset)

      const placesForReset = []
      this.placesAvailable.forEach((place) => {
        let placeReset = { cantidad: 0 }
        placeReset = Object.assign(place, placeReset)
        placesForReset.push(placeReset)
      })
      this.setPlacesAvailable(placesForReset)
      this.setTotalForSale(0)
    },
    getNameRoute: function () {
      if (this.$route.name === 'Mi carrito') {
        return this.titleCarritoRoutes[this.$route.params.id]
      }
      return this.$route.name
    },
    LogOut: async function () {
      const result = await this.$ConfirmBox('warning', 'Esta seguro?', 'Realmente quieres salir del sistema!')
      if (!result.isConfirmed) return

      this.$root.logout()
    }
  }
}
</script>

<style scoped>
.container-avatar {
  position: relative !important;
}

.container-img-avatar {
  width: 100%;
  height: 121px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.img-avatar {
  max-width: 240px !important;
  max-height: 121px !important;
}

.add-avatar-message {
  position: absolute;
  top: calc(100% - 35px);
  left: 0;
  width: 100%;
  color: white;
  background-color: #272727b4;
  text-align: center;
  padding: 2px;
  font-size: 13px;
}

.bg-active {
  color: #fff !important;
  caret-color: #fff !important;
  background-color: #e91e63 !important;
  border-color: #e91e63 !important;
}

.theme--dark.v-list-item--active:hover::before,
.theme--dark.v-list-item--active::before {
  opacity: 0 !important;
}

.item-for-desktop {
  display: initial;
}

.item-for-mobile {
  display: none;
}

@media screen and (max-width: 500px) {
  .item-for-desktop {
    display: none;
  }

  .item-for-mobile {
    display: block;
  }
}
</style>
