import Vue from 'vue'

const user = JSON.parse(localStorage.getItem('user'))

const initialState = user
  ? { loggedIn: true, user }
  : { loggedIn: false, user: null }

export default {
  namespaced: true,
  state: initialState,
  actions: {
    updateToken ({ commit }, token) {
      commit('updateToken', token)
    }
  },
  getters: {
    user: (state) => state.user,
    userData: (state) => {
      if (!state.user) return null
      const user = { ...state.user }
      delete user.token
      return user
    },
    token: (state) => state.user.token
  },
  mutations: {
    loginUser: function (state, user) {
      localStorage.setItem('user', JSON.stringify(user))
      state.loggedIn = true
      state.user = user
    },
    refreshUser: function (state, user) {
      localStorage.setItem('user', JSON.stringify(user))
      Vue.set(state, 'user', user)
    },
    logoutUser: function (state) {
      localStorage.removeItem('user')
      state.loggedIn = false
      state.user = null
    },
    updateToken (state, updateToken) {
      const user = JSON.parse(localStorage.getItem('user'))
      user.token = updateToken

      localStorage.setItem('user', JSON.stringify(user))
      Vue.set(state, 'user', user)
    }
  }
}
