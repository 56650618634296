<template>
  <v-container>
    <v-form ref="formValidateReferenceLine">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="transaction.date"
            v-mask="'####-##-##'"
            :label="'Fecha de vencimiento'"
            :rules="[$rules.required, $rules.isDate(transaction.date, 'YYYY-MM-DD')]"
            outlined
            hide-details="auto"
            @keydown="clear"
            @keypress.enter="validateReferenceLine()"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="transaction.total"
            :label="'Importe a pagar - solo numeros y punto decimal '"
            :rules="[$rules.required, $rules.isFloat, $rules.min(transaction.total, 0), $rules.max(transaction.total, MAX_VALUE)]"
            outlined
            hide-details="auto"
            @paste="onPaste"
            @keypress="$input.isNumber"
            @keydown="clear"
            @keypress.enter="validateReferenceLine()"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="transaction.RefOri"
            label="Referencia original"
            :rules="[$rules.required, $rules.isNumber, $rules.minLength(transaction.RefOri, 24)]"
            outlined
            hide-details="auto"
            @paste="onPaste"
            @keypress="$input.isNumber"
            @keydown="clear"
            @keypress.enter="validateReferenceLine()"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            class="mr-4"
            color="success"
            @click="validateReferenceLine()"
          >
            VERIFICAR REFERENCIA
          </v-btn>
          <v-btn
            color="primary"
            @click="clearForm()"
          >
            LIMPIAR
          </v-btn>
        </v-col>
        <v-col
          v-if="referenceLine"
          cols="12"
        >
          <table class="table-show-details">
            <tr>
              <td>Fecha Condensa</td>
              <td>{{ dateCode }}</td>
            </tr>
            <tr>
              <td>Importe Condensado</td>
              <td>{{ totalCode }}</td>
            </tr>
            <tr>
              <td>Referencia Original</td>
              <td>{{ referenceLine }}</td>
            </tr>
            <tr>
              <td>Con Prefijo</td>
              <td>
                <strong>{{ referenceLineWithPrefix }}</strong>
              </td>
            </tr>
            <tr :class="`validate-reference-line ${valid || 'no-valid'}`">
              <td>Valido</td>
              <td>{{ valid ? 'SI' : 'NO' }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import REFERENCELINECHECKER from '../minixs/reference-line-checker'

export default {
  mixins: [REFERENCELINECHECKER],
  data () {
    return {
      MAX_VALUE: 999999999999999,
      valid: false,
      dateCode: '',
      totalCode: '',
      referenceLine: '',
      referenceLineWithPrefix: '',
      transaction: {
        date: '',
        total: '',
        RefOri: ''
      }
    }
  },
  methods: {
    onPaste (evt) {
      if (!isNaN(evt.clipboardData.getData('text'))) return
      this.$Toast('warning', 'Solo se permiten numeros')
      evt.preventDefault()
    },
    validateReferenceLine: async function () {
      try {
        if (!this.$refs.formValidateReferenceLine.validate()) return
        this.clear()

        const transaction = this.transaction
        const constante = 2
        const residuo = 97

        const dateCode = await this.getDateCode(transaction.date, 'Formato fecha incorrecto')
        const totalCode = await this.getTotalCode(transaction.total, 'Formato importe incorrecto')
        const referenceCode = await this.getReferenceCode(transaction.RefOri, constante, residuo, 'Formato referencia incorrecto')

        const LineRef = referenceCode.folioRef + dateCode + totalCode + constante + referenceCode.Refclave

        this.valid = LineRef === transaction.RefOri

        this.dateCode = dateCode
        this.totalCode = totalCode
        this.referenceLine = transaction.RefOri
        this.referenceLineWithPrefix = LineRef
      } catch (error) {
        const message = error.message || error.Msg ||
          (error.response && error.response.data) ||
          error.toString()
        this.$Toast('warning', message)
      }
    },
    clear () {
      this.valid = false
      this.dateCode = ''
      this.totalCode = ''
      this.referenceLine = ''
      this.referenceLineWithPrefix = ''
    },
    clearForm () {
      this.$refs.formValidateReferenceLine.resetValidation()
      this.$refs.formValidateReferenceLine.reset()
      this.transaction = {
        date: '',
        total: '',
        RefOri: ''
      }
      this.clear()
    }
  }
}
</script>

<style scoped>
.table-show-details > tr > td:first-child {
  font-weight: bold;
  padding-right: 20px;
}

.validate-reference-line {
  font-weight: bold;
  color: green;
}

.validate-reference-line.no-valid {
  color: red;
}
</style>
