<template>
  <v-expand-transition id="ActionsButtonsForms">
    <div v-if="show">
      <v-divider class="my-4" />
      <div class="container-actions-buttons">
        <v-btn
          :loading="loading"
          :disabled="loading"
          @click="$emit('cancel')"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading"
          class="primary"
          color="#df8157 !important"
          @click="$emit('accept')"
        >
          {{ acceptText }}
        </v-btn>
      </div>
    </div>
  </v-expand-transition>
</template>

<script>
export default {
  name: 'ActionsButtonsForms',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: 'cancelar'
    },
    acceptText: {
      type: String,
      default: 'aceptar'
    }
  }
}
</script>

<style scoped>
.container-actions-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

@media (max-width: 599px) {
  .container-actions-buttons {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
    gap: 15px;
  }
}
</style>
