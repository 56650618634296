<template>
  <div id="bg-catalogo-container">
    <span class="texto-prueba">
      {{ activeNavPlace }}
    </span>
    <div
      v-for="(item, index1) in placesAvailable"
      :key="`section-${index1}`"
      :class="`section section-${index1}`"
    >
      <div
        v-for="(part, index2) in 8"
        :key="`bg-part-item-${item}-${index1}-${index2}`"
        :class="`bg-part bg${index2}`"
        :style="getSizeBackground(item.img)"
      />
    </div>
  </div>
</template>

<script>
// Global Variables
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

export default {
  name: 'BackgroundCatalogue',
  props: ['placesAvailable', 'activeNavPlace'],
  computed: {
    getChromeVersion () {
      const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
      return raw ? parseInt(raw[2], 10) : false
    }
  },
  watch: {
    activeNavPlace: function (newValue, oldValue) {
      this.changeBackground(newValue, oldValue)
    }
  },
  mounted () {
    this.changeBackground(this.activeNavPlace, null)
  },
  methods: {
    getSizeBackground: function (image) {
      return {
        backgroundImage: 'url(\'' + image + '\')',
        'background-size': isFirefox ? '112vw 100%' : (this.getChromeVersion <= 99 ? '100vw 100%' : '112vw 100%')
      }
    },
    changeBackground: function (newActivePlace, oldActivePlace) {
      if (newActivePlace === oldActivePlace) return
      const oldActiveSection = document.querySelector('.section-' + oldActivePlace)
      if (oldActiveSection) oldActiveSection.classList.remove('section-active')

      const activeSection = document.querySelector('.section-' + newActivePlace)
      if (activeSection) activeSection.classList.add('section-active')
    }
  }
}
</script>

<style scoped>
#bg-catalogo-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}

.section {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.bg-part {
  height: 100%;
  width: 12.5%;
  background-repeat: no-repeat;
  position: absolute;
  background-attachment: fixed;
  transition-property: transform;
}

.bg0 {
  margin-left: 0;
  background-position: 0% 50%;
  transition-duration: 1s;
}

.bg1 {
  margin-left: 12.5%;
  background-position: 12.5% 50%;
  transition-duration: 0.8s;
}

.bg2 {
  margin-left: 25%;
  background-position: 25% 50%;
  transition-duration: 0.6s;
}

.bg3 {
  margin-left: 37.5%;
  background-position: 37.5% 50%;
  transition-duration: 0.4s;
}

.bg4 {
  margin-left: 50%;
  background-position: 50% 50%;
  transition-duration: 0.6s;
}

.bg5 {
  margin-left: 62.5%;
  background-position: 62.5% 50%;
  transition-duration: 0.8s;
}

.bg6 {
  margin-left: 75%;
  background-position: 75% 50%;
  transition-duration: 1s;
}

.bg7 {
  margin-left: 87.5%;
  background-position: 87.5% 50%;
  transition-duration: 1.2s;
}

/* animation for change background */

.section:not(.section-active) {
  z-index: 0 !important;
}

.section:not(.section-active) > .bg-part {
  transform: translateY(-100%) !important;
}

.section-active {
  z-index: 1 !important;
}

.section-active > .bg-part {
  transform: translateY(0%) !important;
}

@media (max-width: 700px) and (orientation: landscape),
  (max-width: 530px) and (orientation: portrait) {
  .bg-part {
    margin-top: -30px;
  }
}
</style>
