<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="table"
      :loading="loading"
    />
    <v-data-table
      v-if="!loading"
      v-model="selected"
      hover
      :show-select="showSelect"
      :items-per-page="12"
      :footer-props="{ 'items-per-page-options': [24, 36, 48, -1] }"
      :headers="headers"
      :items="filteredDesserts"
      fixed-header
      height="calc(100vh - 220px)"
      :single-select="!showSelect"
      :item-key="idkey"
      class="tableFilter"
      mobile-breakpoint="Infinity"
      @click:row="handleClickCuotas"
    >
      <template
        v-for="header in headers"
        v-slot:[`header.${header.value}`]
      >
        <div
          v-if="false"
          :key="`filter-table-item-${header.text}`"
        >
          <div style="margin-top:10px; min-width: 100px;">
            {{ header.text }}
            <v-icon
              dark
              style="font-size: 14px !important;"
              class="mx-1"
              @click="orderBy(header.value)"
            >
              {{ order }}
              {{ order[header.value] }}
            </v-icon>
          </div>
          <div>
            <v-text-field
              v-model="filters[header.value]"
              dark
              class="px-0 py-0 mx-0 my-0 style-input"
              style="font-size: 12px !important; font-weight: 400 !important;"
              type="text"
              hide-details
              :autofocus="true"
              :items="columnValueList(header.value)"
              @click:append="clearList(header.value)"
            >
              <template
                v-if="filters[header.value]"
                v-slot:append
              >
                <v-icon
                  v-if="filters[header.value].length > 0"
                  style="font-size: 20px !important;"
                  @click="clearList(header.value)"
                >
                  mdi-close-circle
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </div>
        <v-menu
          :key="`filter-table-menu-${header.text}`"
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              small
              v-on="on"
            >
              <v-icon
                small
                :style="{ 'color': filters[header.value] ? colorSelect : '' }"
                style="margin-left: 0px !important;"
              >
                mdi-filter
              </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px">
            <v-text-field
              v-model="filters[header.value]"
              class="pa-4 text-caption"
              type="text"
              append-icon="mdi-broom"
              :label="'Enter the search ' + header.text"
              hide-details
              :autofocus="true"
              @click:append="filters[header.value] = ''"
            />
          </div>
        </v-menu>
        <span
          :key="`filter-table-span-${header.text}`"
          :style="{ 'color': filters[header.value] ? colorSelect : '' }"
        >
          {{ header.text }}
        </span>
      </template>

      <template
        v-if="!showSelect"
        v-slot:body="{ items }"
      >
        <tbody v-if="!items.length">
          <tr>
            <td
              align="center"
              :colspan="headers.length"
            >
              No hay datos disponibles
            </td>
          </tr>
        </tbody>
        <tbody v-if="items.length">
          <tr
            v-for="item in items"
            :key="`item-tickets-${item.id}-${item.ntickettype}-${item.npersontype}-${item.npool}`"
            @click="handleClickCuotas(item)"
          >
            <template v-for="item2 in headers">
              <td
                v-if="item2.value != 'mayo'"
                :key="`item-tickets-${item.id}-${item2.value}-${item.ntickettype}-${item.npersontype}-${item.npool}`"
                :style="'text-align:' + item2.align"
              >
                {{item[item2.value] }}
              </td>
              <td
                v-if="item2.value === 'mayo'"
                :key="`item-tickets-mayo-${item.id}-${item2.value}-${item.ntickettype}-${item.npersontype}-${item.npool}`"
              >
                <div
                  v-if="item[item2.value] > 0"
                  :style="'text-align:' + item2.align"
                >
                  <v-icon color="green">
                    mdi-check
                  </v-icon>
                </div>
                <div v-else />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'TicketSetup',
  props: ['headers', 'desserts', 'showSelect', 'idkey', 'loading'],
  data () {
    return {
      colorSelect: '#a2ffa2 !important',
      selected: [],
      filters: {},
      order: {}
    }
  },
  computed: {
    filteredDesserts () {
      const self = this
      return this.desserts.filter((d) => {
        return Object.keys(self.filters).every((f) => {
          if (d[f]) {
            return self.filters[f].length < 1 || d[f].toString().toLowerCase().includes(self.filters[f])
          }
          return false
        })
      })
    }
  },
  watch: {
    desserts: function () {
      this.selected = []
      this.clearFilters()
    }
  },
  mounted () {
    const order = {}
    for (const h in this.headers) {
      order[this.headers[h].value] = 'mdi-arrow-down'
    }
    this.order = order
  },
  methods: {
    clearFilters () {
      this.filters = {}
    },
    handleClickCuotas (value) {
      if (!this.showSelect) {
        this.$emit('clicRowTable', value)
      }
    },
    clearList (val) {
      this.filters[val] = ''
    },
    appendIconCallback (header) {
      this.filters[header] = null
    },
    columnValueList (val) {
      return this.desserts.map((d) => d[val])
    },
    orderBy (val) {
      if (!val) return
      this.order[val] = this.order[val] === 'mdi-arrow-up' ? 'mdi-arrow-down' : 'mdi-arrow-up'
    },
    getRowCheck () {
      this.$emit('getRowCheck', this.selected)
      this.selected = []
    }
  }
}
</script>

<style scoped src="../assets/css/style-tables-dark.css"></style>

<style scoped>
.style-input {
  height: 40px;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.v-data-table>>>tbody tr td {
  font-size: 12px !important;
  height: 40px !important;
}

.v-data-table>>>tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.v-data-table>>>tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.tableFilter>>>table tbody tr:hover {
  cursor: pointer;
  background-color: rgb(190, 190, 190) !important;
}

.v-data-table>>>.v-data-footer {
  width: 100%;
}</style>
