// Mixins
import { mapMutations } from 'vuex/dist/vuex.common.js'
import WHOLESALERS from './wholesalers'

export default {
  mixins: [WHOLESALERS],
  methods: {
    ...mapMutations({
      setLoadingMainApp: 'globalStates/setLoadingMainApp'
    }),
    getPrecartData: function () {
      this.items = []
      this.totalPrecart = 0
      this.loading = true

      this.$root.post('/wholesalers/getPreCarrito')
        .then((response) => {
          const data = response.data

          if (data.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }
          this.items = this.fixinfo(data?.tickets)
          this.totalPrecart = data?.total || 0
        }).finally(setTimeout(() => { this.loading = false }, 100))
    },
    getTotalForPrecart: function () {
      let total = 0
      for (let x = 0; x < this.items.length; x++) {
        const val1 = this.items[x]
        for (let y = 0; y < val1.place.length; y++) {
          const val2 = val1.place[y]
          total = total + (val2.costo * val2.cantidad)
        }
      }

      this.totalPrecart = total
    },
    deleteItemPrecart: function (ticket) {
      this.loading = true

      const data = {
        idTicketDate: ticket.idTicketDate,
        id_ticket: ticket.id_ticket,
        persontype: ticket.persontype,
        npool: ticket.npool,
        NTARIFBLATTNR: ticket.NTARIFBLATTNR,
        NTARIFBLATTGUELTNR: ticket.NTARIFBLATTGUELTNR,
        idCorp: this.userData.idCorp
      }

      this.$root.post('/wholesalers/removeItemPreCarrito', data)
        .then((response) => {
          const data = response.data
          if (data.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          this.$Toast('success', data.message)
          this.getPrecartData()
        })
        .finally(() => { this.loading = false })
    },
    generatePaymentOrder: function (comprador) {
      
      const tickets = []
      for (let x = 0; x < this.items.length; x++) {
        const item = this.items[x]
        for (let y = 0; y < item.place.length; y++) {
          const placeItem = item.place[y]
          tickets.push({ ...placeItem, sitio: item.sitio })
        }
      }
      console.log(tickets)
      
      const data = { id: null, estatus: 1, tickets, comprador }
      this.$root.post('/generatePaymentOrder', data)
        .then((response) => {
          const data = response.data

          if (data.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          const id_transac = data?.id
          this.$swalAlert('success', 'Orden de compra realizado con exito', null)
          this.cartItemActive = 1

          setTimeout(() => {
            if (id_transac) this.$refs?.TableCartTransaction1?.[0]?.clickrow(id_transac)
          }, 100)
        })
    },
    setInvitate: function (data) {
      this.loading = true
      this.setLoadingMainApp(true)
      
      this.$root.post('/wholesalers/setInvitate', data)
        .then((response) => {
          const data = response.data

          if (data.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }
          this.cartItemActive = 2
          this.getTransactionsData()
        }).finally(() => { 
          this.loading = false
          this.setLoadingMainApp(false)
        })
    },
    getTransactionsData: function () {
      this.items = []
      this.totalPrecart = 0
      this.loading = true
      
      const data = { cartItemActive: this.cartItemActive }
      this.$root.post('/wholesalers/getTransCart', data)
        .then((response) => {
          const data = response.data

          if (data.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          this.items = this.fixinfo(data?.[0], this.cartItemActive)


        }).finally(() => { this.loading = false })
    },
    fixinfo (info = [], cartItemActive) {
      for (let x = 0; x < info.length; x++) {
        info[x].place = JSON.parse(info[x].place)
        
        if(cartItemActive === 2){
          info[x].place.map((ele) =>{
            ele.transaccion.sort((a, b) => a.id_carrito - b.id_carrito)
          })
        }
      }
      return info
    }
  }
}
