<template>
  <SecctionFormStyled
    id="corp-data-container-for-scroll"
    subtitle="Datos de la empresa"
  >
    <v-form
      ref="formCorpData"
      class="user-noselect"
      lazy-validation
    >
      <CorpStatusSettings
        :formCorp="formCorpComputed"
        @sendDocument="sendDocument"
      />
      <FormCorpData
        :formCorp="formCorpComputed"
        :canEdit="canEditForm"
      />

      <ActionsButtonsForms
        :show="showButtons"
        acceptText="Guardar cambios"
        @cancel="cancelCorpSetting"
        @accept="saveCorpSetting"
      />
    </v-form>
  </SecctionFormStyled>
</template>

<script>
import SecctionFormStyled from '../../tools/SecctionFormStyled.vue'
import FormCorpData from '../FormCorpData.vue'
import ActionsButtonsForms from '../../tools/ActionsButtonsForms.vue'
import CorpStatusSettings from './CorpStatusSettings.vue'

export default {
  name: 'CorpDataSettings',
  components: { SecctionFormStyled, FormCorpData, ActionsButtonsForms, CorpStatusSettings },
  props: {
    formCorp: {
      type: Object,
      require: true,
      default () {
        return {
          companyRFC: '',
          companyName: '',
          companyAddress: '',
          companyPhone: '',
          companyState: 0,
          companySentDocument: 0
        }
      }
    }
  },
  data () {
    return {
      firtChange: true,
      showButtons: false
    }
  },
  computed: {
    formCorpComputed: function () {
      return this.formCorp
    },

    canEditForm: function () {
      if (this.formCorpComputed?.companyState === 3 && this.formCorpComputed?.companySentDocument === 0) return true
      return this.formCorpComputed?.companyState === 0 && this.formCorpComputed?.companySentDocument === 0
    }
  },
  watch: {
    formCorp: function () {
      this.firtChange = true
      this.showButtons = false
    },
    formCorpComputed: {
      handler: function () {
        if (this.firtChange) {
          this.firtChange = false
          return
        }
        if (!this.showButtons) this.showButtons = true
      },
      deep: true
    }
  },
  methods: {
    resetForm: function () {
      this.$refs?.formCorpData?.reset()
      this.$refs?.formCorpData?.resetValidation()
    },
    cancelCorpSetting () {
      this.$emit('cancel')
      this.resetForm()
    },
    saveCorpSetting () {
      if (!this.$refs.formCorpData.validate()) return this.$Toast('error', 'Verifique los campos')

      this.$root.post('/wholesalers/updateCorp', this.formCorp)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            this.$emit('cancel')
            return
          }

          this.$swalAlert('success', 'Datos modificados', null)
          this.$emit('success')
        })
    },
    sendDocument: async function () {
      if (!this.$refs.formCorpData.validate()) return this.$Toast('error', 'Verifique los campos')
      const sendToVerificationTitle = '¿Estás seguro?'
      const sendToVerificationText = 'Al iniciar el proceso de verificación, no podrás modificar los datos de tu empresa.'
      const sendToVerification = await this.$ConfirmBox('warning', sendToVerificationTitle, sendToVerificationText)
      if (!sendToVerification.isConfirmed) return

      const swalUploadPdfConfiguration = {
        text: 'Sube tu constancia de situación fiscal para que podamos verificar tu empresa',
        title: 'Enviar documentos',
        confirmButtonText: 'Enviar a verificación'
      }
      const file = await this.$swalUploadPdf(swalUploadPdfConfiguration)
      if (!file) return

      const formData = new FormData()
      formData.append('file', file)

      this.$root.post('/wholesalers/uploadDocument', formData)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          this.$swalAlert('success', 'Documento enviado', null)
          this.$emit('success')
        })
    }
  }
}
</script>

<style scoped>
</style>
