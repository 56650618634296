<template>
  <div id="ReportTicketSalesTable">
    <StyledTableReport
      :headers="headersReport"
      :details="detailsReport"
      :title="titleReport"
      :totals="{ tickets: soldTickets, canje: exchangedTickets, totalTickets: totalTickets, total: totalSales }"
      center
    >
      <tr
        v-for="(item, index1) in ticketsDetails"
        :key="`ticketsWithDetails-report-${index1}`"
      >
        <td align="center">
          {{ item.shortname }}
        </td>
        <td>{{ item.Descripcion }}</td>
        <td align="center">
          {{ $formatNum(item.Cantidad) }}
        </td>
        <td align="center">
          {{ $formatNum(item.Canje) }}
        </td>
        <td align="center">
          {{ $formatNum(item.Boleto) }}
        </td>
        <td align="right">
          ${{ $formatPrice(item.unitario) }}
        </td>
        <td align="right">
          ${{ $formatPrice(item.total) }}
        </td>
      </tr>
    </StyledTableReport>
  </div>
</template>

<script>
import StyledTableReport from '../tools/reports/StyledTableReport.vue'
export default {
  name: 'ReportTicketSalesTable',
  components: { StyledTableReport },
  props: [
    'dateStart',
    'dateEnd',
    'titleReport',
    'ticketsDetails',
    'soldTickets',
    'exchangedTickets',
    'totalTickets',
    'totalSales'
  ],
  data () {
    return {
      headersReport: [
        { name: 'Sitio', attrs: { } },
        { name: 'Concepto', attrs: { class: 'text-left' } },
        { name: 'Vendidos', attrs: { } },
        { name: 'Canjes', attrs: { } },
        { name: 'total', attrs: { } },
        { name: 'Precio Individual', attrs: { } },
        { name: 'Importe', attrs: { } }
      ],
      detailsReport: [
        {
          key: 'Fecha',
          value: `${this.$formatFecha(this.dateStart)} al ${this.$formatFecha(this.dateEnd)}`,
          attrs: { class: 'pt-2' }
        }
      ]
    }
  }

}
</script>

<style>

</style>
