<template>
  <div id="NavBarDashboard">
    <v-app-bar
      dark
      dense
      fixed
      :height="getHeightCss"
      align="center"
      elevation="0"
    >
      <v-btn
        class="ml-n2 mr-3 button-app"
        text
        @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ $route.fullPath.includes('/reports') ? 'Reportes' : $route.name }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="$route.fullPath.includes('/reports') && $route.fullPath !== '/reports'"
        class="ma-2 button-responsive"
        color="orange darken-2"
        dark
        @click="$router.go(-1)"
      >
        <v-icon
          left
          class="icon-button-responsive"
        >
          mdi-arrow-left
        </v-icon>
        <span class="title-button-responsive">
          regresar
        </span>
      </v-btn>
      <v-btn
        v-if="$route.fullPath === '/wholesalers'"
        class="ma-2 button-responsive"
        dark
        color="orange"
        @click="openReferenceLineChecker()"
      >
        <v-icon
          left
          class="icon-button-responsive"
        >
          mdi-calculator
        </v-icon>
        <span class="title-button-responsive">
          Verificador
        </span>
      </v-btn>
      <v-tooltip
        v-if="$route.fullPath === '/ticket-setup'"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            small
            color="pink"
            v-bind="attrs"
            v-on="on"
            @click="showAxessTicketsComputed = !showAxessTicketsComputed"
          >
            <v-icon>{{ showAxessTicketsComputed ? 'mdi-close' : 'mdi-format-list-bulleted-square' }}</v-icon>
          </v-btn>
        </template>
        <span v-if="showAxessTicketsComputed">Ticket Axess</span>
        <span v-if="!showAxessTicketsComputed">Ticket Promtec</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      bottom
      temporary
      dark
      color="#363636"
      :mobile-breakpoint="600"
    >
      <div class="container-user-data">
        <v-list-item
          class="container-avatar px-2"
          @click="sendToRoute('Perfil y configuración')"
        >
          <div
            align="center"
            class="container-img-avatar"
          >
            <img
              :src="userData.avatar"
              class="img-avatar"
            >
          </div>
          <div
            v-if="alertLogo(userData.avatar)"
            class="add-avatar-message"
          >
            Haz click para agregar tu foto
          </div>
        </v-list-item>

        <v-list-item
          link
          active-class="bg-active"
          :to="'/account-settings-dashboard'"
        >
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ userData.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ userData.cargo }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>

      <v-divider />

      <v-list
        class="pt-0 mt-0"
      >
        <template
          v-for="(item) in routes"
        >
          <v-list-item
            v-if="!item.sub.length"
            :key="'navBar-dashboard-'+item.title"
            link
            active-class="bg-active"
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>

          <v-list-group
            v-else
            :key="'navBar-dashboard-group-'+item.title"
            class="active-dropdown"
            no-action
            :prepend-icon="item.icon"
            dark
            color="white"
          >
            <template slot="activator">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="subitem in item.sub"
              :key="`navBar-dashboard-sub-${item.title}-${subitem.title}`"
              dark
              link
              active-class="bg-active"
              :to="subitem.link"
              style="padding-left: 40px !important"
            >
              <v-list-item-title>
                {{ subitem.title }}
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon>
                  {{ subitem.icon }}
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            class="white--text"
            color="pink"
            @click="logout"
          >
            <v-icon class="mr-2">
              mdi-logout
            </v-icon>
            Cerrar sesión
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'NavBarDashboard',
  data () {
    return {
      drawer: false
    }
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      userData: 'auth/userData'
    }),
    ...mapState('dashboard', ['showAxessTickets']),
    showAxessTicketsComputed: {
      get () {
        return this.showAxessTickets
      },
      set (val) {
        this.setShowAxessTickets(val)
      }
    },
    getHeightCss () {
      const defaultHeight = '58px'
      try {
        const height = getComputedStyle(document.querySelector('html')).getPropertyValue('--h-navbar')
        return height || defaultHeight
      } catch (e) {
        return defaultHeight
      }
    },
    routes () {
      let routes = [
        { title: 'Inicio', icon: 'mdi-home', link: '/dashboard', sub: [] },
        { title: 'Reportes', icon: 'mdi-view-grid-outline', link: '/reports', sub: [] },
        { title: 'Api Service', icon: 'mdi-xml', link: '/API', sub: [] },
        {
          title: 'Catalogos',
          icon: 'mdi-arrange-send-backward',
          sub: [
            { title: 'Configuración Boletos', link: '/ticket-setup', icon: 'mdi-ticket-confirmation-outline' },
            { title: 'Boletos Restantes', link: '/remaining-tickets', icon: 'mdi-file-restore-outline' },
            { title: 'Boletos Repetidos', link: '/repeat-tickets', icon: 'mdi-repeat-once' },
            { title: 'Boletos usados', link: '/tickets-use', icon: 'mdi-ticket' },
            { title: 'Configuración Cajas', link: '/pos-configuration', icon: 'mdi-printer-pos-cog-outline' },
            { title: 'Configuración usuarios', link: '/users-configurations', icon: 'mdi-account-cog-outline' },
            { title: 'Cancelacion Manual', link: '/manual-cancellations', icon: 'mdi-printer-pos-cancel-outline' }
          ]
        },
        { title: 'Mayoristas', icon: 'mdi-sale', link: '/wholesalers', sub: [] }
      ]

      const defaultRoutes = ['Inicio', 'Reportes'] // Cultur, Parador y Soporte aafy
      const alLowedRoutesMap = {
        0: () => ['Inicio', 'Reportes', 'Api Service', 'Catalogos', 'Mayoristas'], // Promtec
        1: () => {
          if (this.userData.subrol === 3) return ['Inicio', 'Reportes', 'Mayoristas'] // Cajero Mayorista
          return ['Inicio', 'Reportes', 'Api Service', 'Mayoristas'] // Aafy
        },
        7: () => ['Reportes'], // Contabilidad
        8: () => ['Reportes', 'Api Service', 'Catalogos'] // Mesa de ayuda
      }

      const allowedRoutes = alLowedRoutesMap[this.userData.rol] ? alLowedRoutesMap[this.userData.rol]() : defaultRoutes
      routes = routes.filter(route => allowedRoutes.includes(route.title))

      return routes
    }
  },
  methods: {
    ...mapMutations({
      setShowAxessTickets: 'dashboard/setShowAxessTickets'
    }),
    alertLogo: function (val) {
      try {
        const temp = val.split('/')
        if (!temp.length) return true
        const temp1 = temp?.[5].split('?')
        if (!temp1.length) return true
        return temp1[0] === '0.png'
      } catch (error) {
        console.log(error)
      }
    },
    sendToRoute: function (name, params = {}) {
      if (!name || this.$route.name === name) return
      this.$router.push({ name, params })
    },
    openReferenceLineChecker: function () {
      const routeData = this.$router.resolve({ name: 'ReferenceLineChecker', query: { data: 'someData' } })
      window.open(routeData.href, '', 'height=500,width=500,left=100,top=100,resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=no')
    },
    logout: async function () {
      this.drawer = true
      const result = await this.$ConfirmBox('warning', 'Esta seguro?', 'Realmente quieres salir del sistema!')
      if (!result.isConfirmed) return

      this.$root.logout()
    }

  }
}
</script>

<style scoped>
.button-app:hover {
  background-color: white !important;
  color: #444 !important;
}

.name-user-container {
  display: none;
}

.container-avatar {
  position: relative !important;
  background-color: white;
}

.container-img-avatar {
  width: 100%;
  height: 121px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.img-avatar {
  max-width: 240px !important;
  max-height: 121px !important;
}

.add-avatar-message {
  position: absolute;
  top: calc(100% - 35px);
  left: 0;
  width: 100%;
  color: white;
  background-color: #272727b4;
  text-align: center;
  padding: 2px;
  font-size: 13px;
}

.bg-active {
  color: #fff !important;
  caret-color: #fff !important;
  background-color: #1d1b31 !important;
  border-color: #1d1b31 !important;
}

.theme--dark.v-list-item--active:hover::before,
.theme--dark.v-list-item--active::before {
  opacity: 0 !important;
}

.subitem-title {
  font-size: 15px !important;
}

@media (max-width: 580px) {
  .button-responsive {
    margin-right: 0 !important;
  }

  .icon-button-responsive {
    margin-right: 0 !important;
    font-size: 20px !important;
  }

  .title-button-responsive {
    display: none;
  }
}
</style>
