<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div id="PendingTransactions">
    <v-toolbar
      height="45px"
      dark
      elevation="0"
      color="#1e1e1e"
    >
      <v-spacer />

      <v-text-field
        v-if="!isExpiredTrasactions"
        v-model="search"
        label=""
        outlined
        dark
        clearable
        dense
        color="orange"
        placeholder="Escribe lo que desea buscar"
        prepend-icon="mdi-magnify"
        class="search-text-field"
        hide-details
      />

      <v-text-field
        v-if="isExpiredTrasactions"
        v-model="searchExpired"
        label=""
        outlined
        dark
        clearable
        dense
        color="orange"
        placeholder="Escribe la línea de referencia y presione enter"
        prepend-icon="mdi-account-search"
        class="search-text-field"
        hide-details
        @keyup.enter="getExpiredTransactions"
        @click:clear="clearExpiredSearch"
      />
    </v-toolbar>
    <div class="content-with-fixed-toolbar">
      <v-card>
        <v-card-title
          v-if="!loading && items.length"
          class="pt-2 pb-1"
        >
          <h5 v-if="isExpiredTrasactions">
            * Limite de busqueda 20 resultados
          </h5>
          <v-spacer />
          <v-btn
            height="40"
            color="success"
            class="ma-2 ml-7"
            @click="exportTable"
          >
            Exportar
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader
            v-if="loading"
            type="table"
          />
          <v-alert
            v-if="!loading && !items.length"
            class="mb-0"
            icon="mdi-folder-alert-outline"
            prominent
            text
            type="warning"
            align="left"
          >
            {{ textNoData }}
          </v-alert>
          <div v-if="!loading && items.length">
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :sort-by="['fecha']"
              :sort-desc="[true]"
              multi-sort
              mobile-breakpoint="Infinity"
            >
              <template v-slot:body="{ items }">
                <tbody v-if="!items.length">
                  <td colspan="20">
                    <v-alert
                      class="mb-0 text"
                      align="center"
                    >
                      Sin datos para esta búsqueda
                    </v-alert>
                  </td>
                </tbody>
                <tbody v-else>
                  <template v-for="(item, index) in items">
                    <tr
                      :key="`item-pending-transaction-${item.id}`"
                      class="pointman"
                      style="color: black"
                      :style="{ 'background-color': $backgroundRowTable(index) }"
                      @click="clickrow(item)"
                    >
                      <td style="padding: 5px; max-width: 14px !important;">
                        <v-icon class="ml-2">
                          mdi-{{ item.id === expanded ? 'minus' : 'plus' }}
                        </v-icon>
                      </td>
                      <td v-if="false">
                        {{ item.RefOri }}
                      </td>
                      <td align="center">
                        <strong>{{ item.Date }}</strong>
                      </td>
                      <td
                        :style="[
                          item.valido ? { color: 'green' } : { color: 'red' },
                        ]"
                      >
                        <strong>
                          {{ item.valido ? 'SI': 'NO' }}
                        </strong>
                      </td>
                      <td>{{ item.CorpName }}</td>
                      <td align="center">
                        {{ item.fecha }}
                      </td>
                      <td>
                        <strong>${{ $formatPrice(item.total) }} <ExchangeCurrency /></strong>
                      </td>
                      <td align="center">
                        <strong>{{ $formatNum(item.cBoletos) }}</strong>
                      </td>
                      <td align="center">
                        <v-btn
                          small
                          color="success"
                          @click="validateTransaction(item)"
                        >
                          <v-icon>mdi-clipboard-check-multiple-outline</v-icon>
                        </v-btn>
                      </td>
                      <td align="center">
                        <v-btn
                          small
                          dark
                          color="orange"
                          class="ma-2"
                          @click="lockTransaction(item)"
                        >
                          <v-icon>mdi-cancel</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr
                      v-if="item.id === expanded"
                      :key="`pending-transaction-details-${item.id}`"
                      style="background-color: white !important"
                    >
                      <TicketsDetailsTransactions
                        :colspan="headers.length"
                        :placeDetails="item.placeData"
                        :transactionId="item.id"
                      />
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <div
      v-show="false"
      :id="`pending-transactions-${isExpiredTrasactions ? 'expired': 'toValidate'}-tickets-wrap`"
    >
      <table style="width:100%;">
        <tr align="right">
          <td
            colspan="7"
            style="font-size: 16px;"
          >
            {{ getNameReport }}
          </td>
        </tr>
        <tr align="right">
          <td
            colspan="7"
            style="font-size: 16px;"
          >
            <strong>Al día: </strong> {{ $formatFecha(nowDate, '/') }}
          </td>
        </tr>
        <tr />
      </table>
      <div
        v-for="item in items"
        :key="`pending-transaction-${item.id}`"
      >
        <table
          border="1"
          bordercolor="#272727"
          class="styled-table"
          style="border-collapse: collapse; width:100%;"
        >
          <thead>
            <tr>
              <td align="center">
                <strong>Línea de Referencia</strong>
              </td>
              <td align="center">
                <strong>Fecha de vencimiento</strong>
              </td>
              <td align="center">
                <strong>Válido</strong>
              </td>
              <td align="center">
                <strong>Nombre de la Empresa</strong>
              </td>
              <td align="center">
                <strong>Fecha Orden de Pago</strong>
              </td>
              <td align="center">
                <strong>Costo Total</strong>
              </td>
              <td align="center">
                <strong>Total de Boletos</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>{{ item.RefOri }}  &nbsp;</strong>
              </td>
              <td align="center">
                <strong>{{ item.Date }}</strong>
              </td>
              <td align="center">
                {{ item.valido }}
              </td>
              <td>{{ item.CorpName }}</td>
              <td align="center">
                {{ item.fecha }}
              </td>
              <td>
                <strong>${{ $formatPrice(item.total) }}</strong>
              </td>
              <td align="center">
                <strong>{{ $formatNum(item.cBoletos) }}</strong>
              </td>
            </tr>
            <tr>
              <TicketsDetailsTransactionsToExport
                :colspan="headers.length - 3"
                :placeDetails="getPlaceDetails(item)"
                :transactionId="item.id"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import DASHBOARDWHOLESALERS from '../../../minixs/dashboard-wholesalers'
import REFERENCELINECHECKER from '../../../minixs/reference-line-checker'

// Components
import TicketsDetailsTransactions from '../../tools/TicketsDetailsTransactions.vue'
import TicketsDetailsTransactionsToExport from '../../tools/TicketsDetailsTransactionsToExport.vue'

export default {
  name: 'PendingTransactions',
  components: { TicketsDetailsTransactions, TicketsDetailsTransactionsToExport },
  mixins: [DASHBOARDWHOLESALERS, REFERENCELINECHECKER],
  props: ['activeNow', 'isExpiredTrasactions', 'nowDate'],
  data () {
    return {
      search: '',
      searchExpired: '',
      searchedExpired: '',
      headers: [
        { text: '', value: '', sortable: false },
        { text: 'Línea de Referencia', value: 'RefOri', width: '280px', class: 'd-none', sortable: false },
        { text: 'Fecha de vencimiento', value: 'Date', align: 'center', width: '189px' },
        { text: 'Valido', value: 'valido' },
        { text: 'Nombre de la Empresa', value: 'CorpName' },
        { text: 'Fecha Orden de Pago', value: 'fecha', align: 'center', width: '189px' },
        { text: 'Costo Total', value: 'total' },
        { text: 'Total de Boletos', value: 'cBoletos', align: 'center' },
        { text: 'Validar', value: '', align: 'center', sortable: false },
        { text: 'Bloquear', value: '', align: 'center', sortable: false }
      ],
      items: [],
      loading: false,
      expanded: null
    }
  },
  computed: {
    textNoData: function () {
      let textNoData = 'No hay '
      if (!this.isExpiredTrasactions) {
        textNoData += 'pendientes por validar!'
      } else {
        textNoData += 'vencidos sin validar'
        textNoData += this.searchedExpired ? ` para '${this.searchedExpired}'!` : '!'
      }
      return textNoData
    },
    getNameReport: function () {
      let name = 'Transacciones '
      if (!this.isExpiredTrasactions) {
        name += 'por validar'
      } else {
        name += 'vencidas sin validar'
        name += this.searchedExpired ? ` para '${this.searchedExpired}'` : ''
      }
      return name
    },
    fileName: function () {
      let name = this.getNameReport.replaceAll(' ', '_')
      name += '_al_dia_' + this.$formatFecha(this.nowDate)
      return name
    }
  },
  watch: {
    activeNow: function (val) {
      if (val) this.getData()
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData: function () {
      if (this.isExpiredTrasactions) this.getExpiredTransactions()
      else this.getToValidateTransactions()
    },
    getPlaceDetails: function (item) {
      if (item.placeData) return item.placeData

      const placeData = item.sitio
      const placeDetails = []
      placeData.forEach(element => {
        const {
          SitioName: shortname,
          sitio,
          ticket,
          subtotal2: total
        } = element
        const transaccion = ticket.map((item) => {
          const { NameTicket: nombre, alias, cantidad, costo, subtotal: total, id_ticket, fecha } = item

          return { nombre, alias, cantidad, costo, total, id_ticket, fecha }
        })
        const ticketData = {
          shortname,
          sitio,
          transaccion,
          total
        }
        placeDetails.push(ticketData)
      })
      item.placeData = placeDetails
      return placeDetails
    },
    exportTable: function (event) {
      this.$exportExcelFromJs(event, this.fileName, `pending-transactions-${this.isExpiredTrasactions ? 'expired' : 'toValidate'}-tickets-wrap`)
    },
    getToValidateTransactions: function () {
      this.loading = true
      this.items = []
      this.search = ''

      const data = { status: 1, opt: 0, type: 1 }
      this.getTransactions(data)
    },
    getExpiredTransactions: function () {
      this.loading = true
      this.items = []
      this.searchedExpired = this.searchExpired

      const data = { status: 1, opt: 0, type: 4, linea: this.searchExpired }
      this.getTransactions(data)
    },
    getTransactions: function (data) {
      this.$root.post('/wholesalers/getTransCartGob', { data })
        .then(response => {
          const info = response.data
          if (info.success === 'FALSE') return this.$swalAlert('error', info.message, null)

          for (let i = 0; i < info.length; i++) {
            info[i].sitio = JSON.parse(info[i].sitio)
            info[i].valido = 'Verificando..'
            info[i].total = info[i].total.toFixed(2)

            this.verifyReferencieLine(info, i, info.length - 1)
          }
        }).finally(() => { this.loading = false })
    },
    clearExpiredSearch: function () {
      this.searchExpired = ''
      this.getExpiredTransactions()
    },
    verifyReferencieLine: async function (info, i, leg) {
      info[i].valido = await this.validateReferenceLine(info[i])

      if (i >= leg) this.items = info
    },
    validateReferenceLine: async function (transaction) {
      const constante = 2
      const residuo = 97

      if (!transaction) {
        return Promise.resolve(false)
      }
      const dateCode = transaction.Date ? await this.getDateCode(transaction.Date) : false
      const totalCode = transaction.total ? await this.getTotalCode(transaction.total) : false
      const referenceCode = transaction.RefOri ? await this.getReferenceCode(transaction.RefOri, constante, residuo) : false

      if (dateCode === false || totalCode === false || referenceCode === false) {
        return Promise.resolve(false)
      }

      const LineRef = referenceCode.folioRef + dateCode + totalCode + constante + referenceCode.Refclave

      const Valido = LineRef === transaction.RefOri
      return Promise.resolve(Valido)
    },
    clickrow (item) {
      if (!this.flag) {
        this.expanded = this.expanded === item.id ? '' : item.id
        if (this.expanded) this.getPlaceDetails(item)
      } else {
        this.flag = false
      }
    }

  }
}
</script>

<style scoped>
.content-with-fixed-toolbar {
  height: calc(100vh - var(--h-navbar) - 45px - 45px);
  overflow-y: auto;
  padding: 20px;
}

.search-text-field {
  max-width: 600px !important;
}
</style>

<style scoped src="../../../assets/css/style-tables-dark.css">
</style>
