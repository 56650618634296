<template>
  <SecctionFormStyled id="FormChangePassword">
    <v-form ref="formChangePassword">
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="password"
            :type="hidePassword ? 'password' : 'text'"
            :append-icon="`mdi-${hidePassword ? 'eye-off' : 'eye'}`"
            name="Nueva contraseña"
            label="Nueva contraseña"
            outlined
            :rules="[$rules.required]"
            hide-details="auto"
            @click:append="hidePassword = !hidePassword"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="passwordAgain"
            :type="hidePasswordAgain ? 'password' : 'text'"
            :append-icon="`mdi-${hidePasswordAgain ? 'eye-off' : 'eye'}`"
            name="Repita contraseña"
            label="Repita contraseña"
            outlined
            :rules="[$rules.required]"
            hide-details="auto"
            @click:append="hidePasswordAgain = !hidePasswordAgain"
          />
        </v-col>

        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <div class="container-actions-buttons">
            <v-btn
              :disabled="loading"
              :loading="loading"
              @click="resetForm"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="primary"
              color="#df8157 !important"
              :disabled="loading"
              :loading="loading"
              @click="saveNewPassword"
            >
              Guardar
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </SecctionFormStyled>
</template>

<script>
import SecctionFormStyled from '../../tools/SecctionFormStyled.vue'
export default {
  name: 'FormChangePassword',
  components: { SecctionFormStyled },
  data () {
    return {
      password: '',
      passwordAgain: '',
      hidePassword: true,
      hidePasswordAgain: true,
      loading: false
    }
  },
  watch: {
  },
  methods: {
    resetForm: function () {
      this.$refs.formChangePassword.reset()
      this.$refs.formChangePassword.resetValidation()
      this.password = ''
      this.passwordAgain = ''
      this.hidePassword = true
      this.hidePasswordAgain = true
    },
    saveNewPassword: function () {
      if (!this.$refs.formChangePassword.validate()) return this.$Toast('error', 'Verifique los campos')
      if (this.password !== this.passwordAgain) return this.$swalAlert('error', 'Las contraseñas no coinciden')

      const data = { password: this.password }
      this.$root.post('/auth/updatePassword', data)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }
          this.resetForm()
          this.$swalAlert('success', 'Contraseña modificada', null)
        })
    }
  }
}
</script>

<style scoped>
.container-actions-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
</style>
