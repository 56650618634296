<template>
  <div
    id="ReportWholesalers"
    class="content-tickets-corps-tabs"
  >
    <TitleReportsCard
      title="Reporte de cajero"
      justify="center"
      :loading="loading"
      @generateReport="generateReport"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateStart"
          :newMax="dateEnd"
          label="Seleccione una fecha de inicio"
          :disabled="loading"
          @change="getListCashiers"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateEnd"
          label="Seleccione una fecha de fin"
          :disabled="loading"
          @change="getListCashiers"
        />
      </v-col>
      <v-col
        v-if="canChangeUser"
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          v-model="cashierSelected"
          prepend-icon="mdi-account"
          dense
          outlined
          :items="listCashiers"
          name="id"
          item-value="id"
          item-text="name"
          label="Seleccione un cajero"
          :disabled="loading"
          hide-details
          @change="generated = false"
        />
      </v-col>
    </TitleReportsCard>

    <ContentReportsCard
      style="min-width: 80%;"
      :loading="loading"
      :generated="generated"
      :fileName="fileName"
      :noData="false"
    >
      <ReportSalesByCashierWholesalersTable
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :titleReport="getTitleReport"
        :detMayo="detMayo"
        :detUserMayo="detUserMayo"
        :totalMayo="totalMayo"
        :detailTransactions="detailTransactions"
      />
    </ContentReportsCard>
  </div>
</template>

<script>
// Vue
import { mapGetters } from 'vuex'

// Components
import InputDatePickerReport from '../../tools/reports/InputDatePickerReport.vue'
import TitleReportsCard from '../../tools/reports/TitleReportsCard.vue'
import ContentReportsCard from '../../tools/reports/ContentReportsCard.vue'
import ReportSalesByCashierWholesalersTable from '../../reports/ReportSalesByCashierWholesalersTable.vue'

export default {
  name: 'ReportWholesalers',
  components: { TitleReportsCard, InputDatePickerReport, ContentReportsCard, ReportSalesByCashierWholesalersTable },
  props: ['activeNow', 'nowDate'],
  data () {
    return {
      loading: false,
      generated: false,
      dateStart: '',
      dateEnd: '',
      listCashiers: [],
      cashierSelected: null,
      detMayo: [],
      detUserMayo: [],
      totalMayo: 0,
      detailTransactions: []
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData'
    }),
    canChangeUser: function () {
      return this.userData.rol === 0
    },
    getNameCashier: function () {
      let name = ''
      this.listCashiers.forEach(item => {
        if (item.id === this.cashierSelected) name = item.name
      })
      return name
    },
    fileName: function () {
      return `Reporte_de_cajero_${this.getNameCashier}-${this.cashierSelected}_mayoristas_del_` + this.dateStart + '_hasta_' + this.dateEnd
    },
    getTitleReport: function () {
      return `Reporte de cajero "${this.getNameCashier}-${this.cashierSelected}" en mayoristas`
    }
  },
  mounted () {
    this.dateStart = this.nowDate
    this.dateEnd = this.nowDate
    this.getListCashiers()
  },
  methods: {
    getListCashiers: function () {
      if (!this.canChangeUser) {
        this.cashierSelected = this.userData.id
        return
      }
      this.generated = false
      this.loadingCashiers = true
      this.cashierSelected = null
      this.listCashiers = []

      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      }
      this.$root
        .post('/reports/getListCashierWholesalers', data)
        .then((response) => {
          const data = response.data
          this.listCashiers = data
        }).finally(() => { this.loadingCashiers = false })
    },
    generateReport: function () {
      if (this.canChangeUser && !this.cashierSelected) {
        this.$swalAlert('warning', 'Seleccione un cajero!')
        return
      }
      this.loading = true
      this.generated = false

      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        cashierSelected: this.cashierSelected
      }
      this.$root
        .post('/reports/salesCashierWholesalers', data)
        .then((response) => {
          const data = response.data
          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          const { salesWholesalers, users, totalWholesalers, detailTransactions } = data
          this.detMayo = salesWholesalers
          this.detUserMayo = users
          this.totalMayo = totalWholesalers
          this.detailTransactions = detailTransactions
        })
        .finally(() => {
          this.loading = false
          this.generated = true
        })
    }
  }
}
</script>

<style scoped>
.content-tickets-corps-tabs {
  max-height: calc(100vh - var(--h-navbar) - 45px);
  overflow-y: auto;
}
</style>
