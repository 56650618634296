import MainReportsDashboard from '../views/dashboard/MainReportsDashboard.vue'
import ReportsDashboard from '../views/dashboard/ReportsDashboard.vue'
import ReportCommissions from '../components/reports/ReportCommissions.vue'
import ReportCommissionsParking from '../components/reports/ReportCommissionsParking.vue'
import ReportByPlace from '../components/reports/ReportByPlace.vue'
import ReportByPOS from '../components/reports/ReportByPOS.vue'
import ReportByCashier from '../components/reports/ReportByCashier.vue'
import ReportTicketSales from '../components/reports/ReportTicketSales.vue'
import ReportTicketSalesWithDetails from '../components/reports/ReportTicketSalesWithDetails.vue'
import ReportTicketSalesAllPlaces from '../components/reports/ReportTicketSalesAllPlaces.vue'
import ReportCanceledTransactions from '../components/reports/ReportCanceledTransactions.vue'
import ReportSalesWholesalers from '../components/reports/ReportSalesWholesalers.vue'
import ReportSalesByCashierWholesalers from '../components/reports/ReportSalesByCashierWholesalers.vue'
import ReportTicketsMayo from '../components/reports/ReportTicketsMayo.vue'
import ReportWholesaleTickets from '../components/reports/ReportWholesaleTickets.vue'

export default [
  {
    path: '/reports/',
    component: MainReportsDashboard,
    children: [
      {
        path: '',
        name: 'Reportes',
        component: ReportsDashboard
      },
      {
        path: 'commissions',
        name: 'Comisiones',
        component: ReportCommissions
      },
      {
        path: 'commissions-parking',
        name: 'Comisiones Parking',
        component: ReportCommissionsParking
      },
      {
        path: 'place',
        name: 'Reporte por lugar',
        component: ReportByPlace
      },
      {
        path: 'pos',
        name: 'Reporte por POS',
        component: ReportByPOS
      },
      {
        path: 'cashier',
        name: 'Reporte por cajero',
        component: ReportByCashier
      },
      {
        path: 'wholesale-tickets',
        name: 'Reporte de mayoristas de boletos de vendidos, generados y usudos',
        component: ReportWholesaleTickets
      },
      {
        path: 'ticket-sales',
        name: 'Reporte de ventas de boletos',
        component: ReportTicketSales
      },
      {
        path: 'ticket-sales-details',
        name: 'Reporte de ventas de boletos con detalles',
        component: ReportTicketSalesWithDetails
      },
      {
        path: 'ticket-sales-all-places',
        name: 'Reporte de ventas de boletos por lugar',
        component: ReportTicketSalesAllPlaces
      },
      {
        path: 'canceled-transactions',
        name: 'Reporte de transacciones canceladas',
        component: ReportCanceledTransactions
      },
      {
        path: 'wholesale-sales',
        name: 'Reporte de ventas de mayoristas',
        component: ReportSalesWholesalers
      },
      {
        path: 'wholesale-cashier-sales',
        name: 'Reporte de ventas de mayoristas por cajero',
        component: ReportSalesByCashierWholesalers
      },
      {
        path: 'wholesale-tickets-sales',
        name: 'Reporte de ventas de boletos mayoristas',
        component: ReportTicketsMayo
      }
    ]
  }
]
