<template>
  <div id="ReportTicketSales">
    <TitleReportsCard title="Reporte de mayoristas de boletos de vendidos, generados y usudos" :loading="loading"
      @generateReport="generateReport">
      <v-col cols="12" sm="10">

        <v-autocomplete v-model="placeSelected" :items="userwhoshellers" chips multiple name="id" item-text="name"
          item-value="id" label="Seleccione un mayorista" background-color="white" :disabled="loading"
          prepend-icon="mdi-bank" dense outlined @change="generated = false">

          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < limitSelect" style="font-size: 12px;">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === limitSelect"
              class="text-grey text-caption align-self-center"
            >
              (+{{ placeSelected.length - limitSelect }} others)
            </span>
          </template>

          <template v-slot:prepend-item>
            <v-list-item title="Select All" @click="toggle">
              <template>
                <v-checkbox v-model="checkbox2" label="Seleccionar todos"></v-checkbox>
              </template>
            </v-list-item>

            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>

      </v-col>

    </TitleReportsCard>
    <ContentReportsCard :loading="loading" :generated="generated" :fileName="fileName" :noData="!ticketsDetails.length">
      <ReportWhosallersSalesTable :titleReport="getTitleReport" :ticketsDetails="ticketsDetails" />
    </ContentReportsCard>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Components
import TitleReportsCard from '../tools/reports/TitleReportsCard.vue'
import InputDatePickerReport from '../tools/reports/InputDatePickerReport.vue'
import ContentReportsCard from '../tools/reports/ContentReportsCard.vue'
import ReportWhosallersSalesTable from './ReportWhosallersSalesTable.vue'
import { LineChart } from 'vue-chart-3'

export default {
  name: 'ReportTicketSales',
  components: { TitleReportsCard, InputDatePickerReport, ContentReportsCard, ReportWhosallersSalesTable, LineChart },
  data() {
    return {
      dateStart: '',
      dateEnd: '',
      loading: false,
      generated: false,
      placeSelected: [],
      ticketsDetails: [],
      userwhoshellers: [],
      limitSelect: 5,
      selectUsers: [],
      soldTickets: 0,
      checkbox2: false,
      exchangedTickets: 0,
      totalTickets: 0,
      chartData: {
        toggleLegend: true,
        datasets: [
          {
            label: 'Boletos',
            data: [],
            backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED']
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: this.toggleLegend ? 'top' : 'bottom'
          },
          title: {
            display: true,
            text: '',
            font: {
              size: 18
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState('dashboard', ['placesForUser']),
    namePlaceSelected: function () {
      let name = ''
      this.placesForUser.forEach(element => {
        if (element.id === this.placeSelected) name = element.name
      })
      return name
    },
    fileName: function () {
      return 'Boletos_vendidos_' + this.namePlaceSelected + '_del_' + this.dateStart + '_hasta_' + this.dateEnd
    },
    getTitleReport: function () {
      return `Boletos vendidos de "${this.namePlaceSelected}"`
    },
  },
  mounted() {
    this.dateStart = this.$sumarDias2(new Date())
    this.dateEnd = this.$sumarDias2(new Date())
    this.getListWhosellers()
  },
  methods: {
    getListWhosellers: function () {
      let data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$root.post('/reports/getListWhosellers', data)
        .then(response => {
          const listWhoseller = response.data
          this.userwhoshellers = listWhoseller
        })
        .finally(() => {
          this.loading = false
          this.generated = true
        })
    },
    generateReport: function () {
      let ids = []
      this.ticketsDetails = []
      this.loading = true
      this.generated = false
      
      this.placeSelected.map((e)=>{
        if(e.id){
          ids.push(e.id)
        }else{
          ids.push(e)
        }
      })

      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        id: ids
      }
      this.$root.post('/reports/getWhosellersReport', data)
        .then(response => {
          const data = response.data
          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }
          let ticketsDetails = data

          ticketsDetails.map((e) => {
            e.sitios = JSON.parse(e.sitios)
          })

          this.ticketsDetails = ticketsDetails
        })
        .finally(() => {
          this.loading = false
          this.generated = true
        })
    },
    toggle() {
      if (this.placeSelected.length > 0) {
        this.checkbox2 = false
        this.placeSelected = []
      } else {
        this.checkbox2 = true
        this.placeSelected = this.userwhoshellers.slice()
      }
    }
  }
}
</script>

<style></style>
