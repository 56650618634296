<template>
  <v-card
    class="MyCardL2"
    style="background: white; opacity: 1"
  >
    <v-toolbar
      dark
    >
      <v-toolbar-title>Registro de mayoristas</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pt-0">
      <v-form ref="signInForm">
        <v-subheader class="pl-0">
          Datos del usuario
        </v-subheader>
        <FormUserData
          :formUser="formUser"
          :isAddUser="false"
        />
        <v-subheader class="pl-0">
          Datos de la Empresa
        </v-subheader>
        <FormCorpData :formCorp="formCorp" />
      </v-form>
    </v-card-text>
    <v-card-actions class="sing-buttons">
      <v-btn
        min-width="211"
        color="#dd8159"
        class="my_font"
        @click="changeView"
      >
        Ya tengo una cuenta
      </v-btn>

      <v-btn
        :color="'primary'"
        :loading="loading"
        min-width="211"
        class="my_font"
        @click="viewPrivacyPolicy"
      >
        Registrarse
      </v-btn>
    </v-card-actions>

    <DialogTermsConditions
      v-model="dialog"
      :validateScroll="true"
      @acceptedPrivacy="signIn"
    />
  </v-card>
</template>

<script>
// Vue
import { mapMutations } from 'vuex'

// Components
import FormUserData from '../wholesalers/FormUserData.vue'
import FormCorpData from '../wholesalers/FormCorpData.vue'
import DialogTermsConditions from '../tools/DialogTermsConditions.vue'

export default {
  components: {
    FormUserData,
    FormCorpData,
    DialogTermsConditions
  },
  data () {
    return {
      dialog: false,
      loading: false,
      formUser: {
        id: '',
        email: '',
        clave: '',
        name: '',
        name2: '',
        name3: '',
        rol: 4,
        subrol: 0,
        sitio: 0,
        avatar: '//gpstuuxan.com/img/user/0.png'
      },
      formCorp: {
        companyRFC: '',
        companyName: '',
        companyAddress: '',
        companyPhone: '',
        companyState: 3,
        companySentDocument: 0
      }
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.btnprivacyPolicy = false
      }
    }
  },
  methods: {
    ...mapMutations({
      loginUser: 'auth/loginUser',
      setLoadingMainApp: 'globalStates/setLoadingMainApp'
    }),
    viewPrivacyPolicy: function () {
      if (!this.$refs.signInForm.validate()) return
      this.dialog = true
    },
    signIn: function () {
      if (!this.$refs.signInForm.validate()) return

      this.setLoadingMainApp(true)
      const data = Object.assign({}, this.formUser, this.formCorp)
      this.$root.post('/auth/signin', data)
        .then((response) => {
          const data = response?.data
          const dataStore = data?.[0]

          if (data?.success === 'FALSE' || dataStore?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message || dataStore?.message)
            return
          }

          this.$swalAlert('success', null, data.message ?? dataStore?.message)
          this.clearForm()
          this.changeView()
        })
        .finally(() => {
          this.setLoadingMainApp(false)
        })
    },
    changeView: function () {
      this.clearForm()
      this.$emit('changeView')
    },
    clearForm: function () {
      this.formUser = {
        id: '',
        email: '',
        clave: '',
        name: '',
        name2: '',
        name3: '',
        rol: 4,
        subrol: 0,
        sitio: 0,
        avatar: '//gpstuuxan.com/img/user/0.png'
      }
      this.formCorp = {
        companyRFC: '',
        companyName: '',
        companyAddress: '',
        companyPhone: '',
        companyState: 3,
        companySentDocument: 0
      }
      this.$refs.signInForm.reset()
      this.$refs.signInForm.resetValidation()
    }
  }
}
</script>

<style scoped>
.MyCardL2 {
  max-width: 550px !important;
}

.my_font {
  margin-top: 5px;
  margin-bottom: 10px;
  color: white !important;
}

.sing-buttons {
  margin-top: 0;
  padding-top: 0;
  justify-content: space-evenly;
}
</style>
