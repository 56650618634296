import Vue from 'vue'
import VueI18n from 'vue-i18n'

import MX from './Spanish/'
import US from './English/'

Vue.prototype.$languages = [
  { text: 'Español', locale: 'mx', abbreviation: 'ES' },
  { text: 'English', locale: 'us', abbreviation: 'EN' }
]

Vue.prototype.$getLanguageActive = function () {
  return this.$languages.find(lang => lang.locale === this.$i18n.locale)
}
Vue.use(VueI18n)

export default new VueI18n({
  locale: 'mx',
  fallbackLocale: 'mx',
  messages: {
    mx: MX,
    us: US
  }
})
