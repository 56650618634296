<template>
  <div id="TicketsCorps">
    <v-toolbar
      height="45px"
      dark
      elevation="0"
      color="#1e1e1e"
    >
      <v-btn
        v-if="selectedCorp"
        dark
        color="orange"
        @click="selectedCorp = null"
      >
        <v-icon>mdi-arrow-left</v-icon>
        Regresar
      </v-btn>

      <v-spacer />
      <v-text-field
        v-if="!selectedCorp"
        v-model="searchCorp"
        placeholder="Escribe el nombre de una empresa"
        v-bind="attrsSearching"
      />
      <v-toolbar-title v-if="selectedCorp">
        {{ selectedCorp.name }}
      </v-toolbar-title>
    </v-toolbar>

    <div :class="!selectedCorp ? 'content-tickets-corps' : ''">
      <CardsCorps
        v-if="!selectedCorp"
        :corpsList="corpsList"
        :search="searchCorp"
        :loading="loadingCorps"
        :noDataText="`No hay empresas con boletos para ${searchCorp}!`"
        @selectCorp="corp => selectedCorp = corp"
      />
      <v-tabs
        v-if="selectedCorp"
        v-model="tab"
        dark
        grow
        height="45px"
        slider-color="orange"
      >
        <v-tab
          id="tab1"
          key="tab-1"
        >
          <v-icon left>
            mdi-ticket-confirmation-outline
          </v-icon>
          Boletos Disponibles
        </v-tab>

        <v-tab
          id="tab2"
          key="tab-2"
        >
          <v-icon left>
            mdi-cloud-print-outline
          </v-icon>
          boletos generados
        </v-tab>

        <v-tab
          id="tab3"
          key="tab-3"
        >
          <v-icon left>
            mdi-clipboard-check-multiple-outline
          </v-icon>
          Transacciones
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-if="selectedCorp"
        v-model="tab"
        style="background: transparent"
      >
        <v-tab-item
          key="tab-1"
          class="content-tickets-corps-tabs"
        >
          <AvailableTicketsTable
            v-if="tab === 0"
            :selectedCorp="selectedCorp"
            :items="items"
            :loading="loading"
            :nowDate="nowDate"
          />
        </v-tab-item>
        <v-tab-item
          key="tab-2"
          class="content-tickets-corps-tabs"
        >
          <TicketsGeneratedTable
            v-if="tab === 1"
            :selectedCorp="selectedCorp"
            :items="items"
            :loading="loading"
            :nowDate="nowDate"
            @usedHistory="usedHistory"
          />
        </v-tab-item>
        <v-tab-item
          key="tab-3"
          class="content-tickets-corps-tabs"
        >
          <TransactionsCorpTable
            v-if="tab === 2"
            :items="items"
            :loading="loading"
            :nowDate="nowDate"
            @detailOfTransac="detailOfTransac"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <ImpressionHistoryTicketsCorps
      v-model="dialogPrintHistory"
      :transactionPrint="transactionPrint"
      :ticketType="ticketType"
      :transactionPrintData="transactionPrintData"
      :loading="loadingPrintHistory"
      :nowDate="nowDate"
    />

    <ImpressionHistoryTransacCorps
      v-model="dialogDetailsOfTransac"
      :loading="loadingDetailsOfTransac"
      :nowDate="nowDate"
      :itemTransaction="itemTransaction"
      :itemTransactionData="itemTransactionData"
      :selectedCorp="selectedCorp"
    />
  </div>
</template>

<script>
// Components
import AvailableTicketsTable from './AvailableTicketsTable.vue'
import CardsCorps from './CardsCorps.vue'
import ImpressionHistoryTicketsCorps from './ImpressionHistoryTicketsCorps.vue'
import ImpressionHistoryTransacCorps from './ImpressionHistoryTransacCorps.vue'
import TicketsGeneratedTable from './TicketsGeneratedTable.vue'
import TransactionsCorpTable from './TransactionsCorpTable.vue'

export default {
  name: 'TicketsCorps',
  components: { CardsCorps, AvailableTicketsTable, TicketsGeneratedTable, ImpressionHistoryTicketsCorps, TransactionsCorpTable, ImpressionHistoryTransacCorps },
  props: ['activeNow', 'nowDate'],
  data () {
    return {
      selectedCorp: null,
      searchCorp: '',
      attrsSearching: {
        label: '',
        outlined: true,
        dark: true,
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field',
        'hide-details': true
      },
      corpsList: [],
      loadingCorps: false,
      tab: 0,
      loading: false,
      items: [],
      dialogPrintHistory: false,
      loadingPrintHistory: false,
      transactionPrint: {},
      ticketType: {},
      transactionPrintData: [],
      dialogDetailsOfTransac: false,
      loadingDetailsOfTransac: false,
      itemTransaction: {},
      itemTransactionData: []
    }
  },
  watch: {
    activeNow: function (val) {
      if (val) this.getData()
    },
    tab: function () {
      this.getTickets()
    },
    selectedCorp: function (val) {
      if (val) {
        this.tab = 0
        this.getData()
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData: function () {
      if (!this.selectedCorp) this.getCorps()
      else this.getTickets()
    },
    getCorps: function () {
      this.loadingCorps = true
      this.corpsList = []

      const data = { type: 1, corp: 0 }
      this.$root.post('/wholesalers/corpTicketsGob', data)
        .then(response => {
          const data = response?.data
          if (data.success === 'FALSE') return this.$swalAlert('error', null, data.message)

          this.corpsList = data
        }).finally(() => { this.loadingCorps = false })
    },
    getTickets: function () {
      this.loading = true
      const tempTab = this.tab
      const data = { type: 2 + this.tab, corp: this.selectedCorp.idcorp }
      this.items = []

      this.$root.post('/wholesalers/corpTicketsGob', data)
        .then(response => {
          const data = response?.data
          if (data.success === 'FALSE') return this.$swalAlert('error', null, data.message)

          if (tempTab !== this.tab) return

          data.forEach(element => {
            element.data = typeof element.data === 'string' ? JSON.parse(element?.data || {}) : element.data
          })

          this.items = data
        }).finally(() => { this.loading = false })
    },
    usedHistory: function (item, ticketType) {
      this.dialogPrintHistory = true
      this.loadingPrintHistory = true
      this.transactionPrint = item
      this.ticketType = ticketType
      this.transactionPrintData = []
      const data = { type: 2, id: item.id, corp: this.selectedCorp.idcorp, ticketType }

      this.$root
        .post('/wholesalers/historyPrintedTransac', data)
        .then((response) => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          this.transactionPrintData = data
        }).finally(() => { this.loadingPrintHistory = false })
    },
    detailOfTransac: function (item) {
      if (item.f_autorizacion === '') {
        this.$swal({
          icon: 'warning',
          title: 'La Línea de Referencia no ha sido autorizada!'
        })
        return false
      }
      this.dialogDetailsOfTransac = true
      this.loadingDetailsOfTransac = true
      this.itemTransaction = item
      this.itemTransactionData = []
      const data = { type: 3, id: item.id, corp: 0, ticketType: [] }

      this.$root.post('/wholesalers/historyPrintedTransac', data)
        .then((response) => {
          const data = response?.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }

          this.itemTransactionData = data
        }).finally(() => { this.loadingDetailsOfTransac = false })
    }
  }
}
</script>

<style scoped>
.search-text-field {
  max-width: 600px !important;
}

.content-tickets-corps{
  height: calc(100vh - var(--h-navbar) - 45px - 45px);
  overflow-y: auto;
  padding: 20px;
}

.content-tickets-corps-tabs {
  height: calc(100vh - var(--h-navbar) - 45px - 45px - 45px);
  overflow-y: auto;
  padding: 20px;
}
</style>
