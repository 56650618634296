<template>
  <v-card tile>
    <v-toolbar
      dark
      flat
      color="rgb(30, 30, 30)"
    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        class="mr-4 button-generate-report"
        color="pink"
        :loading="loading"
        :disabled="loading"
        @click="$emit('generateReport')"
      >
        <v-icon
          left
          class="icon-button-generate-report"
        >
          mdi-file-edit-outline
        </v-icon>
        <span class="title-button-generate-report">GENERAR</span>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-5">
      <v-row :justify="justify">
        <slot />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TitleReportsCard',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    justify: {
      type: String,
      required: false,
      default: 'center'
    }
  }
}
</script>

<style scoped>
.title-report {
  font-size: 1.75rem;
  justify-content: center;
  font-weight: bold;
}

.container-actions-card{
  padding: 4px !important;
  display: flex;
  justify-content: center;
  gap: 30px;
}

@media (max-width: 580px) {
  .button-generate-report {
    margin-right: 0 !important;
  }

  .icon-button-generate-report {
    margin-right: 0 !important;
    font-size: 20px !important;
  }

  .title-button-generate-report {
    display: none;
  }
}
</style>
