<template>
  <div>
    <LoadingAnimation
      v-if="SeeLoading"
      :textLoading="textLoading"
    />
    <iframe
      id="iframe"
      :src="url"
      class="Myiframe"
    />

    <div
      v-if="Erro404"
      class="Erro404"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
        x="0px"
        y="0px"
        width="206.5px"
        viewBox="0 0 326.5 592"
        style="enable-background:new 0 0 326.5 592;"
        xml:space="preserve"
      >
        <defs />
        <g id="shadow_1_">
          <image
            style="overflow:visible;"
            width="192"
            height="103"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAABnCAYAAABWxU1RAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
            GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAALnZJREFUeNrsfdmSHMeRbSyZtQBd
            BBdrzR0ZJBu7ZpqHmUf+AH8C/8P/wU/wB+6j9CCajck0tDsatVEk2A10VS7h48fdIzKyqroBQtNY
            qAygLXKrLfMc38LDw7mlLW1pS1va0pa2tKUtbWlLW9rSlra0pS1taUtb2tKWtrSlLW1pS1va0pa2
            tKUtbWlLW9rSlra0pS1taUtb2tKWtrSlLe0jan65BR/NvaXlli4EWO7TQpzlwX7A9+A17/X1G77N
            1+8S1LQ8/OW3vsVrzoH093r9s+nIs7/zSz8/e+Df6S2IQws5/nEI4N/+mq9/NqCvrq7Ovtf19fXR
            8S/f8Ov/v9nebre7E4iXl5d0L2nKTibN138P2GkhwMf3O+4B+hzgz+4B9H1g3u9/mJ/7N+0ONzdy
            /Hdv+aO+tX59caHA+8PpNZvNZ3SOOHeRJhPm+VmCfP02oKeFAB/O9z5z/Jk/Ft/PzoB8AviXZ0F9
            +O1N2a8BfXt7O7/ucOD9p7Nv0HX7t7rPq9XmBFzr9dXJse12S2dJ84dzZLmbJCDHGxKDfilk8L8s
            0M+l+jHQaym+3/9fP4GWwf27CdwTqP+Fz73094G57xnw/2faH7uOz1/yPzt31Iahv/eeN017AqK2
            XdOVbcfVi+n8X6bz9xGnJg3IUkjy5ws6R45jYnyTzaznP4sQtBDgYb6jPyvhGe1fnQX7l0WaZyn+
            tJLah8OlPwZ2/4UCd+yeSH8M5hrEsv2ZgX+4KMc/PbpuHAfe/uSNf3SMr6gmxY/1OSHJD/L/mDT1
            9kScKybOikCYTJaaJJkg35kmmRPjP+iYFHNCyAZ9rGTwHwnwT6X8kYTPgM+SPUv1DPZjoAPkkNaQ
            1RncGbAK6s8Y0Lpfg7kGsmzv8jHnkvUiacdNBf5Rty+mX1FfixZiM4HkJpMg0kSIhm7sRH1tfKXb
            sRz7qWzXxInNDYEwOJZJkgmStUr7/USO9foxb//phBR3EuLNyEALAd4a+M/8OSl/DvAAew109J+z
            NAfQC8h36D9zO97HsQzicXx0BOqN35ZzDOSLCbzJQL5Jtp+Sfrcttld2bJxIUV9zRwsh0O1sX0kg
            /e1tuUbIHhToQYjyUs/dZkLo627jnjJhhBjXql0ySW6YDKJRfvihkKMmxpwUV/QnEItJcRch3pAM
            tBDgjYA/SXsA/0TKs0lzDvA12GtpfmFAHx89KoB/hH0GMsCdtjj2mIHN2wzqRwDsOvljMK8TjvH2
            ZlMAjX0q261f4ymvJrDrdesJAUdE8AZqM8wKyMu5Pf+20FN9bSFHh/29O/B+OD536OTYKznWEMgS
            bg+FJJkgIAS0CcgBElyblmiubwop/lZpikwI0RDfwoyaCHFKhg+XCP7DBL7a9V99NZk3d4H+BPC7
            Cz+BnYH+aBCwF6BvGdy8DekNyZ0BDgADpOtV6+egXvl2VYM78Xbr1YTg1xDx9koATTRds8LTbRtf
            g12vfX0L3s+A7v1AXTdJfu+tDwPh2gPO+WjH+kICvL7vIvkAEhg5mEgHu0aPdbQ/YBvk2FPbrRPI
            8Iq3a1JkTXFMiEwGfN5/MSGa71g7MBlqzfAhE8F/DMCHeTM8vfX/fA70n/T+cLsOGfDdeh8A8K2B
            feiacAz0duhDWun2CscM4OMQwwTs1mdQNw36RvYVxK3T41SIIeeNAMTXN/m6cp7KuXtvypBBXpOg
            dwP2ewW/EqCXawTk8jre9iNlAslxJk4Y9HrsB99T30fdZhLUBGFQp9D1dOBjTTOmwKTYz0ixZ1Io
            IVarLikhmAzXajZdP96nuzRD1gofIhH8h2TqnAC/kvYvX74I4+dP/KcM+v32VUDEBaDv1qsACb9m
            oGfAr4YupPUKGiFAoqPPknwcGBEswWODXkEbo24D3NREAa+Cn88zYGMa+XwsQMY1ET3hGgZ20+hx
            ysDHddFTzNt8vJCgKWQ4uTEZ9AzkoZb0I44Pcn4o1zCox5GEBLw9mKbwAnhvvgEMuWDHBgE8Xu+H
            UTTHwOczQWIcE8gRGfyeTaoBJGAN0QxjqknRtUMCIbLptDpsEjTE7eZRgnbYbi9SrRmOtcIdRHhv
            JPAfgtR/9kyjOWeBv3sRIO1vb2/CYauSHqDvu32AlO9Xh7DqmzC0/FcBvmFpPjLIxzGEtm1h9rD5
            y08Skp4fLaQ34zrUAE9xxCNXYMcIDRGEDAZmBbyBX/aDZzQzfEACPLrglQgk1wjYKxLo46VKIzQ5
            mFrA7yqA1+AfBejewD3yf+YotuVYKtfqdaNK/VG3+Vclee2ox1Pgm8AaQwgyQBvwPt+KcUwM5shE
            GGD+pJoUw9Am9CBED23BvkXXrFKzGtKKCQGTaXXo0u16myITYcOE+LH9iTbXuwQiXH16k+A45/GG
            iQhlXOG9kMC/P/Dzj37GUr8GPps6T5+eB/72cBsAfEj6GvQwZ4aGkd25mAHfMNJZKwQcFjkWRn7E
            3DPAAfoUIdvYnGFDWMBvYOdHzcdSALAF5PW2XeOMEAHAJiOBSNtpX8HNJBBO5P0K/C5rgJgDpXZT
            jknAoE2J7KwRQME+jtW+ESNlTQHACxnGcg1+GMgBEYBzyXqAHwQJCS9nk2nkOwLNMDYJBMmkQI99
            NiNHJQSTgYnQSz+mrmcydEPqVn1qWSvATIJW2NweChF2uy/Sd9v/JiWCagMZfX6PJPAfDPhZ6l/+
            eBFg4+92mzABvw2w65tbH4dVw6C+je3QKOgDg35A3wHHDDXHxowHzhnkQwgDC3ecxiMOXkgQjAyJ
            97FBuWeAE95EegV+MAIAwHlbQM5WNgVIeUYlCVq9C6oJlBCqBYDvYPszPyATIsY8UHBkAhkRHKQ8
            A3zMxxTMKZ9nheTZtca5kb8ZyCIk8vh4SjVp+CelTATRANAESe5I0tNQDMH6TAp+w+j4hikBYoNI
            cGLAs7Jgggy807ZuBBnatB57JsKKFQ7MpM34eDxAO0Ar3DIRNocEP+Hx9ZMkPgKbRU+e/IZJ8Ucj
            QTGJ3ikJ/PsA/zMGf23yXF5ehSz1dy83Yc/AX738KULiA/grBn4XGS4s5ftwYEh7+RNw947FsY8M
            gBiGaRuMkGMAO3rdkGMkREiq+ANwKwcE+CAGgM1QCQC152sE0NAXzkycYKaPM8AH9Cx4FXl8zglB
            4I4Gk/wi9UPWAtHVmqBIfkU6uWTHINUV6fzx2Hcq6flNlCj8FnBcuE9yvV4n1wh3lDT8XRLIIr8Q
            V/CLkgFfjEKQg3tsQXvwb0AUwMAfpGfcJ74vI7ZZIzIB8F40ggzQCik5pgRvr3AtH+9ZS6zTKEQ4
            sGZ4ROOatQF8hOvr/cws4uefSh7S83dLgndJgBPwv3jxqwCT5/p6F3a7a5b6W5H6Kzazm1sXD+gN
            +AB851IDqc8PsKlBz8+4gfRnFR/9yOd5G6Bn+5d5gNfyq3GMHy96ATxkOgQ74AsUeyUCjGRARkIp
            Iv4hQnnfBZXwALZohYSXAqcKbmgMIYoziR9E0gcRw9nk8aYB3B1OcH7YBnASAhjYSTUAVWA3kkDy
            Qyv4igSyj5cnoVzCe+NL8y1AOAzfLOk+uMK3gl2AkDUBsfEPcjDYQQb+5FGIQrrfOD/IdqQRpAD4
            CccY+OihFVhLDiACNMKwYS3BPRzmrnNjrQ1OSPCONYF/l+CvzZ45+Dfh+vqHuGXw3zYhIsiCsEzs
            fDy4VeMr4COQ6H2jPe8D9LI94LgCH+M3hQTJRQG7betxCD5vYGckONYl2BdJDpLwFgHBsDxMA0Cu
            ChEEimbyBAE/iCJXZtvejkOMeyOD2f96PriTSJA3yV8euJg5SZSJqgrdhxbQY+poOAM+BLaaPNAS
            GIpAL986Cd65x2tEpzHwhQB4URBNwT3catIeUp/VkZBCQK9/mRAIumKfd6UH6PnTBvTyR4P0q3UY
            lByBrSQ3rkEG9uehDR73abybBCc+wYMRoHlnLvCRzf/06dUE/ga5WjG+9DAzoWQ3DQHcDZubrmtc
            Cm0GPt//lm8H94ShyibgOhcRkefzIIURwIEwMIl4n71W8UmxDRIwJxwEHX+WhxgH2PFPrBpSyW7a
            wMAfnEh2J+YNDByFlzq7QG85p9aLHCDBYuUE63sYdsM8SQZyPdv2AnmSSBBsHKMNlX/eCAIqKkfM
            PHKyby/mDkDm/wEch68QkvgGXi4Z+SskXOOCWFXcsw3GbjCG7rxsOwx9j2Kb8VNh+gHYDHw/MI8G
            fsEgsVeXBn4NvO/e9wOfiz05xjzv8e3Ea8LWdYPIEL4H/KjdS/4wNnfd9e6Fu/zxibv67U26/uu/
            8sf9EVhBoh2ZwKj7j0oDzKT/V199E2qbfwb+l4xg7l+xGRn8KJKd/7fUDgz22K54hzHM277l59Qy
            hLUnJQZLxhbpXl628WcawKnExz4kOt971QCiFdTk8YIKXGegFlNZ7X/RBiQAD6QngxrnALDAVcBu
            YRvxC3wmQ7bxSR/66f32R/eeTlQ+kYJ78hFU3ai5g2sU6NksUuUgdhhsH32dSHsv5o/4A2LyiP1k
            mgA+gUh2OMbSs7ZVqc/HRQMEIYFIe5H+Ang/8E3rHbYZ+Ax3Bj57ZD7xX+zZIJJtORaant+arwUZ
            uiFhCNvFoRuPNMHVTXry5K9JQqTfION0Nk5AH6MG8Fn6uyvN4UG05/Djpd/tQIJbkfz75hMWyQp+
            AD+1fbsKrk0DY78Z24HBz7bmilXySiT/6FYM4ZbFOhPBM/jxx6TBduTflBz7Y0HBj9EsfiL8zFn6
            E5PCB9USYs1Hl+1/GPkiliVqE5xGb4IqCJg+glhv13m1Q/TYhGYRu97rruGexFC3m1GB/nhEuIBc
            kK3Xq90/MURozD/F9I1phUQm/cu+EkE/JJhGEOkvx/QPhAjai1aAxBefWyR/6UPZH7yAX3vsM8kA
            6t6PqY8hMuCpZ6ei45dCG0fEY0fYiaMDzfgugyMr/kad/PpaE+y7PcEkvrpCyst/OPcVkolm5s+D
            aAH/8NL/mYzwZrv/8vIiINqzfRFj2waYPWzGbAv4Vwx+yVCIrCmTZ9C7FUvsFYvsVRppzRp6FVny
            EwjhmQhMggDNEKjlB9Moqalh9czvpyaRBiRZypMMS4kmMCDHyQGW3isxIN3N3hcSZBPHNIQjM3e8
            Ob0G7mICqXHuHJnXmzWFOQDurpwgs/Nd5k1WC3rcmz+g4Fb14LNpZE4ymZawsQRSsx+UkS04w0kJ
            hpE5GVRArGjMvgGIQDLMpuAnMYPU7pcROzZ1lARBAM/vBinf8Yt7freOdWSXrGcnuOPHcmCN0g2M
            emiFEHGONcJhYPMoslZpBjjGu50bDwc3wh948lcNj1Za4MF8gebhpb9zWfoPbPd3141HqPPlNrAB
            OarNnwD+oYV0z+BnZbxODH6++Wt+MGtKcc0PZBW4x3E2X1f8CSsQISHphv9AABZzbSYBIkUggTN/
            gMTOFzJoL2CWSKVIf5PowYs9IZrCT1EgdXLNJBLg6yCAxnlcFe2RQKTL1MiWf9YC9yXEKZLtVaYF
            1B/ImoCKSSSmj3kUvjoGS0dGx3RkTl+Hc0nNJvEahBSUSQBzCE4CxpKVgZMGcBhi8Gb+YJTN9aoB
            EvqeL2Z7P3V8zzrkRsizZAGD++tF22K8jb0C9gBYx4NK8L/lRiLyjO8mqRS3iW6fjPT09td0JROY
            rhk7JbX6wXwB/9DS37H0//KM9A/hgtXk0NCmbwlmz+BX7AesQIAQGPyege/SJvm4FhKMtOE7t/Y4
            ntyaFPxrFtvwB1ivevUHxCQyAiCoDS0gkaIUndrwUQwJRW4wkRyMFACrmTga2THb3xcn2JnpQ24C
            uB2bAO6PbH5zev00Gnx0r6hyDcwRTrVZVAbGxGIpPsAkGX1+hZtMIRuBmPsCbh4NEo1Aol7EFBI/
            QKwoZF8oETT3wg0B0p+Ql6fA5+tZ+vuO3501gEN/4DdmiZ8OfHv22Oa33rNKPbBQO7D6PYyJz0fW
            CIPr2CVg/6Dv+RH38Ac+de2AMYIXvxrGd6UFHjwKBFPu6oz0j27PCraN285HRHv4RjZ821s2Uti0
            aUTys6/K0j9tGDwbls8bFtFrFlMbflhrfoRMBjWD+GavxAwi1gIBEp81AcyhpKFRifZAKsEg1m0F
            v4I8mN1uDq6QoUR2guUZOPUDDGuFEGXfRJOcUDJQZfJ781lrgUPnfQAiV95i/sD1DST4lDVENpnU
            9FEZaX5DOW+EMGcZEVNnYwKk15oGUIeYz8PsgZ8tESE+Z5EgktAmv5Ztf4IfgNzUjkT7EgIUDYSL
            81nD4p4kIX5C/Bl0QyCpkaE6nMBgDOggJFyFPt32h9B/IVrAQwuwWfTgI8EPSwAzf9D+hf/97Yv/
            75sX0W8Toi+PGNJd6AYf257vT9AwZsP2/UDjCtEymD6MWTZ9QALPwOc+ce9owzdtrSYQ8XXsK3hi
            k0icYo0CsQYgDIyJ7a9+gC+mj6CraAAJg05hTttWYCcyg6cg0h9J/0naC8wpR4C8Pw33HIGesu9M
            NBNs2bipCOBzZMhI4Cq2ZJD7iTiZD7XPYH6BRoOc+AXmC6i/kWwkYlRfAOAn8Q8kAsRECJkExGYP
            TCHvkDnYIBXQw5QVoSLDymJHqnwhM7dGxGP5IHvMLo7s043I3+rbyL7GgY3VdRg3Tfq8cx6FCJAX
            JhOgxAzCmMDX/mPyAWbTFg+/3frDj2xguid+2MEDHVi+HIIb2tA2iIkR3w/cQAxwYXQd0tyvGKor
            /slrlkT8x+ZQ8Bu+0UIGTK8SEwj+gRcfANe2DF91hJNJJFekkkR+nM9mj5o44vRqZMgbiH0FbG8i
            fe7sZoeWSoBTNUAV369sfX90W/zpXfLu3KinP1H5PqsRopnbMGmCjH4doqZsUqk2KNdKPCgZYZJd
            nwTw0kNCQwOYP+Bd5QSzKeQRZXN2f2UgMvqcBiK3ImFQgWScgV0AGXPwiAUha7thsrA3EOIwNpGf
            P/thB3YHxj1yrUJPMXUdaZWOv37JFvQf/Tfu9w8WDWoeCvgS/cHAl+0hye1TGOwsG0YGf0otW/ox
            DB37QIEJIANWiPeH1sKZGPCSaA8LDkywYnMIcwpVAyTsO8/gD5ivyGYQrsn2P5tTrgyKyUCYjugC
            6BRJcR3s+wZf2fdGDl9Fa2xgy/bJ+eLP+vKj/YmpMwM3+Tf0ve6uqDCZNzNVQj5HTT3N1UyJBLkS
            JTLfgeZhUyOCE/BrL1oiiQ+QtUKJ/ogWiCrxMVtHQ8XIuQgmBtTRxkygwCY/3kNHimFG6ZQG9omR
            Chi6yL5AiPwUab/hb/hKZuP1/eif3nbvxAx6cB9ARn1vQYSdZr4PPf9I/rGbHlMXETcIo2/4RiaT
            JgraoANakOg2AGZSnk0dmEesMVaiJdhXEJIwt/j+t4gCeRsIMzKwpCdzfHW019BsMX1vUovCFMMv
            oBXnNogSqEdzZ6A+dWr9WQH+cwMOdLo7mUb+rFPoqVhKZvf47HmUsGoeJbZBNpgnZiaRDk7A6rNe
            yKEDYholQ/g4mpkYNMLqzGKS4WWV+F6iRQx69g28zGOzoISPkk6HoXhMx8Es5RajaCz6t6MPzSf8
            KTfqcnf/4vJEfzGln39sBKjsfzRMX2yaKFk5CbOr0spmdCTcFQYlMK8AZSgicQ1hyshWYzQbs9Eb
            qTdTBsDkBuNPJL86Y6wFyFIhnMb5Vfp7s1GLlJdQZShSXh3YaXCLCtQ9OXc32OsRrCNzh05s/mMb
            6A7E+1OpN9n988GyU0f51G+Y/AA3jR2YWWS2E5k/IREi8w9GHTDzfroH2bGnvGkDa2LmtNIjJYI0
            XJpMG8uzwwg8EiqCVzMUbMCzlrnY8DBYKLKCGf2FR8yjLkj2gPg/Skh5yFaqp30i9XFkbm4abZZV
            U6YXpjBqejJvSaKahi3NhHEaX7ZjEtK0mLOAXVRzHtHNr7NBMLXzjQi4NsXsCJOjWN5bt4OFQy08
            SvNwqUaEgn1WjhCFybnWa3weV8h/3nyKO/7l87PXTKPN0+eXz5k+o/5eJ3+q8WJx+CHNfQn9mo8E
            GY5tX+6fJ1c0srO0kiJY/HQ+Pw/9jTZpWTStvGfQDFqSSUSYXNSQzsKT8fDU+q3bSCWOUprGKnwg
            a2AqcPb1R0qAf5s26/o70jZ1JoxOM3Q2pTDn2Vto3PJvNMOSLL+GdPZJdkqzyWJAoOLAzmL1NE9X
            yJJ/stv9cWx/svknKe8tVGPXzJLhqmuOw57ZSXzd38lYwfz9aHLUzbv1tSab/dHseyjVyE1Svdwj
            8jn6lX/3lNpNNslHwsiqPYls4E/9Ih08l2eig4Ahp4m7kjU7Pdt5wYC6dtJ4UjryyweF58MT4A/3
            OQhH+4OhPU/1G7ONMc2QmtIAnEVDNCO5sjEyLsncUZrlmlWjplWcUa+jmSFy18CLmReVSeLPXkOn
            cf4qLn/vn7/LGT7+zOq7+FMziE6+m40JzCKsU8oFzb+zr28GlbCrhVwF/1RFbisLrp7Ukwfo/OSU
            q1F8FhLxTG3Uj9oJLu0vqCjWsrfzitLN2oVtpAOy1pxWMygA0el8iMfJTCZ4x2RpvDqjSaIVMjgj
            gzQ6oJN0SrhX50yjFiblbJAqR0DEbCAzhjWkY3F9TW+YaYMiPWkmfX0JwFMlkalkgE4vrs+/3UCm
            r2lJE8DoTBoplQt0rKyQhIoTbJeR/dyUQZ3HApzlC7k8OmxpEToq7EcLi8pIsQyqUX4OXhPq4DQn
            SX0bZUC55Bh5maRf0rZNN+ATpKCXJKyTzX/WhjkCu8s/klZn/cgJoEVZB2V55F8qRZkO3G3SoBm3
            cvNl2JBvVcwTMfyUjZhj0aQjkYOz0d3svFpuvmXPTPo72+vmPySx+/V1SS2gKgJkZHBlZPfIrPHz
            wS2apTv44/jnXfGfnxMGrQNLdBwRckUTzqS9ymZfpUj4aVBsli6h+4nMCTZyjFTCoUFBLwlxLifE
            9fosMBqM0KgmyeWJMrovyXRDkHkHXmeNhaSkGvG+TRpTT02LnBRPqhG0cl5EbSE3lXt8/oA+wMMR
            4LnmQaAMxnZ7Q9fXWphVS3awG3Q4UGzb1Pc9xeBSwGC5TwJ6JzOPFPAM0Z5Q+4kk0U0T3FzgV8h9
            K4OyFpgkCSvllN9qBJjEWZMpe0HnNEpkyOzkPCg25fcbZo7zempiTOAvYwP+rkxP/3PjoHQvKfyx
            eUN+HhmapVCXhDifeaJAzy6Eppp6HQvwNhbgLTVa+TNoajSDPCEdgoGP8Ca5jnukQ3R82zoQg+VK
            J1miQPTI1wZ9bSiTa5gQEc8BZGhQliWh9oy7JalO59MN9U9eIA7qHlLyPxQBJlNB6sh/41ED5lv3
            K3fJrG7Z7/mR8RzdHikkDPE+rVoMB7SsOqWEsmQeskzBTF/JOHReskc6mc1lERlNZrSES68DO05H
            MXGDW0zBy6E3b1EOGwCbIjsWBSG1kcIU+vR+8o2qsKibRn99cQBykhv5efTxLil/J0FOc4JOcU9n
            Aqp1LlBlAim484jwLJnO5XRoZ+dVWNjgWNKJMVIjzMYASFOjddrj6DQJDpNgMtAPSIZjyXPgaw7B
            KSn4XTtJ/Mf1KfSquVVb6DxjTKb3UmIl9imF1Zo/8xWToKX2+4bcrpsL04/MBMKcTv/N5TNCJqhU
            Ev7EOVQM276IKYSLlNIhuW3LEsCnppVizCjuN3iMhmNCBaZkxyDpC4k0bq+5B8Gmm7s8y0mH6kkT
            tZCnTjIeIGHNJof/YC4ZGSQFmnIKRAG8mkI5UqPAl3RpN0VcNC1u0gZ5hGlm9/h53k/OoSNPZ4T5
            6bh+PaqQyZBm5JhNmKE8GuymqZPZUc3xNWVosfdz6oOZTskXIlAeDR7Ndx3FBGLNbPH9UaS/mjwd
            coL4Mw8irj3AT3t+wwO/FNV9UYGXiUBKCE9dZIGGmWD8jWRSPcDfDZTGdsPqYM+qoaVP+Q/zmb/b
            DvRE6ovuPmInmJm7+2pH+73zKJH3xfc7ajaI8KAC8aOU+kOidSMFm6kNkPyYZIE08Thq6QUBZnA6
            CcoQoxO+Nb13lNETj2QtSH4MgqXGYzKMaQAZFDPgS3Icv6dMkFcTKOTkN7KYuo4LuJL3P4U8c4h+
            Motoiluekfx+TgR/3geg14z+epvwe3aAy6IxibLDO80gU4lONg8gk8BPA15Tn6aMUFf1frRip4Nm
            hKqAUXPI95ISjUkwIv0d4hldcmHvA4OfewIZnN/z3RWtwPs9q/W+cWNPKM6IChJskq5WrGH2zAdi
            EqSb9Lcnr+hz92ta/5l/z+avthjH8werEPFOnGBUATt89ytaXaoZxP5AinFIfscSBZal64ZVx7cu
            akWGEblSCBpkx1RHhFC3KqfzIoNRctNJHLHED8LmA9i8AJK5ASFK/UEthSIT4M0sKmkRNqBTzKBp
            ZliZHzDF+auYP9mwMk2EmOf+O7pjnYM3qRBdT4+ko1SHkvSWp8PQLOHNIj7m7Jd5AToRXjNA9Vyd
            /0NTHpAmruk8YckHkoDDmIMPTus46lwAp7PB+Cvw0/MHfinmOrL0h43rD1FIEPaM9UMEUfi6vok9
            351htaahH2gYUj+m1ZAQMnrcq/nzp92f3GO2/6XM+vOHAf5DEqDyA54Tm0FOzKD1hVQNvr7epc3G
            +5/8mJqObT/XjFvkyVr1ABYToYnwCNQ+QVDISnuIY0aobcXAT5h8TZKSiyS4TtMgRPJrBQnexqhl
            sqQ4GXnXKFCwKZKBbEaYRoRQCkUrzWp6tBbe9FXJk1ozeM0b0BDqlBpXzKJaK9AsOe4kO/Tc8y1R
            T+9nDu1EjjLhpUR1jo7liS9m7pBNjiEFvEaBJTyp0TdvER9zfqmYQYhR65TIoNuw46GpWWxjPgBm
            him4PSa+sLnDD/PAd5oJEQ4GfpkQg7nDbOT2LNqYSCsmwTDSS7H9pTjX9ZMxPU5PqPluoM2Thzd/
            HloDKBHMDMJE5+9YC1yyFviRtcDF7WoMoWMkDTbhhOHeswvQsvfLt79BupBUQxsSy54k8yowvTql
            Qep8sxoOkpcOR6vkAsmEeB90Yjy0QMjVIcjHsq11f2L2BfK0yEyEnBznpxHfYBOCfS6VYkOlflYl
            YrJaLOOAcuqmDxOKX0uADPrk6xOGfxtc0nENyrMw8xisDc8KiGsnN0eDksbwVUNMyW9kEp9sfnCZ
            ByBOcCjhZznWk4WkRQM4zAbzYgp5mRXGz4O1QTNSxwq7Y/V7GCMf5/0hUhfGtvd4fqz1xxRG2qLM
            6SfjbnBp+H5P/7W7osfrS9rtbo8rQzyIFngHJtBcC4gvwFpgu8NcAXaEouUmCxlWqJwhAZ8xDVLN
            p6GYtADmKNXFImrBJTi7CfHTDqVRWAq1jGa+55gXzC9Js2xQzXUhy2REDlDSqZE6RZIku9Ekfy6B
            YmMLeS6wVouwLOJgEXafC1xNk+bJyOyr+QB64VQbyN8xKObrka2qRmiqsjhshKNKZzaproNZxQeQ
            ag9yjc37LdEfraKo5k6QijCkYWMvocqkINeZYeL06uT4aU6wlDZJMi2SkN+fQs83i8HOJEhOI0Mj
            aQTIs45nqY/zfWTp3zc9hY7fo2Xw8xlR9CiNckh/e7ynz3e/TvurTdqcOr8fXWGseVGjSgvAF9g9
            /SJdX3/vYAq9bMO4ehURFXYo8ey7lQopzPhFnT6oZ8K9iiNbP6g0hjnAPRNB5w3AxMG8AVhOXmsC
            YZQFyW4wg0jqgzotizJNjglBE+K415IoTmc0+VIehTQqZAZ9jvSEEhHyzurSZp9AxxSO5grbGJxa
            MyHcXRbxOAVhmt8b3DTOldOcRbjbpBc/SXcZ7iDb18nvNpprg12a/x9sHjBpjUQd+U0S4RHgB6sS
            ASdVpH+y8igWysw9ibSinpgEEEiI4LHhxGapRHv6yGY+SykpmxLgMDd8LYM/OfZ6XRy2bPr3/aCm
            z/WT9N2n/01PNr+hM3WBPiof4E4t8JVs/yZ9t/3PcHm4oOvHLxJqwrx8FBxIIAmyWMiBn+lhwHRf
            VDEeUVoPlb2tJAcmVLg+YeqjrFUE0EeN+pASIFhZRA2nessatcxQylmlk/SXMYHSqxOMzAubS+D1
            WiksqglhvtQC8kYUXyeaGe39lBZBMgCRj997q6zmT7CRvGkWWHFo6zwgI4KFM608ok2GUQI4X2L8
            WfJbaFQkP1IWpJSimD8JnleuETSKyUk2I0xGcrM/EAYWWTo9EiRg6Y6xG9UKKJTFkp79WxTIis3A
            dm3sYfOr2QPJn8Gfxpvtbfrk+rKUR0Tqg65mj3Gk5w8q/X/u4OTf8/6zwrhXV5chl0PPFaFZE0SU
            Qs8V4lAUV4rgoi4oA7cTgMdWjvUIcTZy3Fk1uNHsfWeTYbQCXMR7lGK5QeqAWo1QSdLV2qAkBY89
            Soh6TcXWOkHzeqAIz1ZZjZI2bJWgbZL8uTqgpPHW+ZzHe7SAP0qsS47qpDMb4Ep5NFgK4EptUCuS
            i0J3UlI9aXHcXBWurhFaaoOW4rheyiLmfRTHLZXhvNTZkm2KsoCT1QaV0VzRBo04x8FSIQbtxVTS
            SnAbFMq1IrkYB7g9dOmRgR/rBny/u06Pry5TWUHmHdQDetcEuJ8EL19ImcT9fh222zbcNtdSILfZ
            M/CjFyLEoEVwexBiQJ0sP6sSXRfHVQJopWgMNkq+OgCL4gRAcYJDnErOel0VWnuW/pkMkjWBnDyr
            Dm1VJObVoHU+7FQJ+rgKNIn542wxjSrE6WcZlLM7h1LNU0i0rhxNlDMyq4rRRgRxhiU8BUkO7ILz
            iUo5xCTBX4mqoUa8Rte0GK4mG8pSIlIdOleFBtiDlUbHAFZjZRIlX7fxQxQyMNiT9rkgbov9TRzY
            3hk3o5P1AlD+BGXSb5+MI8we+IQz8MuqMb+c6tBnSDAtiVST4HhhjNUrH9frJuzjy4gZ1KgU3QXM
            I2W92msPiS8EGJxGeFBEHStmSKl0JUFIvqwPgBlJWBGAZF2Aan0AWxdAioSTXCF1QWdrAnhgmKwy
            tLdkAx0rsLUAtPJPXgvAh9lqMKGqB6QLalSjxNn2SfMU5LwQxpR5ofU+7TiZMpA+5crQkOJWJj1o
            9tlUF9QqQcv1lFIulitao1orAFWgZfEMgByLR6FcuvQ0pmqNAABdCNHo8bxOAIA/rlASXStCA/gw
            efKKMcdVoefgf3eVod8VAe4lwfX1v/oXL/4z1EsjySIZog1eBV0kgz2q1SE0hxCHNmK1F1sZRhfL
            0IVdQhiECEPEgBpWhwkCez4JTSBrYnjAXvq8MkwSQoxBiXG8MowunAFQw2aIQYONUmf3eEUYK5de
            VocRWW1GmWqHs4Nglot0NuEt5XSI0Qq0WGSoXgxjtmqMLYJRVosRc2gyjcpKMfilWC9MpICIfFsl
            Ju/jPyxILJAhnGDwt6OsrQNqGOixSowsjLEiWSVmxdtYPwzAx3JJwxZrAui6YZD6N9sLNnn2Z5dK
            eh/gf5cEuNccumuZpF5WhGQi7JkI3SpAI3RMBKwECR8Bi+G1bByBDE3TB2iG2fpgWAsMjw09P93R
            9xGL4MkqMCkvlTTK4jGy6J0sFSfDYzLhW5dOygvhxWrGWvDn1gQLeT2w2VpgUaqNxiqFbU4AdTTO
            3C3JP1aQj7P1w4QEKa8HMK0TlsajbVsKCcu5CP3LWmGJQrVUUr0kEhYCwyqS0QB+bp2wcWhSBn3D
            hk69NBJWkNSlkTYM/D7lVWGQBzY3eT4jVH7DT1Kb//2sE/aeV4n8erZuAKa/1dpgRgTTCHkt4LxY
            Hha8XrUdS/8oZDheIRKE0KVQmQB8IK8UqaSYrw6ZbME8OV6tDBnLtgI+lumb81UhZZ2xk9Ug4cyc
            Lojhzi2VekQEWftr0GVT6/N5idTZypEAd1kl0tYNs1UigyyaN0pNkmmRPKwUGbByfBpkcTwF+zBI
            JQIDfiOVzAF4ydpksA/VSpFNPySsJQxpv1r1DPyNrAmGNYSPgZ/XDj6V+holPJvx+o4B+Z5IgHZm
            qdRqxci8ODZMI8wrzsul1mS4b41gLIY9soaApsAi2OM4kSIvfp2389rAmLgty6jKusCostWWtYAV
            6NO6wDVZ8GtiBX7pIx1FgcI9UaA0J8F4vHaw+eF2To7bMZlrMep2WS518LY+sIK8rBc8DAz0WJZH
            1cW0FexYbT42Teo79FhAuyMB/Jm1glsGfXz1itZs5lw3N7S5fZTyKvJ3Af99S/33TYA7SIAb8fuz
            RECVsKe3/8THvhdtkBfMBhmG3YWHifS6VeKhIdYA+9HC2XmF+BbrwrUwg5qzq8MDzChZp75oVcni
            aFX40xXhlTjNjADpHgKEAoChjAbr/Nl6FfkM6nr1+HOLYYurHTHxZKAMchzXFeN7mEYEyd6FDpqC
            Mthl+4AV5Fdpj0Wxb7FCvK4HfG61+OMFsu8H/vuV+h8CAX4WEWAa7fc/CBkG1gr/XGmFseuYDJ8Y
            GVBsS1eQByHGR4NUoXgEQnCfTaZMisQkQEWCTAxUJUtSn133BfxSuWAt4M4kkSqMSYsr4/wK5bkk
            585NlQ6OtIBxAZl75dy5BnDKVJC+JoWaQBnQALlcIhWlwmQWVeDW6xTguD50SMQxkMt+T1gBXvf5
            OEt2mDPBVoCPt2z48fYrbBvgMaPvmv+wIjy2s6Q/Br37lu/Y+sMG/odCgNcSAVWR4CPgSNYK2IbD
            PNwqGbCfCYHFtfusHVBi47PP3M6256RAD2IwqLdIcHwstWmEHAD+elVKdmB/jdjKZuNSVcpDwb5x
            0CQz8FsvA9VHDSQSFtzVGP29j6eFsTDPR3I4wkSCPV+LRV15wxuY8zWHri/kOAa5agWV6pifHRnk
            kOxivgngX8kxlfBzwK8Y8H/B7yigf0yI6NSgx/u8IfDfK/g/JALcQwT1EbS7gwzFTJp8BpGQTAjn
            /sl9DnIckQL1Zy5KnaJPXCaGANjMKGyPo4IbJEl2jF1F/ygDfYt+dVLfZp0mM2c6vpl1Z9t+vpFB
            Lb8zTOZRAfuhK6CfwK0TyjPAM7DnIMe1P8l2luzO/eBgzkiOzJGEx7EMeOf+5LJ5Iz9n8xlh6msB
            fXFui43/wQH/QyXAXd/rtWQQyJwhBAqzo8zenBSoPqZ+hNjaVowJJpRzSo5Pq+OFJOh3eV+Jgj6T
            pRDmwoBfFwGT9tiOj3ebQDED/uXRcQbsjW7XoL6xg3LejmFxFay6Uva53Vi9HdjsjHMBeGPHAPQr
            d4WlOmdgFyKzWYP+PsDfA/oPFvgfOgHegAjnyXCOENlkQq+kAEkm00ntaSaDlW8EOS7zseyQZpKI
            BsHWZ7MqZhfVdibMfP/tWgawtp9m+zdVESkBNiT39XRsDnA0BTlqNGmZmlOgZ7Bnk0YBPzdr0KaV
            3T8+0H9MBHjdd62Omc9whhA1KbJDXWsKNHS3R+QoZrmR5JQonc+lOz6tyHJMmnPt3Lnmnqpox+cm
            UFfARqvAXQP8GORbBvm32KiAXoP9GPA6N9dVFRqevwnI6WME1cf6nY+OV4QwUkjB6jOaIhNDtcYP
            J1ojE8RVJCnX3EOWu1p/hiw1sF/3+hrU54Cdt7/N5/98Mbs+mzDngH4e7CcS/j5w0y8BTL+U33Dm
            3FxTVN2MHKcEOU8SaVYA+HCjhPnda77wMYnqVgP4uH1bg/7CQP2HY2DndjfAswlTMD6ru3MW7K8D
            Nv0SwfNL/U13nM8PfU6Oo82zRLlPo9wZ7Dkm0QmAz7X7QV0DuxgqJxv/TvPf+1Zgpn8UsPwj/s57
            rj0GzevJ4t6QPPeB+Cyg7wT2Xd/1rcFLCzCW3/6/QJb/jfb1QwKVFhAs7Zd8b2h5pAsBfmn3cgH1
            0pa2tKUtbWlLW9rSlra0pS1taUtb2tKWtrSlLW1pS1va0pa2tKUtbWlLW9rSlra0pS1taUtb2tKW
            trSlLW1pS1va0pb2j9T+R4ABAOgiAP26ckJ4AAAAAElFTkSuQmCC"
            transform="matrix(1 0 0 1 66.2048 489)"
          />
        </g>
        <g id="robot_1_">
          <path
            class="st0"
            d="M152.7,89.2h-0.1v21.4h19.6V89.8c6.8-3.4,11.4-10.4,11.4-18.5c0-11.5-9.2-20.8-20.6-20.8
                    c-11.4,0-20.6,9.3-20.6,20.8C142.4,79,146.6,85.6,152.7,89.2z"
          />
          <path
            class="st0"
            d="M162.2,466c23.3,0,43.1-14.9,50.6-35.8H111.5C119.1,451.1,138.9,466,162.2,466z"
          />
          <g>
            <path
              class="st0"
              d="M159.1,356c1,0.9,2.4,1.3,4.2,1.3c1.8,0,3.2-0.6,4.5-1.9c1.2-1.2,1.8-2.7,1.8-4.4v-21.4l-12.5,22.4
                        C157.4,353.8,158.1,355.1,159.1,356z"
            />
            <polygon
              class="st0"
              points="213.7,338.4 213.7,314.8 194.8,338.4"
            />
            <path
              class="st0"
              d="M163.3,313.3c-1.7,0-3.2,0.6-4.5,1.8s-1.9,2.7-1.9,4.4V341l12.5-22.5C168.6,315,166.6,313.3,163.3,313.3z"
            />
            <path
              class="st0"
              d="M283.5,262.5v-42.8c0,0-4.2-78-68.6-102.3c-19.1-7.2-36.6-10.1-51.7-10.7c-15.1,0.5-32.6,3.5-51.7,10.7
                        C47.2,141.7,43,219.7,43,219.7v42.8l0,65.7v20.6c-0.1,1.7-0.3,3.3-0.3,5c0,1.8,0.1,3.6,0.3,5.4v0.5h0c2.7,34.3,29.3,61.7,63,65.4
                        l0,0.5h57.3h57.3l0-0.5c33.7-3.7,60.3-31.2,63-65.4h0v-0.5c0.1-1.8,0.3-3.6,0.3-5.4c0-1.7-0.1-3.4-0.3-5v-20.6L283.5,262.5z
                        M131.8,344.8h-6.3v18.9h-12.6v-18.9H87.6v-6.3l25.2-31.5h12.6v31.5h6.3V344.8z M182.2,351.1c0,3.4-1.2,6.4-3.7,8.9
                        s-5.4,3.7-8.9,3.7H157c-3.4,0-6.4-1.2-8.9-3.7c-2.5-2.5-3.7-5.4-3.7-8.9v-31.6c0-3.4,1.2-6.4,3.7-8.9c2.5-2.5,5.4-3.7,8.9-3.7
                        h12.6c3.4,0,6.4,1.2,8.9,3.7s3.7,5.4,3.7,8.9V351.1z M232.6,344.8h-6.3v18.9h-12.6v-18.9h-25.2v-6.3l25.2-31.5h12.6v31.5h6.3
                        V344.8z M231,242.4c-7.7,2.5-24,1.2-31.4,0c-6.8-1.2-65.8-1.2-72.6,0c-7.4,1.2-23.7,2.5-31.4,0c0,0-22.2-1.9-22.2-30.5
                        c0,0,0-31.7,22.2-51c21.5-18.7,53.9-22.8,66.6-23v0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0v0
                        c12.7,0.2,45,4.3,66.6,23c22.2,19.3,22.2,51,22.2,51C253.2,240.6,231,242.4,231,242.4z"
            />
            <polygon
              class="st0"
              points="112.9,338.4 112.9,314.8 94,338.4"
            />
          </g>
          <g id="light_1_">
            <image
              style="overflow:visible;opacity:0.83;"
              width="142"
              height="143"
              xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI4AAACPCAYAAAAsoeOtAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
            GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAADKhJREFUeNrsnQuP66oOhU2m//8f
            Tzi6umdLPWxsr2VMQlqQqj4mk+bxdfkBGJHddtttt91222233Xbbbbfddtttt9122+1jW9mX4L+t
            1tq9JqWUuq/Ol4KjQZF2Mb8IrrIh2SB9PTgrwPItIJUNy4boa8C5AJZ2/3VD9FBwkmHJPu/6bRCV
            LwDmrnOsnwxQ+UBgygX/U6+CaFWAyocAUxY615oN0YrwlIdDU5LObabi1CyIVgKoPBCYEVhmn2+d
            DdEq8JQHQRMBZqYJqwPbPB6g8oHAlETwMpWjZgN0JzzlodAgwJRJ8LA3uw4AtKz6lEWhQYEpifCM
            ghOBpmYBdDU85SHQWEBkwDQDHOb9iCrdAk/5EGBGwMrycaJgpAJ0FTyrgMPc3EKCVJKhQW9q9PUj
            4CkPgKYE4IlCZH2OmCgGCubvjD90CTzlRmi8Xz8KBwoOa74YtUEhqMHtQ+ozE57yIGgQeNDnEXgY
            aNhnRp1uhee1QO6oEL4M8jwTntps8/7+z2vrWcD9a/sVYl/P83EItfGgabdFgZkBz6jSaK+17VBl
            ukV1yiLQsOrCvtY+ywBnBBoUpuXgeS2gbig01uvIdkykxaqMZV4QU4OavXZfl5mxcpHalAtg6b1n
            wGGdY0ZhtM+sbVAlQpzndNUpF0ATNUsoGNbnHjgFNFeMmbKgQP4eMWOXw/O6WNVGoEEgyYQnojgo
            NJWMsjzzFTGH94NDmKhRaEYeGfBkQHM6P7I6AA90rzJU5zURGtTPyYLmSASIAYdRmfPf42RVyMrr
            aOBNVZ2ZpooNvSPQHEF4ZoDDQnOyv1EDDspkZajO6yK1QbLCEgTmcMA5bgDndJzed4hGAQqZrFUV
            h8kOZwLDADULnPPtJp5OhDQKkAbNdNW5KwGo+RpCQoM+M/B44TgDTfv6fPv/FprDgKcq1/K2fqww
            OGAkhfY9eX6OB432WoMIgcdTnN5Dg8bKvbxD04OnGiCjWeR01VmldxzN1ViwePAgjjOqOB44LTSl
            A5Ao0LTwlKDDvJ7iJKoN49d40KAw9fbdU0DtRtXG3GjgnG/77AHU3vx3aFCIiwHQVKBeN6uNgDCh
            0CAQWWbLOsaemToaJ/gdnBaYovgw59sxta/b4+mNBxqCJGquXpOg8Do1I10J7Y3vPX5ANfIUpzjQ
            tGpTFHAsYBCAeo4wY7ammS4anIECAYjaCJDgs4BBFchTHURxDsO5ZfarAWSZ+gp0WRQlGlu2kzNb
            bY7A4weEh/FzejmY2sBSHWCKk5Npz7vdr6c8IX8mYq5ek2DJUJsCwvQDvPbg8fwcz78pitoIoBZ/
            9mUpTw8aRHWmmavZznFEbRD1KYbKMPD0ANIill63QSWhEQOa6vxYWNWZChEFTmByXZbaoNFUFB7E
            H6kdxTlJaKxhGQcAD6o6083VK1lVED9HSLVBssY/BjysydJuuOffoObOcrQ99YkMr5hirq7uqyri
            91mhuZwD8HUsiNjIqqc2lfhfMfwkVHG0AV2Xm6tZeRzPRKEZZa/TEoXnx8j1tPv1FCeiNlZ3xaEo
            j6c6yFCKad0PMDjBkX4F8GuyFEeDR1MdrSvC801QtUH7t94d7ApeCwSepfM4hTRRUTPlDdTqwfNj
            vPecZE8tCmmm0IcGkAZFUZzlUPaYcZDv6B0XR9KZHM4RAAh1kDUA3sfWjABzKudWgYChGooioKoM
            qc8VIwA9aEamvxxgqO75OQdoqtpM7qncNGSw12GoDnItbi1CcFXmOBqme8lANs9jqY5mdmonkjmN
            bd9v4iH6gPVT+EH1nsm6zEF+TVYaZKBURG0OiQ25aFXHiqw05/gUvce7jb6ODjRWFwXblxcJu6/L
            HE9aWAyp8zc6v8obn+MlAXuRkQdNL8xGe9CRgf2WoniDvpZIAGYsC+TNOIiMDiyi90dZTjUKzil/
            d0pWxWc5jePuQSTCzcBIN0NoZHVXVDUabaGAHaAp00xVm6ltlcPrZ7LGE7Nz5WWCgxwG77UYGNFo
            K2rWrPyQdJziQ/FV2hzMSR6nCD7Pfon2ulldmAFPo8nDIv7Mh0P5rjZSQnMwSN0eAaCaqh6rgDNa
            8p7NOHt+EyP9At4wdL/ecbKKUu4G5v3Xc5cfE4GrAOpjOeFo+M/8DwIKAhITUIwGKI8EZzaMyJhn
            b1tGGTLL/zMgzVw68qPAYaQ9sk0ZuOEoWLKacnwDOFb9O29bZn/VSO5J4DvZNSA2ODdA5d24Cm4b
            XYwjXDqfBKhK3krEjwnH0RH66K+6AlAg5WKtSKUS+0DKztYAKEsp1etCMLwprNqvqgKgMKPtWLOH
            7DMCCnsMURV7DDijYCAXKFKDrzcQ64+59ip9iuhl2k7Ry7cxhbBFYiVtbwHodQMs2uh87yKK8MWm
            e0MbirFde4ztsZ0OLGfz2UlCHXX0v0px2IvkmQV0iGav3l7bjWB9fw+a01AiBCDkR4GY75Gg4v/O
            3QVjjlmfRTMDUSC0cS69DsgWIpF+1Sx0zPE7LKfyGas6qDm73b+BwfkfhRMGc0XMFVtPuDfEs+35
            fp/LhIw5rgo4GkSsLxTNR0X8xmVMlVUxSkSf/1yDSsMOyRTxp5v0ChqJ4RD/AvCcilrVATWKrte5
            VH0cTT0KAFYx8i0C+C4MNJr5KYDiVMURPjsA/SpARRzmUcf5UWtyekm+dnR+RHUsgFBotCm31g1q
            AfhV4DlBJToV3whxmr8uj+OF5ZFJbKdwZdmsyf4WOJqD/EvCg5bz95xmLeqcmvd5TVQW1M9hlQWB
            qfd9Gpin4BPyquMg/zqmKgqTZ6KGFrdnQ3EKHCKy8ipHaYtYoNB4c5FEiZ40NStNAhBxjlHV+RXO
            fLF5HxGs9z3drL0mmB9UjbRJ85bK9OZtM+XTLLVh54KfgOp4vg8TqjPQTPd/rvRxKmCukChKg0cA
            M2OpTQEUR3OQregKcZxZeGaE6ZeDEw27pUnvV8eHseDxoGHUBnGOe10NLDw1AE3vWFLM1NRytYCf
            g+ZzNICsGyTyd5l7Ebzmnqc2aDUtMczMGVCbaH7Hi6bWX8yVcJTFyNoikZa1mAYDjac2hVAcFp5f
            p2uC7exko6lHZY4zVMdaSbcEoDkFr6zuDd0YUZ6aEJ5fFk3NVBzLv0FVR4Rf9LSC3QSHE00xq8ec
            gL+Dmip2Xc+0bPIlq8eQPeUjaxBEV85lhmAgIX0F913BLoVIMlACavPIPM6o6vTCXxFu4VNv9Tpm
            +m8lVKdKrI8qmtdBZ248No+Dqk77vrdSrrfwaa9eTW/1uqMBhll2yFMezQFGgGEGeqFqk2amwuAY
            5korGV9A1bEuRFtfRlv4tFe3RlMaK39TjBuCglMBeCoJj5YikCyYVlQcKxlomS2vOJEFzeGYKLQI
            ALrmuPccTf6h5mnt5aMTVccr7qyZLg0iDRqkfg0S3iN5HRSiqE+DAOPC9JQF672VTzwz9t6D3Vsx
            tyRAw647jsLjAYOG36J8/6VqMxMcJMJifR4RbLioNPv31kjIBseatBedOqN1L9Q71GYYnMDsBw2a
            DIC0wkfI4mHZ4CDJvNGxx9HpzMs7xxYcmsliy5K10CBFp2UiOEKAk+kIM73kw2qTAk5wZKDmOKPw
            VPm74KOIX3gaKd6IguMpjwTgEeBzFJSpypM2yc6Axyt1htTLGy1NmwFNBjyRwVkieKWNeoXaXBVV
            IWYKVR6vhEoWNAXMakdNF6suKdAsqTiE6owojwheARSpBJqlOFEFEsHN0jA0WWqTDk4QnhkQSQAc
            xsdhwWFUSQagmW6i7k4AspnmSuzb2l/ETDHmSgDViJqjMDTLmypAdbxfuKcGEUVC1W0UnCg8SCZ4
            CJpstZkGzoC/EzFhkggNA84oPKyqLAPNXeAgN6wkgpQBTQY8WaBQI/seB84keFiQGCeYjapQp3kE
            lKFZC7OgmQ4OAA9juhAICgFgpqkSUCWif6cd4ZnQXAJOEjwsFCUIJhsljkA0RWWugGYlcNBffwSO
            EZWRCerD+C9LQnMZOBcCNKIwIz5OxLSkAnMlNJeDMwhPFiRXgTMK07LQ3AIOAQ97k0dN0ig4UXiG
            gLkDmtvASYInU1EyfJwMRVoemNvBuQAg9PyyoqoMuB4BzRLgkPCMKsfs850F0zLALAVOEKBM81OS
            AckAaVlolgMnCM8VZikTKPrmrwTMsuAkABQ5r1mKE4ZlZWiWBicJoDvPceiGrwrMY8BJBGjmeeeN
            5V0cmMeBMxEi69pc0+/zEFgeD84NEG1YPg2cJ0H0dFg+FpzVQPokUL4KnKtB+mRQvhqcEbi+CYrd
            dtttt91222233Xbbbbfddtttt9122+3L2z8CDAAGA9CHeMiJOgAAAABJRU5ErkJggg=="
              transform="matrix(1 0 0 1 92.2048 0)"
            />
            <g>
              <circle
                class="st0"
                cx="163.1"
                cy="71.5"
                r="19.5"
              />
            </g>
          </g>
          <ellipse
            id="rightEye_1_"
            class="st0"
            cx="215.5"
            cy="206.9"
            rx="11.2"
            ry="16"
          />
          <ellipse
            id="leftEye_1_"
            class="st0"
            cx="108.7"
            cy="206.9"
            rx="11.2"
            ry="16"
          />
          <circle
            class="st0"
            cx="42.8"
            cy="294.2"
            r="32.4"
          />
          <circle
            class="st0"
            cx="283.8"
            cy="294.2"
            r="32.4"
          />
          <path
            id="leftArm_1_"
            class="st0"
            d="M32.8,388.4v-95.1c0-5-4-9-9-9H19c-5,0-9,4-9,9v95.1c-6,3.8-10,10.5-10,18.1
                    c0,11.8,9.6,21.4,21.4,21.4s21.4-9.6,21.4-21.4C42.8,398.8,38.8,392.2,32.8,388.4z"
          />
          <path
            id="rightArm_1_"
            class="st0"
            d="M316.6,388.4v-95.1c0-5-4-9-9-9h-4.8c-5,0-9,4-9,9v95.1c-6,3.8-10,10.5-10,18.1
                    c0,11.8,9.6,21.4,21.4,21.4s21.4-9.6,21.4-21.4C326.5,398.8,322.6,392.2,316.6,388.4z"
          />
        </g>
      </svg>

      <div class="container">
        <h1>Lo sentimos, el archivo solo se puede generar desde la plataforma</h1>
        <p>
          favor de regresar a controlpanel.ticket2pass.com.
          <!-- <a @click="regresar()" style="color:orange">haz clic aqui para regresar a la pagina</a>. -->
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client'
import LoadingAnimation from '../components/tools/LoadingAnimation.vue'

export default {
  components: {
    LoadingAnimation
  },
  data () {
    return {
      textLoading: 'Cargando pdf, un momento por favor!',
      SeeLoading: true,
      Erro404: false,
      url: ''
    }
  },
  mounted () {
    try {
      this.SeeLoading = true
      const d = this.$session.get('tipo')
      let dir = ''

      switch (d.format) {
        case 1:
          dir =
            this.$root.myserver.slice(0, -1) +
            '/tcpdf/reports/GeneratorPdf.php?t=' +
            d.token +
            '&s=' +
            d.estatus +
            '&f=' +
            d.id
          break
        case 2:
          dir =
            this.$root.myserver.slice(0, -1) +
            '/tcpdf/reports/ImprimeBoletosX.php?t=' +
            d.token +
            '&i=' +
            d.ticketType +
            '&n=' +
            d.npool +
            '&p=' +
            d.persontype +
            '&n1=' +
            d.NTARIFBLATTNR +
            '&n2=' +
            d.NTARIFBLATTGUELTNR +
            '&s=' +
            d.idTrans +
            '&c=' +
            d.amount
          break
        default:
          this.$router.push('/')
          break
      }
      this.url = dir
      // window.open(dir, '_parent');

      const iframe = document.getElementById('iframe')
      const handleLoad = () => this.LoadFile()

      iframe.addEventListener('load', handleLoad, true)
    } catch (even) {
      this.SeeLoading = false
      this.Erro404 = true
      // var dir = this.$root.myserver.slice(0, -1) + '/tcpdf/reports/ImprimeBoletos.php?t=12312'
      // this.url = dir

      // alert('no puede cargar el documento')
    }
  },
  methods: {
    LoadFile () {
      const vm = this
      this.SeeLoading = false
      this.$root.socket = io(this.$root.mySock + this.$root.myport + '/', {
        transports: ['websocket']
      })

      this.$root.socket.connect()

      this.$root.socket.emit('GetTicketsGenerated', vm.$session.get('tipo').token)
    },
    regresar () {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style scoped>
html,
div,
body {
  background: rgba(0, 0, 0, 0.9) !important;
}
.Myiframe {
  width: 100vw;
  height: 100vh !important;
  border: 0px;
  padding: 0;
  margin: 0;
}
.st0 {
  fill: #ffffff;
}
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
body h1 {
  margin-bottom: 20px;
}
body p {
  font-size: 18px;
  line-height: 30px;
}
body a {
  color: #fbd160;
}
.Erro404 {
  background: #5a657a !important;
  color: white;
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.container{
    background: #5a657a !important;
}

svg {
  display: block;
  margin: 0 auto;
  overflow: visible !important;
}
svg #robot_1_ {
  animation-name: verticalAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
svg #light_1_ {
  animation-name: blinkLight;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: steps(2);
}
svg #leftEye_1_,
svg #rightEye_1_ {
  animation-name: blinkEye;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: 50% 50%;
}
svg #leftArm_1_ {
  animation-name: moveLeftArm;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50% 10%;
  transition: all;
}
svg #rightArm_1_ {
  animation-name: moveRightArm;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50% 10%;
}
svg #shadow_1_ {
  animation-name: shadowAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50%;
}

@keyframes wave {
  0% {
    transform: rotate(120deg);
  }
  100% {
    transform: rotate(170deg);
  }
}
@keyframes moveLeftArm {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(4deg);
  }
}
@keyframes moveRightArm {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@keyframes shadowAnimation {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.15);
    opacity: 0.6;
  }
}
@keyframes verticalAnimation {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
@keyframes blinkLight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blinkEye {
  0% {
    transform: scaleY(0);
  }
  4% {
    transform: scaleY(1);
  }
}
</style>
