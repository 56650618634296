<template>
  <div id="ReportTicketSales">
    <TitleReportsCard
      title="Boletos vendidos"
      :loading="loading"
      @generateReport="generateReport"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateStart"
          :newMax="dateEnd"
          label="Seleccione una fecha de inicio"
          type="month"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateEnd"
          label="Seleccione una fecha de fin"
          type="month"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          v-model="placeSelected"
          prepend-icon="mdi-bank"
          dense
          outlined
          :items="placesForUser"
          name="id"
          item-text="name"
          item-value="id"
          label="Seleccione un parador"
          background-color="white"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
    </TitleReportsCard>

    <v-card
      v-if="ticketsDetails.length && generated && !loading"
      class="ma-5 pa-5"
      style="color: black"
    >
      <LineChart
        ref="LineChartRef"
        :chartData="chartData"
        :options="options"
      />
    </v-card>

    <ContentReportsCard
      :loading="loading"
      :generated="generated"
      :fileName="fileName"
      :noData="!ticketsDetails.length"
    >
      <ReportTicketSalesTable
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :titleReport="getTitleReport"
        :ticketsDetails="ticketsDetails"
        :soldTickets="soldTickets"
        :exchangedTickets="exchangedTickets"
        :totalTickets="totalTickets"
      />
    </ContentReportsCard>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Components
import TitleReportsCard from '../tools/reports/TitleReportsCard.vue'
import InputDatePickerReport from '../tools/reports/InputDatePickerReport.vue'
import ContentReportsCard from '../tools/reports/ContentReportsCard.vue'
import ReportTicketSalesTable from './ReportTicketSalesTable.vue'
import { LineChart } from 'vue-chart-3'

export default {
  name: 'ReportTicketSales',
  components: { TitleReportsCard, InputDatePickerReport, ContentReportsCard, ReportTicketSalesTable, LineChart },
  data () {
    return {
      dateStart: '',
      dateEnd: '',
      loading: false,
      generated: false,
      placeSelected: null,
      ticketsDetails: [],
      soldTickets: 0,
      exchangedTickets: 0,
      totalTickets: 0,
      chartData: {
        toggleLegend: true,
        datasets: [
          {
            label: 'Boletos',
            data: [],
            backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED']
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: this.toggleLegend ? 'top' : 'bottom'
          },
          title: {
            display: true,
            text: '',
            font: {
              size: 18
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState('dashboard', ['placesForUser']),
    namePlaceSelected: function () {
      let name = ''
      this.placesForUser.forEach(element => {
        if (element.id === this.placeSelected) name = element.name
      })
      return name
    },
    fileName: function () {
      return 'Boletos_vendidos_' + this.namePlaceSelected + '_del_' + this.dateStart + '_hasta_' + this.dateEnd
    },
    getTitleReport: function () {
      return `Boletos vendidos de "${this.namePlaceSelected}"`
    }
  },
  mounted () {
    this.dateStart = this.$sumarDias2(new Date())
    this.dateEnd = this.$sumarDias2(new Date())
  },
  methods: {
    generateReport: function () {
      if (!this.placeSelected) {
        this.$swalAlert('warning', 'Seleccione un parador!')
        return
      }

      this.ticketsDetails = []
      this.loading = true
      this.generated = false
      this.options.plugins.title.text = this.getTitleReport + ' de ' + this.$formatFecha(this.dateStart) + ' a ' + this.$formatFecha(this.dateEnd)

      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        placeSelected: this.placeSelected
      }
      this.$root.post('/reports/ticketSales', data)
        .then(response => {
          const data = response.data
          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }
          const ticketsDetails = data[0]

          const datesChart = []
          const totalsChart = []

          let soldTickets = 0
          let exchangedTickets = 0
          let totalTickets = 0

          ticketsDetails.forEach(element => {
            const { Fecha: date, Cantidad, Canje, Boleto } = element
            const amount = Number(Cantidad) || 0

            datesChart.push(date)
            totalsChart.push(amount)
            soldTickets += amount
            exchangedTickets += Number(Canje) || 0
            totalTickets += Number(Boleto) || 0
          })

          this.chartData.labels = datesChart
          this.chartData.datasets[0].data = totalsChart

          this.ticketsDetails = ticketsDetails
          this.soldTickets = soldTickets
          this.exchangedTickets = exchangedTickets
          this.totalTickets = totalTickets
        })
        .finally(() => {
          this.loading = false
          this.generated = true
        })
    }
  }
}
</script>

<style>

</style>
