<template>
  <div
    id="ContentReportsCard"
    :class="`${loading || noData ? 'loading' : ''}`"
  >
    <v-card
      v-if="generated || loading"
      class="container-card ma-5"
    >
      <v-card-text :class="`container-card-text black--text ${loading ? 'loading-report' : ''} ${noData ? '': 'px-11 py-6'}`">
        <LoadingAnimation
          v-if="loading"
          textLoading="Generando reporte"
          :dark="false"
          :small="true"
        />
        <div v-if="!loading">
          <v-alert
            v-if="noData"
            icon="mdi-folder-alert-outline"
            prominent
            text
            type="warning"
            align="left"
            class="mb-0"
          >
            Sin datos almacenados
          </v-alert>
          <div
            v-if="!noData"
            id="content-for-excel"
          >
            <slot />
          </div>
        </div>
      </v-card-text>
      <div class="container-buttons">
        <v-btn
          v-if="!selectTypeExport && generated && !noData"
          dark
          color="primary"
          @click="printPage"
        >
          <v-icon left>
            mdi-printer-outline
          </v-icon>
          Imprimir
        </v-btn>
        <v-btn
          v-if="generated && !noData"
          dark
          color="green"
          @click="verifyExport"
        >
          <v-icon left>
            mdi-file-export-outline
          </v-icon>
          Exportar
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
// Components
import LoadingAnimation from '../LoadingAnimation.vue'

export default {
  name: 'ContentReportsCard',
  components: { LoadingAnimation },
  props: {
    generated: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    noData: {
      type: Boolean,
      required: true
    },
    selectTypeExport: {
      type: Boolean,
      required: false,
      default: false
    },
    fileName: {
      type: String,
      required: true,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    verifyExport: function (event) {
      if (this.selectTypeExport) this.$emit('exportReport', event)
      else this.exportReport(event)
    },
    exportReport: function (event) {
      this.$exportExcelFromJs(event, this.fileName)
    },
    printPage: function (event, id = 'content-for-excel') {
      event.preventDefault()
      const table = document.getElementById(id)
      if (!table) return
      const mywindow = window.open('', 'PRINT', 'left=0,top=0,width=750,height=700,toolbar=0,scrollbars=0,status=0')
      const htmlObject = `
        <!DOCTYPE html>
        <html lang="es">
          <head>
            <head><title>${this.title}</title>
          </head>
          <body style="padding:20px;">
            ${table.innerHTML}
          </body>
        </html>

      `
      mywindow.document.write(htmlObject)
      mywindow.document.close()
      mywindow.focus()
      mywindow.print()
      mywindow.close()
    }
  }
}
</script>

<style scoped>
#ContentReportsCard {
  margin-inline: auto;
  position: relative;
}

#ContentReportsCard:not(.loading) {
  width: fit-content;
}

#ContentReportsCard.loading {
  max-width: 1200px;
}

.loading-report {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-buttons {
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

@media (max-width: 999px) {
  #ContentReportsCard {
    padding: 20px 10px;
  }

  #ContentReportsCard:not(.loading) {
    width: 100%;
    overflow: hidden;
  }

  .container-card {
    margin: 0px !important;
    max-width: 100% !important;
    overflow-x: auto;
  }

  .container-card-text {
    padding: 20px 15px !important;
  }

  #ContentReportsCard:not(.loading) > .container-card > .container-card-text{
    width: fit-content !important;
  }
}
</style>
