<template>
    <table>
        <thead>
          <tr>
            <td class="place-title f12">
              Transaction Type
            </td>
            <td class="place-title f12">
              Trans. No.
            </td>
            <td class="place-title f12">
              CPU-Number
            </td>
            <td class="place-title f12">
              CPU-Trans. No.
            </td>
            <td class="place-title f12">
              Usage Time
            </td>
            <td class="place-title f12">
              POE #
            </td>
            <td class="place-title f12">
              Lane
            </td>
            <td class="place-title f12">
              Ticket Number
            </td>
            <td class="place-title f12">
              UID No.
            </td>
            <td class="place-title f12">
              Ticket Type
            </td>
            <td class="place-title f12">
              Person Type
            </td>
            <td class="place-title f12">
              Transaction
            </td>
            <td class="place-title f12">
              Validation
            </td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(ticket, index) in dataTable">
            <template v-for="repeat in ticket.tickets_repeat">
              <tr>
                <td>
                  {{ repeat.c1 }}
                </td>
                <td>
                  {{ repeat.c2 }}
                </td>
                <td>'{{ repeat.c3 }}</td>
                <td>
                  {{ repeat.c4 }}
                </td>
                <td>
                  <strong>{{ repeat.c5 }}</strong>
                </td>
                <td>
                  {{ repeat.c6 }}
                </td>
                <td>
                  {{ repeat.c7 }}
                </td>
                <td>
                  {{ repeat.c8 }}
                </td>
                <td>'{{ ticket.c10 }}</td>
                <td>
                  {{ ticket.c11 }}
                </td>
                <td>
                  {{ ticket.c12 }}
                </td>
                <td>
                  {{ ticket.c13 }}
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
</template>

<script>
export default {
  name: "DropZoneTableList",
  data() {
    return { 
    }
  },
  props: ["dataTable"]
}
</script>
