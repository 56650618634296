<template>
  <v-slide-y-reverse-transition>
    <div
      v-if="dialog"
      class="container-print-history"
    >
      <v-card
        class="dialog-print-history"
        outlined
      >
        <v-toolbar
          height="55px"
          dark
        >
          <v-toolbar-title>{{ transactionPrint.RefOri }} </v-toolbar-title>
          <v-spacer />
          <v-btn
            color="success"
            class="ma-2 ml-7"
            @click="exportTable"
          >
            Exportar
          </v-btn>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="loading">
          <v-skeleton-loader type="table" />
        </v-card-text>

        <v-card-text v-if="!loading">
          <v-simple-table
            v-for="sitio in transactionPrintData"
            :key="'print-history-place-' + sitio.sitio"
            class="w-100"
          >
            <thead>
              <tr>
                <td class="place-name">
                  {{ sitio.namesSitio }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color: rgba(255, 165, 0, 0.4)">
                <td>
                  <v-simple-table>
                    <thead>
                      <tr style="background: rgb(253 142 48 / 22%); color: black">
                        <td align="center">
                          <strong>Tipo de boleto</strong>
                        </td>
                        <td class="MyTdPadding">
                          <strong>Nombre del Boleto</strong>
                        </td>
                        <td align="center">
                          <strong>Precio Unitario</strong>
                        </td>
                        <td align="center">
                          <strong>Clave de transacción de Impresión</strong>
                        </td>
                        <td align="center">
                          <strong>Fecha Impresión</strong>
                        </td>
                        <td align="center">
                          <strong>Cantidad</strong>
                        </td>
                        <td align="center">
                          <strong>Descripción</strong>
                        </td>
                        <td align="center">
                          <strong>Solicitó</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="sitioTicket in sitio.data"
                        :key="`print-history-place-details-${sitio.sitio}-${sitioTicket.id_ticket}-${sitioTicket.clave}`"
                      >
                        <td align="center">
                          <strong>{{ sitioTicket.id_ticket }}</strong>
                        </td>
                        <td align="center">
                          <strong>{{ sitioTicket.descripcion }} </strong>
                        </td>
                        <td align="center">
                          ${{ $formatPrice(sitioTicket.costo) }}<ExchangeCurrency />
                        </td>
                        <td align="center">
                          <strong>{{ sitioTicket.clave }}</strong>
                        </td>
                        <td align="center">
                          <strong>{{ sitioTicket.f_solicitud }}</strong>
                        </td>
                        <td align="center">
                          {{ sitioTicket.cantidad }}
                        </td>
                        <td align="center">
                          {{ sitioTicket.descript }}
                        </td>
                        <td align="center">
                          <strong>{{ sitioTicket.solicito }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div
            v-show="false"
            :id="idPrint"
          >
            <table style="width:100%;">
              <tr align="right">
                <td
                  colspan="8"
                  style="font-size: 16px;"
                >
                  <strong>Línea de Referencia: </strong>  {{ transactionPrint.RefOri }}
                </td>
              </tr>
              <tr align="right">
                <td
                  colspan="8"
                  style="font-size: 16px;"
                >
                  <strong>Compañía: </strong>  {{ transactionPrint.CorpName }}
                </td>
              </tr>
              <tr align="right">
                <td
                  colspan="8"
                  style="font-size: 16px;"
                >
                  <strong>Fecha de Solicitud: </strong>  {{ transactionPrint.fecha }}
                </td>
              </tr>
              <tr align="right">
                <td
                  colspan="8"
                  style="font-size: 16px;"
                >
                  <strong>Total: </strong>  ${{ $formatPrice(transactionPrint.total) }}
                </td>
              </tr>
              <tr align="right">
                <td
                  colspan="8"
                  style="font-size: 16px;"
                >
                  <strong>Total Boletos: </strong>  {{ $formatNum(transactionPrint.cBoletos) }}
                </td>
              </tr>
              <tr align="right">
                <td
                  colspan="8"
                  style="font-size: 16px;"
                >
                  <strong>Autorizó: </strong>  {{ transactionPrint.autorizo }}
                </td>
              </tr>
              <tr align="right">
                <td
                  colspan="8"
                  style="font-size: 16px;"
                >
                  <strong>Fecha autorización: </strong>  {{ transactionPrint.f_autorizacion }}
                </td>
              </tr>
              <tr />
            </table>

            <div
              v-for="sitio in transactionPrintData"
              :key="'export-print-history-place-' + sitio.sitio"
            >
              <table
                class="styled-table"
                style="border-collapse: collapse; width:100%;"
              >
                <thead>
                  <tr>
                    <td
                      style="
                        background: white;
                        color: black;
                        padding: 0 !important;
                        padding-left: 5px !important;
                        font-size: 17px !import;
                        font-weight: bold;
                      "
                    >
                      {{ sitio.namesSitio }}
                    </td>
                  </tr>
                </thead>
              </table>
              <table
                border="1"
                bordercolor="#272727"
                class="styled-table"
              >
                <thead>
                  <tr>
                    <td align="center">
                      <strong>Tipo de boleto</strong>
                    </td>
                    <td class="MyTdPadding">
                      <strong>Nombre del Boleto</strong>
                    </td>
                    <td align="center">
                      <strong>Precio Unitario</strong>
                    </td>
                    <td align="center">
                      <strong>Clave de transacción de Impresión</strong>
                    </td>
                    <td align="center">
                      <strong>Fecha Impresión</strong>
                    </td>
                    <td align="center">
                      <strong>Cantidad</strong>
                    </td>
                    <td align="center">
                      <strong>Descripción</strong>
                    </td>
                    <td align="center">
                      <strong>Solicitó</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="sitioTicket in sitio.data"
                    :key="`export-print-history-place-details-${sitio.sitio}-${sitioTicket.id_ticket}-${sitioTicket.clave}`"
                  >
                    <td align="center">
                      <strong>{{ sitioTicket.id_ticket }}</strong>
                    </td>
                    <td>
                      <strong>{{ sitioTicket.descripcion }} </strong>
                    </td>
                    <td align="center">
                      <strong>${{ $formatPrice(sitioTicket.costo) }}</strong>
                    </td>
                    <td align="center">
                      <strong>{{ sitioTicket.clave }}</strong>
                    </td>
                    <td align="center">
                      {{ sitioTicket.f_solicitud }}
                    </td>
                    <td align="center">
                      {{ sitioTicket.cantidad }}
                    </td>
                    <td align="center">
                      {{ sitioTicket.descript }}
                    </td>
                    <td align="center">
                      <strong>{{ sitioTicket.solicito }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-slide-y-reverse-transition>
</template>

<script>
export default {
  name: 'ImpressionHistory',
  props: ['value', 'transactionPrint', 'transactionPrintData', 'loading', 'nowDate'],
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    idPrint: function () {
      return `history-tickets-wrap-${this.transactionPrint.RefOri}`
    },
    fileName: function () {
      return `Historial_impresiones_de_${this.transactionPrint.RefOri}_a_${this.$formatFecha(this.nowDate, '-')}`
    }
  },
  methods: {
    exportTable: function (event) {
      this.$exportExcelFromJs(event, this.fileName, this.idPrint)
    }
  }
}
</script>

<style scoped>
.container-print-history {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
}

.dialog-print-history {
  min-width: 100%;
  min-height: 100%;
}

.place-name {
  background: white;
  color: black;
  padding: 0 !important;
  padding-left: 5px !important;
  font-size: 17px !important;
  font-weight: bold;
}
</style>
