import Vue from 'vue'
const moment = require('moment')

Vue.prototype.$rules = {
  required: (value) => !!value || 'Este campo es requerido',
  requiredOrZero: (value) => !!value || value === 0 || 'Este campo es requerido',
  isEmail: (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(value) || 'El correo debe ser válido',
  isFloat: (value) => {
    const pattern = /^[+-]?([0-9]*\.?[0-9]{1,2})$/
    return pattern.test(value) || 'Numero no válido.'
  },
  isNumber: (value) => {
    const pattern = /^[0-9]+$/
    return pattern.test(value) || 'Numero no válido.'
  },
  min: (value, min, typeValue = 'valor') => value >= min || `El ${typeValue} debe ser mayor o igual a ${min}`,
  max: (value, max, typeValue = 'valor') => value <= max || `El ${typeValue} debe ser menor o igual a ${max}`,
  equals: (value, equalsTo, typeValue = 'valor') => value === equalsTo || `El ${typeValue} debe ser igual a ${equalsTo}`,
  minLength: (value, minLength, typeValue = 'valor') => value?.length >= minLength || `El ${typeValue} debe tener al menos ${minLength} ${typeof value === 'string' ? 'caracteres' : 'elementos'}`,
  maxLength: (value, maxLength, typeValue = 'valor') => value?.length <= maxLength || `El ${typeValue} debe tener como máximo ${maxLength} ${typeof value === 'string' ? 'caracteres' : 'elementos'}`,
  equalsLength: (value, length, typeValue = 'valor') => value?.length === length || `El ${typeValue} debe tener ${length} ${typeof value === 'string' ? 'caracteres' : 'elementos'}`,
  isDate: (value, format = 'DD/MM/YYYY') => {
    return moment(value, format, true).isValid() || 'Fecha no válida'
  },
  isRFC: (value) => {
    const pattern = /^[A-Z]{3,4}[0-9]{6}[A-Z0-9]{3}$/
    return pattern.test(value) || 'RFC no válido'
  },
  isPhone: (value) => {
    // for this patern '(###) ###-####'
    const pattern = /^\(\d{3}\) \d{3}-\d{4}$/
    return pattern.test(value) || 'Teléfono no válido'
  }
}

Vue.prototype.$input = {
  isNumber: (evt) => {
    evt = evt ?? window.event
    const charCode = evt.which ?? evt.keyCode

    const cantUse = charCode > 31 && 
      (charCode < 48 || charCode > 57) && 
      (charCode < 96 || charCode > 105) && 
      (charCode < 37 || charCode > 40) 
      && charCode !== 46
    if (cantUse) {
      evt.preventDefault()
    } else {
      return true
    }
  }
}
