<template>
  <div id="LoginView">
    <AnimationBgLogin />
    <div class="container-cards-login">
      <LoginCard
        class="loginCard"
        @changeView="toSinginCard"
      />
      <SinginCard
        class="singinCard"
        @changeView="toLoginCard"
      />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

// Components
import AnimationBgLogin from '../components/tools/AnimationBgLogin.vue'
import LoginCard from '../components/loginCards/LogInCard.vue'
import SinginCard from '../components/loginCards/SingInCard.vue'

export default {
  name: 'LoginView',
  components: {
    AnimationBgLogin,
    LoginCard,
    SinginCard
  },
  methods: {
    toSinginCard: function () {
      $('.loginCard').toggle('slow')
      setTimeout(() => {
        $('.singinCard').toggle('slow')
      }, 500)
    },
    toLoginCard: function () {
      $('.singinCard').toggle('slow')
      setTimeout(() => {
        $('.loginCard').toggle('slow')
      }, 500)
    }
  }
}
</script>

<style scoped>
#LoginView {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: grid;
  place-content: center;
  padding: 20px;
}

.container-cards-login {
  overflow: auto;
}

.singinCard {
  display: none;
}
</style>
