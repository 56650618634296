import MainDashboard from '../views/dashboard/MainDashboard.vue'
import HomeDashboard from '../views/dashboard/HomeDashboard.vue'
import TicketSetup from '../views/dashboard/TicketSetup.vue'
import RemainingTickets from '../views/dashboard/RemainingTickets.vue'
import RepeatTickets from '../views/dashboard/RepeatTickets.vue'
import TicketsNoUse from '../views/dashboard/TicketsNoUse.vue'
import WholesalersDashboard from '../views/dashboard/WholesalersDashboard.vue'
import ApiDashboard from '../views/dashboard/ApiDashboard.vue'
import AccountSettingDashboard from '../views/dashboard/AccountSettingDashboard.vue'
import PosConfiguration from '../views/dashboard/PosConfiguration.vue'
import UsersConfigurations from '../views/dashboard/UsersConfigurations.vue'
import ManualCancellations from '../views/dashboard/ManualCancellations.vue'

// Import routes of reports
import reports from './reports'

export default [
  {
    path: '/',
    component: MainDashboard,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: HomeDashboard
      },
      {
        path: 'account-settings-dashboard',
        name: 'Perfil y configuración',
        component: AccountSettingDashboard
      },
      // reports and their children routes
      ...reports,
      {
        path: 'ticket-setup',
        name: 'Configuración de Boletos',
        component: TicketSetup
      },
      {
        path: 'remaining-tickets',
        name: 'Boletos restantes',
        component: RemainingTickets
      },
      {
        path: 'repeat-tickets',
        name: 'Boletos Repetidos',
        component: RepeatTickets
      },
      {
        path: 'tickets-use',
        name: 'Boletos usados',
        component: TicketsNoUse
      },
      {
        path: 'pos-configuration',
        name: 'Configuración de cajas',
        component: PosConfiguration
      },
      {
        path: 'users-configurations',
        name: 'Configuración de usuarios',
        component: UsersConfigurations
      },
      {
        path: 'manual-cancellations',
        name: 'Cancelaciones manuales',
        component: ManualCancellations
      },
      {
        path: 'wholesalers',
        name: 'Módulo de mayoristas',
        component: WholesalersDashboard
      },
      {
        path: 'API',
        name: 'Bitacoras de envio',
        component: ApiDashboard
      }
    ]
  }
]
