<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-row class="FormCorpData">
    <v-col cols="12">
      <v-text-field
        v-model="formCorp.companyRFC"
        append-icon="mdi-badge-account-horizontal"
        name="RFC"
        label="RFC"
        type="text"
        outlined
        :rules="[$rules.required, $rules.isRFC]"
        hide-details="auto"
        :disabled="!canEdit"
        @keyup="rfcParseUppercase()"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="formCorp.companyName"
        append-icon="mdi-domain"
        name="CompanyName"
        label="Nombre"
        type="text"
        outlined
        :rules="[$rules.required]"
        hide-details="auto"
        :disabled="!canEdit"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-text-field
        v-model="formCorp.companyAddress"
        append-icon="mdi-map-marker"
        name="Direccion"
        label="Dirección"
        type="text"
        outlined
        :rules="[$rules.required]"
        hide-details="auto"
        :disabled="!canEdit"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-text-field
        v-model="formCorp.companyPhone"
        v-mask="'(###) ###-####'"
        append-icon="mdi-phone"
        name="Telefono"
        label="Telefono"
        type="text"
        outlined
        :rules="[$rules.required, $rules.isPhone]"
        hide-details="auto"
        :disabled="!canEdit"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormCorpData',
  props: {
    formCorp: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    rfcParseUppercase: function () {
      // eslint-disable-next-line vue/no-mutating-props
      this.formCorp.companyRFC = this.formCorp.companyRFC.toUpperCase()
    }
  }
}
</script>

<style>

</style>
