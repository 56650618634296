<template>
  <div id="WholesalesSearchEngine">
    <v-toolbar
      height="45px"
      dark
      elevation="0"
      color="#1e1e1e"
    >
      <v-radio-group
        v-model="searchType"
        class="radio-search-type"
        row
        hide-details
        mandatory
      >
        <v-radio
          v-for="item in searchTypes"
          :key="`item-search-type-${item.title}`"
          :value="item.value"
        >
          <template v-slot:label>
            Buscar por <strong class="warning--text pl-1">{{ item.title }}</strong>

            <InputDatePickerReport
              v-if="searchType === 'date' && item.value === 'date'"
              v-model="selectedDate"
              class="ml-3"
              @change="searchTickets"
            />
          </template>
        </v-radio>
      </v-radio-group>

      <div class="select-search-type">
        <v-select
          v-model="searchType"
          :items="searchTypes"
          dense
          dark
          outlined
          hide-details
          item-value="value"
        >
          <template v-slot:item="{item}">
            Buscar por <strong class="warning--text pl-1">{{ item.title }}</strong>
          </template>
          <template v-slot:selection="{item}">
            Buscar por <strong class="warning--text pl-1">{{ item.title }}</strong>
          </template>
        </v-select>
        <InputDatePickerReport
          v-if="searchType === 'date'"
          v-model="selectedDate"
          class="ml-3"
          :left="true"
          @change="searchTickets"
        />
      </div>

      <v-spacer />

      <v-text-field
        v-if="searchType !== 'date'"
        ref="textFieldSearch"
        v-model="search"
        :placeholder="textSearch"
        v-bind="attrsSearching"
        :rules="searchType === 'serialNumber' ? [$rules.required, rulesIsFolio] : [$rules.required]"
        @keyup.enter="searchTickets()"
      />

      <v-btn
        v-if="searchType !== 'date'"
        class="ml-4 mr-2"
        depressed
        color="primary"
        @click="searchTickets"
      >
        <div class="radio-search-type">
          Buscar
        </div>
        <v-icon class="select-search-type">
          mdi-magnify
        </v-icon>
      </v-btn>
    </v-toolbar>
    <div class="content-with-fixed-toolbar">
      <WholesalesSearchEngineTable
        :tickets="tickets"
        :loading="loading"
        :generated="generated"
        :titleReport="titleReport"
        :valueSearched="searchType !== 'date' ? search : selectedDate"
        :isDateSearch="searchType === 'date'"
        :nowDate="nowDate"
      />
    </div>
  </div>
</template>

<script>
// Components
import InputDatePickerReport from '../../tools/reports/InputDatePickerReport.vue'
import WholesalesSearchEngineTable from './WholesalesSearchEngineTable.vue'

export default {
  name: 'WholesalesSearchEngine',
  components: { InputDatePickerReport, WholesalesSearchEngineTable },
  props: ['activeNow', 'nowDate'],
  data () {
    return {
      attrsSearching: {
        label: '',
        outlined: true,
        dark: true,
        clearable: true,
        dense: true,
        color: 'orange',
        class: 'search-text-field',
        'hide-details': true
      },
      searchType: null,
      searchTypes: [
        { title: 'Folio', value: 'serialNumber' },
        { title: 'Journal', value: 'journal' },
        { title: 'Barcode', value: 'barcode' },
        { title: 'Fecha Impresión', value: 'date' }
      ],
      textSearch: '',
      search: '',
      searchPlaceholder: 'Escribe el nombre de una empresa',
      rulesIsFolio: (value) => {
        const pattern = /[0-9]+-[0-9]+-[0-9]{8}/
        return pattern.test(value) || 'Folio no valido.'
      },
      selectedDate: '',
      tickets: [],
      loading: false,
      generated: false
    }
  },
  computed: {
    titleReport: function () {
      let title = ''
      this.searchTypes.forEach(element => {
        if (element.value === this.searchType) {
          title = element.title.toLocaleLowerCase()
        }
      })
      return title
    }
  },
  watch: {
    searchType: function (val) {
      const textSearch = {
        serialNumber: '00-00-00000000',
        journal: 'Escribe un numero de Journal',
        barcode: 'Escribe un numero de Barcode'
      }
      this.textSearch = textSearch[this.searchType]
      this.search = ''
      this.tickets = []
      this.generated = false

      setTimeout(() => {
        if (val !== 'date') this.$refs.textFieldSearch.reset()
        else this.searchTickets()
      }, 10)
    }
  },
  mounted () {
    this.selectedDate = this.nowDate
  },
  methods: {
    searchTickets: function () {
      if (this.searchType !== 'date' && !this.$refs.textFieldSearch.validate()) {
        this.$swalAlert('warning', 'Campos invalidos!')
        return
      }
      const typeSearch = this.searchTypes.findIndex(element => element.value === this.searchType)

      this.loading = true
      this.generated = false
      this.tickets = []
      this.search = this.search.replaceAll(' ', '')

      const data = {
        typeSearch,
        value: typeSearch === 3 ? this.selectedDate : this.search
      }

      this.$root.post('/wholesalers/searchTickets', data)
        .then((response) => {
          const data = response?.data
          if (data.success === 'FALSE') return this.$swalAlert('error', null, data.message)

          this.tickets = data
        }).finally(() => {
          this.loading = false
          this.generated = true
        })
    }
  }
}
</script>

<style scoped>
.search-text-field {
  max-width: 300px !important;
  margin-left: 15px;
}

.content-with-fixed-toolbar {
  height: calc(100vh - var(--h-navbar) - 45px - 45px);
  overflow-y: auto;
  padding: 20px;
}

.select-search-type {
  display: none;
}

.radio-search-type {
  display: block;
}

@media (max-width: 1199px) {
  .select-search-type {
    display: flex;
  }

  .radio-search-type {
    display: none;
  }
}
</style>
