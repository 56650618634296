<template>
  <div id="ReportSalesWholesalersTable">
    <StyledTableReport
      :headers="headersReport"
      :details="detailsReport"
      :title="titleReport"
      :showTotals="false"
      :titleColspan="headersReportSaleTransactions.length"
      center
    >
      <tr
        v-for="cashierData in detUserMayo"
        :key="'cashier-data-'+cashierData.name"
      >
        <td>
          {{ cashierData.name }} {{ cashierData.name2 }} {{ cashierData.name3 }}
        </td>
        <td
          align="center"
          class="text-center"
        >
          {{ $formatNum(cashierData.operaciones) }}
        </td>
        <td
          align="right"
          class="text-right"
        >
          ${{ $formatPrice(cashierData.total) }}
        </td>
      </tr>
      <tr class="spacer-styled-table">
        <th
          align="right"
          class="text-right"
          style="border-top: 2px double black;"
        >
          Totales:
        </th>
        <th
          align="center"
          class="text-center"
          style="border-top: 2px double black;"
        >
          {{ $formatNum(getTotalOperaciones) }}
        </th>
        <th
          align="right"
          class="text-right"
          style="border-top: 2px double black;"
        >
          ${{ $formatPrice(totalMayo) }}
        </th>
      </tr>
    </StyledTableReport>

    <v-divider class="mb-2" />
    <StyledTableReport
      :headers="headersDetailsSalePlace"
      title="Detalle de ventas por sitio"
      :showTable="false"
      :titleColspan="headersReportSaleTransactions.length"
      center
    />
    <StyledTableReport
      v-for="(place, index) in detMayo"
      :key="`item-details-sales-by-place-${place.sitioId}`"
      :titleTable="place.sitio"
      :headers="headersDetailsSalePlace"
      :includeCajes="false"
      :spacer="!!index"
      :totals="{totalTickets : place.Cantidad, total: place.total}"
    >
      <tr
        v-for="ticket in place.data"
        :key="`item-details-sales-place-${place.sitioId}-ticket-${ticket.id_ticket}`"
      >
        <td>{{ ticket.nombre }}</td>
        <td align="center">
          {{ $formatNum(ticket.cantidad) }}
        </td>
        <td align="right">
          ${{ $formatPrice(ticket.costo) }}
        </td>
        <td align="right">
          ${{ $formatPrice(ticket.total) }}
        </td>
      </tr>
    </StyledTableReport>

    <v-divider class="mb-2" />
    <StyledTableReport
      :headers="headersDetailsSalePlace"
      title="Transacciones"
      :showTable="false"
      center
      :titleColspan="headersReportSaleTransactions.length"
    />
    <StyledTableReport
      v-for="(tansaction, index) in detailTransactions"
      :key="`item-details-sales-by-cashier-${tansaction.id}-rl-${tansaction.RefOri}`"
      :spacer="!!index"
      :headers="headersReportSaleTransactions"
      :showTotals="false"
    >
      <tr>
        <td>
          {{ tansaction.RefOri }}
        </td>
        <td align="center">
          {{ tansaction.CorpName }}
        </td>
        <td align="center">
          {{ tansaction.solicito }}
        </td>
        <td align="center">
          {{ tansaction.f_solicitud }}
        </td>
        <td align="center">
          {{ tansaction.f_autorizacion }}
        </td>
        <td align="right">
          ${{ $formatPrice(tansaction.total) }}
        </td>
      </tr>
      <tr>
        <td :colspan="headersReportSaleTransactions.length">
          <StyledTableReport
            v-for="(place, indexPlace) in tansaction.sitios"
            :key="`item-details-sales-by-cashier-${tansaction.id}-rl-${tansaction.RefOri}-place-${place.sitio}`"
            :titleTable="place.SitioName"
            :headers="headersDetailsSalePlace"
            :includeCajes="false"
            :spacer="!!indexPlace"
            :totals="{totalTickets : place.cantidadSitio, total: place.subtotal2}"
          >
            <tr
              v-for="ticket in place.ticket"
              :key="`item-details-sales-place-${place.sitioId}-ticket-${ticket.id_ticket}`"
            >
              <td>{{ ticket.NameTicket }}</td>
              <td align="center">
                {{ $formatNum(ticket.cantidad) }}
              </td>
              <td align="right">
                ${{ $formatPrice(ticket.costo) }}
              </td>
              <td align="right">
                ${{ $formatPrice(ticket.subtotal) }}
              </td>
            </tr>
          </StyledTableReport>
        </td>
      </tr>
    </StyledTableReport>
  </div>
</template>

<script>
// Components
import StyledTableReport from '../tools/reports/StyledTableReport.vue'

export default {
  name: 'ReportSalesWholesalersTable',
  components: { StyledTableReport },
  props: [
    'dateStart',
    'dateEnd',
    'titleReport',
    'detMayo',
    'detUserMayo',
    'totalMayo',
    'detailTransactions'
  ],
  data () {
    return {
      headersReport: [
        { name: 'Cajero', attrs: {} },
        { name: 'Total de transacciones', attrs: {} },
        { name: 'Total', attrs: {} }
      ],
      detailsReport: [
        {
          key: 'Fecha',
          value: `${this.$formatFecha(this.dateStart)} al ${this.$formatFecha(this.dateEnd)}`,
          attrs: { class: 'pt-2' }
        }
      ],
      headersDetailsSalePlace: [
        { name: 'Nombre del boleto', attrs: { width: '40%' } },
        { name: 'Cantidad', attrs: { width: '20%' } },
        { name: 'Precio unitario', attrs: { width: '20%' } },
        { name: 'Total', attrs: { width: '20%' } }
      ],
      headersReportSaleTransactions: [
        { name: 'Linea de referencia', attrs: {} },
        { name: 'Nombre de la empresa', attrs: {} },
        { name: 'Solicitó', attrs: {} },
        { name: 'Fecha orden de pago', attrs: {} },
        { name: 'Fecha de autorización', attrs: {} },
        { name: 'Costo total', attrs: {} }
      ]
    }
  },
  computed: {
    getTotalOperaciones: function () {
      let total = 0
      this.detUserMayo.forEach((element) => {
        total += element.operaciones
      })
      return total
    }
  }
}
</script>

<style>

</style>
