// WebSocket
import { io } from 'socket.io-client'

export default {
  methods: {
    ejectSocket: function () {
      const self = this
      self.killSocket()

      window.onbeforeunload = function () {
        self.killSocket()
      }

      self.$root.socket = io(self.$root.mySock + self.$root.myport + '/', {
        transports: ['websocket']
      })

      self.$root.socket.connect()

      self.$root.socket.removeListener('message')
      self.$root.socket.on('message', (res) => {
        if (res.exit) {
          self.$root.logout()
          self.$swalAlert('error', res.text || 'Error inesperado')
          return
        }
        self.$swalAlert(res.icon, res.title, res.text)
      })
    },
    killSocket: function () {
      try {
        if (self.$root.socket) self.$root.socket.disconnect()
      } catch (e) {}
    }
  }
}
