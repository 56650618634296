<template>
  <div id="ReportByCashier">
    <TitleReportsCard
      title="Recaudación por cajero"
      justify="start"
      :loading="loading"
      @generateReport="generateReport"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateStart"
          :newMax="dateEnd"
          label="Seleccione una fecha de inicio"
          :disabled="loading"
          @change="getListCashier"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateEnd"
          label="Seleccione una fecha de fin"
          :disabled="loading"
          @change="getListCashier"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          v-model="placeSelected"
          prepend-icon="mdi-bank"
          dense
          outlined
          :items="placesForUser"
          name="id"
          item-value="id"
          item-text="name"
          label="Seleccione un parador"
          :disabled="loading"
          hide-details
          @change="getListCashier"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          v-model="cashierSelected"
          prepend-icon="mdi-account"
          dense
          outlined
          :items="listCashier"
          name="id"
          item-value="id"
          item-text="name"
          label="Seleccione un cajero"
          :disabled="loading || loadingCashier"
          :loading="loadingCashier"
          hide-details
          @change="generated = false"
        />
      </v-col>
    </TitleReportsCard>

    <ContentReportsCard
      :loading="loading"
      :generated="generated"
      :fileName="fileName"
      :noData="!usersDetails.length"
    >
      <ReportsCollectionsTable
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :titleReport="getTitleReport"
        :usersDetails="usersDetails"
        :ticketsDetails="ticketsDetails"
        :totalPlace="totalPlace"
        :totalPlaceAtTickets="totalPlaceAtTickets"
        :receiptDetails="receiptDetails"
        :receiptTotal="receiptTotal"
        :fideA="fideA"
        :fideB="fideB"
        :anexos="anexos"
        :placeId="placeSelected"
      />
    </ContentReportsCard>
  </div>
</template>

<script>
// Mixins
import COLLECTIONREPORTS from '../../minixs/collection-reports'

export default {
  name: 'ReportByCashier',
  mixins: [COLLECTIONREPORTS],
  data () {
    return {
      apiName: 'collectionByCashier',
      cashierSelected: null,
      listCashier: [],
      loadingCashier: false,
      needPOS: false,
      needCashier: true
    }
  },
  computed: {
    nameCashierSelected: function () {
      let name = ''
      this.listCashier.forEach(element => {
        if (element.id === this.cashierSelected) name = element.name
      })
      return name
    },
    getTitleReport: function () {
      const titleReport = `Recaudación de "${this.namePlaceSelected}" - cajero - "${this.nameCashierSelected}"`
      return titleReport
    },
    fileName: function () {
      return 'Recaudación_de_' + this.namePlaceSelected + '_cajero_' + this.nameCashierSelected + '_' + '_del_' + this.dateStart + '_hasta_' + this.dateEnd
    }
  },
  methods: {
    getListCashier: function () {
      this.generated = false
      this.cashierSelected = null
      if (!this.placeSelected) return

      this.listCashier = []
      this.loadingCashier = true
      const data = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        placeSelected: this.placeSelected
      }

      this.$root.post('/reports/getListCashier', data)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', null, data.message)
            return
          }
          this.listCashier = data
        })
        .finally(() => { this.loadingCashier = false })
    }
  }
}
</script>

<style>

</style>
