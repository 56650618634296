<template>
  <div id="CompaniesWholesalers">
    <v-card>
      <v-card-title
        v-if="!loading && corpsList.length"
        class="pb-2"
      >
        <v-chip
          v-for="item in filteredStatus"
          :key="'chip-status-to-filter'+item"
          class="mx-2"
          small
          close
          :color="stateColors[item]"
          @click:close="closeFilterStatus(item)"
        >
          {{ states[item].text }}
        </v-chip>
        <v-divider
          v-show="filteredStatus.length && filteredDocumentSent.length"
          vertical
        />
        <v-chip
          v-for="item in filteredDocumentSent"
          :key="'chip-document-sent-status-to-filter'+item"
          class="mx-2"
          small
          close
          :color="stateColors[item]"
          @click:close="closeFilterDocumentSent(item)"
        >
          {{ documentSentStatus[item].text }}
        </v-chip>
        <v-spacer />
        <v-menu
          v-model="showFilters"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="search"
              placeholder="Buscar"
              v-bind="attrsSearching"
              append-icon="mdi-filter-variant"
              :append="attrs"
              @append="on"
              @click:append="showFilters = !showFilters"
            />
          </template>
          <v-card>
            <v-subheader>
              Solo mostrar empresas con estado:
            </v-subheader>
            <v-list-item-group
              v-model="filteredStatus"
              multiple
            >
              <v-list-item
                v-for="item in states"
                :key="'item-status-to-filter'+item.value"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      color="orange"
                      :input-value="active"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
            <v-subheader>
              Envio de constancia:
            </v-subheader>
            <v-list-item-group
              v-model="filteredDocumentSent"
              multiple
            >
              <v-list-item
                v-for="item in documentSentStatus"
                :key="'item-status-to-filter'+item.value"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      color="orange"
                      :input-value="active"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-card>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          v-if="loading"
          type="table"
        />
        <v-alert
          v-if="!loading && !corpsList.length"
          class="mb-0"
          icon="mdi-folder-alert-outline"
          prominent
          text
          type="warning"
          align="left"
        >
          No hay empresas registradas
        </v-alert>
        <v-data-table
          v-if="!loading && corpsList.length"
          :headers="headers"
          :items="corpsList"
          :search="search"
          multi-sort
          :sort-by="['name']"
          mobile-breakpoint="Infinity"
        >
          <template v-slot:[`item.idcorp`]="{ item }">
            <strong>{{ item.idcorp }}</strong>
          </template>
          <template v-slot:[`item.rfc`]="{ item }">
            <strong :class="`${$rules.isRFC(item.rfc) === true ? 'success' : 'red'}--text`">
              {{ item.rfc }}
            </strong>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <strong :class="`${$rules.isPhone(item.phone) === true ? 'success' : 'red'}--text`">
              {{ item.phone }}
            </strong>
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <v-chip
              class="d-block text-center mx-auto"
              :color="stateColors[item.state]"
              small
              @click="changeCompanyState(item)"
            >
              <strong>{{ states[item.state].text }}</strong>
            </v-chip>
          </template>
          <template v-slot:[`item.sentdocument`]="{ item }">
            <v-chip
              :color="stateColors[item.sentdocument]"
              small
              @click="changeCompanyState(item)"
            >
              <strong>{{ item.sentdocument === 1 ? 'Enviada' : 'No enviada' }}</strong>
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogStateCompany
      v-model="dialogChangeState"
      :company="companySelected"
      :states="states"
      @refreshData="getDataCorpsData"
    />
  </div>
</template>

<script>
import DialogStateCompany from './DialogStateCompany.vue'
export default {
  name: 'CompaniesWholesalers',
  components: { DialogStateCompany },
  props: ['activeNow', 'nowDate'],
  data () {
    return {
      loading: false,
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'RFC', value: 'rfc', filter: value => this.$rules.isRFC(value) },
        { text: 'Correo', value: 'mail' },
        { text: 'Teléfono', value: 'phone' },
        { text: 'Dirección', value: 'address' },
        { text: 'Fecha de registro', value: 'createdAt' },
        {
          text: 'Estado',
          value: 'state',
          filter: value => {
            if (!this.filteredStatus.length) return true
            return this.filteredStatus.includes(value)
          }
        },
        {
          text: 'Constancia',
          value: 'sentdocument',
          filter: value => {
            if (!this.filteredDocumentSent.length) return true
            return this.filteredDocumentSent.includes(value)
          }
        }
      ],
      stateColors: {
        0: 'gray',
        1: 'success',
        2: 'warning',
        3: 'error'
      },
      states: [
        { value: 0, text: 'Inactiva', disabled: false },
        { value: 1, text: 'Activa', disabled: false },
        { value: 2, text: 'En verificación...', disabled: true },
        { value: 3, text: 'Suspensión temporal', disabled: false }
      ],
      dialogChangeState: false,
      companySelected: {},
      search: '',
      attrsSearching: {
        label: '',
        clearable: true,
        dense: true,
        color: 'orange',
        'prepend-icon': 'mdi-magnify',
        class: 'search-text-field mr-4',
        'hide-details': true
      },
      showFilters: false,
      corpsList: [],
      filteredStatus: [],
      filteredDocumentSent: [],
      documentSentStatus: [
        { value: 0, text: 'No enviada' },
        { value: 1, text: 'Enviada' }
      ]
    }
  },
  watch: {
    activeNow: function (val) {
      if (val) this.getDataCorpsData()
    }
  },
  mounted () {
    this.getDataCorpsData()
  },
  methods: {
    getDataCorpsData: function () {
      this.loading = true
      this.search = ''
      this.filteredStatus = []
      this.filteredDocumentSent = []

      this.$root.post('/wholesalers/companiesList')
        .then(response => {
          const data = response.data

          if (data.success === 'FALSE') return this.$swalAlert('error', null, data.message)
          this.corpsList = data
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeCompanyState: function (company) {
      this.dialogChangeState = true
      this.companySelected = JSON.parse(JSON.stringify(company))
    },
    closeFilterStatus: function (item) {
      this.filteredStatus = this.filteredStatus.filter(status => status !== item)
    },
    closeFilterDocumentSent: function (item) {
      this.filteredDocumentSent = this.filteredDocumentSent.filter(status => status !== item)
    }
  }
}
</script>

<style scoped>
#CompaniesWholesalers {
  height: calc(100vh - var(--h-navbar) - 45px);
  overflow-y: auto;
  padding: 20px;
}

.search-text-field {
  max-width: 400px !important;
}
</style>

<style scoped src="../../../assets/css/style-tables-dark.css">
</style>
