<template>
  <v-dialog
    id="DialogManualCancellations"
    v-model="dialog"
    max-width="900px"
    persistent
  >
    <v-card tile>
      <v-toolbar
        dark
        style="border-radius: 0px !important"
      >
        <v-card-title>
          <span class="text-h5">
            {{ manualCancellation.id ? 'Editar' : 'Agregar' }} cancelacion manual
          </span>
        </v-card-title>
        <v-spacer />
        <v-btn
          :loading="loading"
          :disabled="loading"
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3">
        <v-form ref="manualCancellationForm">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="manualCancellation.transI"
                label="Transaccion original"
                outlined
                hide-details="auto"
                :rules="[$rules.required, $rules.isNumber, $rules.min(manualCancellation.transI, 1)]"
                @keydown="$input.isNumber($event)"
                @keydown.enter="save"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="manualCancellation.caja"
                append-icon="mdi-printer-pos"
                label="No. Caja"
                outlined
                hide-details="auto"
                :rules="[$rules.required, $rules.isNumber, $rules.min(manualCancellation.caja, 1)]"
                @keydown="$input.isNumber($event)"
                @keydown.enter="save"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-menu
                v-model="showDateCancellation"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="manualCancellation.fecha"
                    label="Fecha del evento"
                    append-icon="mdi-calendar"
                    outlined
                    readonly
                    hide-details
                    v-bind="attrs"
                    :rules="[$rules.required, $rules.isDate(manualCancellation.fecha, 'YYYY-MM-DD')]"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="manualCancellation.fecha"
                  @input="showDateCancellation = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="manualCancellation.transC"
                label="Transaccion de Cancelacion"
                outlined
                hide-details="auto"
                :rules="[$rules.required, $rules.isNumber, $rules.min(manualCancellation.transC, 1)]"
                @keydown="$input.isNumber($event)"
                @keydown.enter="save"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <ActionsButtonsForms
        class="pb-4"
        :loading="loading"
        :acceptText="manualCancellation.id ? 'Actualizar' : 'Agregar'"
        @cancel="dialog = false"
        @accept="save"
      />
    </v-card>
  </v-dialog>
</template>

<script>
// Components
import ActionsButtonsForms from '../tools/ActionsButtonsForms.vue'

export default {
  name: 'DialogManualCancellations',
  components: { ActionsButtonsForms },
  props: ['value', 'item'],
  data () {
    return {
      loading: false,
      showDateCancellation: false
    }
  },
  computed: {
    dialog: {
      get () {
        this.resetForm()
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.resetForm()
      }
    },
    manualCancellation () {
      return this.item
    }
  },
  methods: {
    save: function () {
      if (!this.$refs.manualCancellationForm.validate()) return

      this.loading = true
      this.$root.post('/CC/saveManualCancellation', this.manualCancellation)
        .then(response => {
          const data = response.data

          if (data?.success === 'FALSE') {
            this.$swalAlert('error', 'Error', data.message)
            return
          }

          this.$swalAlert('success', null, data.message)
          this.$emit('refresh')
          this.dialog = false
        })
        .finally(() => { this.loading = false })
    },
    resetForm: function () {
      this.$refs?.manualCancellationForm?.resetValidation()
    }
  }
}
</script>

<style>

</style>
