<template>
  <div id="HomeWholesalers" class="user-noselect">
    <div class="container-home-catalogue">
      <!-- <div class="container-places-available user-noselect">
        <div v-for="(item, index1) in placesAvailable" :key="`nav-place-item-${index1}`" :data-page="index1"
          :class="`nav-place-item nav-place-${index1}`" :style="{ backgroundImage: 'url(\'' + item.img + '\')' }"
          @click="setPlaceActive(item, index1)">
          <div class="nav-place-title">
            {{ item.name }}
          </div>
          <div class="nav-place-price">
            ${{ $formatPrice(item.cantidad) }}
            <ExchangeCurrency />
          </div>
        </div>
      </div> -->

      <div class="container-places-available2 user-noselect" style="display: flex; flex-wrap: wrap;">
        <template v-for="(item, index1) in placesAvailable" :data-page="index1">
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" :color="activeNavPlace === index1 ? 'rgb(30, 30, 30)' : 'white'"
            :dark="activeNavPlace === index1 ? true : false"
              style="margin: 20px; border-radius: 10px 10px 10px 10px;" v-bind:class="activeNavPlace === index1 ? 'myActive1' : 'myActive2'">
              <div>
                <div class="nav-place-title" style="padding: 6px 5px 0px 8px;">
                  {{ item.name }}
                </div>
                <div class="divCoverEvent" :style="{ backgroundImage: 'url(\'' + item.img + '\')' }"
                  :key="`nav-place-item-${index1}`" @click="setPlaceActive(item, index1)" 
                  v-bind:class="activeNavPlace === index1 ? 'myBorder1' : 'myBorder2'">

                  <div class="nav-place-price"
                    style="text-align: center; position: relative; top: calc(100% - 21px);" 
                     v-bind:class="activeNavPlace === index1 ? 'myActive1' : 'myActive2'"
                    >
                    ${{ $formatPrice(item.cantidad) }}
                    <ExchangeCurrency />
                  </div>
                </div>
              </div>
            </v-card>
          </v-hover>
        </template>

      </div>

      <BackgroundCatalogue :placesAvailable="placesAvailable" :activeNavPlace="activeNavPlace" />

      <div class="container-tickets-available">
        <v-select v-model="placeSelectedSelect" class="title-place-active-select" :items="placesAvailable"
          label="Seleccione un lugar" dark item-color="orange" color="orange" outlined hide-details item-text="name"
          item-value="id" />
        <div class="title-place-active">
          {{ placeSelected.name }}
        </div>


        <v-row class="row-container-tickets-available">
          <v-expansion-panels v-model="panel">
            <v-expansion-panel v-for="(item2, i) in ticketsForSelectedPlace" :key="i">
              <v-expansion-panel-header>
                <strong>{{ item2.fin }} 
                  <small>
                      Hrs                    
                  </small>
                </strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="padding: 0 !important;">
                <v-col v-for="(item, index1) in item2.ticketsForSelectedPlace" :key="`ticketsAvailable-${index1}`"
                  cols="12">
                  <v-card class="card-available-ticket"
                    style="padding-bottom: 5px; background: white; color: black; border: 1px solid white;" :class="item.active == 0  ?  'myOpacity' : ''">
                    <div class="card-ticket-title">
                      <strong v-if="item.active == 0" style="color: red;"> VENDIDO <br></strong>
                      <strong>{{ item.alias }}</strong> <br>
                      <div align="right">
                        <small v-if="item.alias2">({{ item.alias2 }})</small>
                      </div>
                      <div class="card-ticket-price" style="font-size: 20px;">
                        ${{ $formatPrice(item.costo) }}
                        <ExchangeCurrency />
                      </div>
                    </div>
                    <div v-if="item.active != 0" align="center">
                      <!-- <InputAmountTickets v-if="canEdit" v-model="item.cantidad" class="ma-3 mt-0 amount-tickets-available" @change="changeAmountOfTicket"/> -->
                      <v-btn color="orage" dark small style="width: 90%;"
                        v-on:click="changeAmountOfTicket2(item, i, index1)" v-if="item.active == 1">Comprar</v-btn>
                      <v-btn color="red" dark small style="width: 90%;"
                        v-on:click="changeAmountOfTicket2(item, i, index1)" v-if="item.active == 2">Cancelar</v-btn>
                    </div>
                  </v-card>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>

      </div>
    </div>
    <div class="footer-home-catalogue">
      <a class="ref-terms-conditions" @click="showTermsConditions = true">
        Términos y condiciones
      </a>
    </div>

    <DialogTermsConditions v-model="showTermsConditions" />
  </div>
</template>

<script>
// Vue
import { mapGetters, mapMutations, mapState } from 'vuex'

// Components
import DialogTermsConditions from '../../components/tools/DialogTermsConditions.vue'
import BackgroundCatalogue from '../../components/wholesalers/BackgroundCatalogue.vue'

export default {
  name: 'HomeWholesalers',
  components: {
    DialogTermsConditions,
    BackgroundCatalogue
  },
  data() {
    return {
      ticketsForSelectedPlace: [],
      activeNavPlace: null,
      placeSelected: {},
      rol: 0,
      showTermsConditions: false,
      isChangingPlace: false,
      panel: 0,
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  computed: {
    ...mapState('wholesaler', ['placesAvailable', 'ticketsAvailable']),
    ...mapGetters({
      userData: 'auth/userData'
    }),
    canEdit() {
      return this.userData?.subrol < 3
    },
    placeSelectedSelect: {
      get() {
        return this.placeSelected?.id
      },
      set(id) {
        const item = this.placesAvailable.find(place => place.id === id)
        const index = this.placesAvailable.findIndex(place => place.id === id)
        this.setPlaceActive(item, index)
      }
    }
  },
  watch: {
    placesAvailable: function () {
      this.initCatalogue()
    }
  },
  mounted() {
    this.initCatalogue()
  },
  methods: {
    ...mapMutations({
      setTotalForSale: 'wholesaler/setTotalForSale'
    }),
    initCatalogue: function () {
      if (!this.placesAvailable.length) return
      setTimeout(() => {
        this.setPlaceActive(this.placesAvailable[0], 0)
      }, 100)
    },
    setPlaceActive: function (item, indexActive) {
      if (!item) return
      if (this.activeNavPlace === indexActive) return false
      this.activeNavPlace = indexActive

      const navPlaceItems = document.querySelectorAll('.nav-place-item')
      navPlaceItems.forEach(item => { item.classList.remove('nav-place-item-active') })

      const activeNavItem = document.querySelector('.nav-place-' + this.activeNavPlace)
      if (activeNavItem) activeNavItem.classList.add('nav-place-item-active')

      const container = document.querySelector('.container-tickets-available')
      if (container) container.style.opacity = 0

      setTimeout(() => {
        this.placeSelected = item
        const resultado = this.ticketsAvailable.filter((itemx) => itemx.sitio === item.id)

        this.ticketsForSelectedPlace = resultado

        if (container) container.style.opacity = 1
      }, 500)
    },
    changeAmountOfTicket: function () {
      let totalForSelectedPlace = 0
      for (let x = 0; x < this.ticketsForSelectedPlace.length; x++) {
        const { cantidad, costo } = this.ticketsForSelectedPlace[x]
        totalForSelectedPlace += cantidad ? costo * parseInt(cantidad) : 0
      }

      const placeSeleted = this.placesAvailable.find(place => place.id === this.placeSelected.id)
      placeSeleted.cantidad = totalForSelectedPlace

      let totalForSale = 0
      this.placesAvailable.forEach(place => { totalForSale += place.cantidad })
      this.setTotalForSale(totalForSale)
    },
    changeAmountOfTicket2: function (item, i, index1) {
      let element = this.ticketsForSelectedPlace[i].ticketsForSelectedPlace[index1]

      const placeSeleted = this.placesAvailable.find(place => place.id === this.placeSelected.id)


      if (item.active == 1) {
        item.active = 2
        this.ticketsForSelectedPlace[i].costo += element.costo
        placeSeleted.cantidad += element.costo
      } else {
        item.active = 1
        this.ticketsForSelectedPlace[i].costo -= element.costo
        placeSeleted.cantidad -= element.costo
      }

      console.log(this.ticketsForSelectedPlace);

      let totalForSale = 0
      this.placesAvailable.forEach(place => { totalForSale += place.cantidad })
      this.setTotalForSale(totalForSale)
    }
  }
}
</script>

<style scoped src="../../assets/css/home-wholesalers.css"></style>

<style scoped>
.myActive1{
  background:rgb(30, 30, 30) !important;
  color:  white !important;
}
.myActive2{
  background: white !important;
  color: rgb(30, 30, 30) !important;
}
.myBorder1{
  border: 4px solid rgb(30, 30, 30) !important;
}
.myBorder2{
  border: 4px solid white !important;
}
.v-expansion-panel-content>>>.v-expansion-panel-content__wrap {
  padding: 0 !important;
  background-color: black;
}

.container-tickets-available {
  padding: 30px !important;
}
.myOpacity{
  opacity: 0.8 !important;
}

.divCoverEvent {
  width: 280px;
  height: 180px;
  background-size: cover;
  border-radius: 10px 50px 10px 10px;
}

.divCoverEvent:hover {
  cursor: pointer;
}

.v-expansion-panel--active .v-expansion-panel-header {
  min-height: 48px;
}
</style>
