import MainWholesalers from '../views/wholesalers/MainWholesalers.vue'
import HomeWholesalers from '../views/wholesalers/HomeWholesalers.vue'
import MiCartWholesalers from '../views/wholesalers/MiCartWholesalers.vue'
import AccountSettingWholesalers from '../views/wholesalers/AccountSettingWholesalers.vue'
import UsersWholesalers from '../views/wholesalers/UsersWholesalers.vue'
import AvailableTickets from '../views/wholesalers/AvailableTickets.vue'

export default [
  {
    path: '/',
    component: MainWholesalers,
    children: [
      {
        path: 'home',
        name: 'Mayoristas',
        component: HomeWholesalers
      },
      {
        path: 'my-cart/:id',
        name: 'Mi carrito',
        component: MiCartWholesalers
      },
      {
        path: 'account-settings',
        name: 'Configuración de la cuenta',
        component: AccountSettingWholesalers
      },
      {
        path: 'users',
        name: 'Usuarios',
        component: UsersWholesalers
      },
      {
        path: 'available-tickets',
        name: 'Boletos disponibles',
        component: AvailableTickets
      }
    ]
  }
]
